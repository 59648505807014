import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {DateFormats, Timezones} from '../../domain/date-params';
import {DateTimeUtilService} from '../../services/date-time-util/date-time-util.service';
import {CompanyService} from '../../services/company/company.service';

@Pipe({
    name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

    constructor(private readonly datePipe: DatePipe,
                private readonly translateService: TranslateService) {
    }

    static getTimezone(timezone: Timezones): string {
        switch (timezone) {
            case Timezones.LOCALE:
                return DateTimeUtilService.formatTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
            case Timezones.COMPANY:
                return DateTimeUtilService.formatTimezone(CompanyService.getCurrentCompany().timezone);
            case Timezones.UTC:
                return DateTimeUtilService.formatUtc();
            default:
                return DateTimeUtilService.formatTimezone(CompanyService.getCurrentCompany().timezone);
        }
    }

    transform(value: Date | string,
              format: DateFormats = DateFormats.DAY_MONTH_INITIALS_YEAR,
              timezone: Timezones = Timezones.COMPANY): string {

        const formattedTimezone = DateFormatPipe.getTimezone(timezone);
        const locale = this.translateService.currentLang;
        const date = typeof value === 'string'
            ? new Date(value)
            : value;

        return this.datePipe.transform(date, format, formattedTimezone, locale);
    }
}
