import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import * as moment from 'moment';
import {Moment} from 'moment';
import {ModalController} from '@ionic/angular';
import {DateTimeUtilService} from '../../../../services/date-time-util/date-time-util.service';
import {PositionOverviewFilter} from '../../../../domain/filter/position-overview-filter';
import {isDefined} from '../../../../commons/utils';
import {TranslateService} from '@ngx-translate/core';
import {InsightsViewType} from '../../../../domain/insights-view-type';
import {FirebaseService} from '../../../../services/firebase/firebase.service';

@Component({
    selector: 'app-insights-filter',
    templateUrl: './insights-filter.component.html',
    styleUrls: ['./insights-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InsightsFilterComponent implements OnInit {

    private readonly TRANSLATE_KEY_BASE = 'hedged-orders.filters.';

    readonly TODAY = moment();
    readonly viewTypeFilterItemKeys = [InsightsViewType.NET_AMOUNTS, InsightsViewType.TOTAL_AMOUNTS];

    selectedExecutionDateFrom: Moment;
    selectedExecutionDateTo: Moment;
    selectedSettlementDateFrom: Moment;
    selectedSettlementDateTo: Moment;
    viewTypeFilterItems = [];
    selectedViewType: string;

    @Input() positionOverviewFilter: PositionOverviewFilter;
    @Input() showViewType = false;
    @Input() viewType: InsightsViewType = InsightsViewType.NET_AMOUNTS;

    constructor(private readonly modalController: ModalController,
                private readonly translateService: TranslateService,
                private readonly firebaseService: FirebaseService) {
    }

    ngOnInit(): void {
        this.translateFilterItemKeys();
        this.setSelectedFilters();
    }

    onTradeDateFromFilterChange(dateFrom: Moment): void {
        this.selectedExecutionDateFrom = dateFrom;
        this.positionOverviewFilter.executionDateFrom = DateTimeUtilService.formatToIsoDate(dateFrom);
    }

    onTradeDateToFilterChange(dateTo: Moment): void {
        this.selectedExecutionDateTo = dateTo;
        this.positionOverviewFilter.executionDateTo = DateTimeUtilService.formatToIsoDate(dateTo);
    }

    onSettlementDateFromFilterChange(dateFrom: Moment): void {
        this.selectedSettlementDateFrom = dateFrom;
        this.positionOverviewFilter.hedgeDateFrom = DateTimeUtilService.formatToIsoDate(dateFrom);
    }

    onSettlementDateToFilterChange(dateTo: Moment): void {
        this.selectedSettlementDateTo = dateTo;
        this.positionOverviewFilter.hedgeDateTo = DateTimeUtilService.formatToIsoDate(dateTo);
    }

    onViewTypeFilterChange(viewType: string): void {
        const index = this.viewTypeFilterItems.findIndex(filterItem => filterItem === viewType);
        this.viewType = this.viewTypeFilterItemKeys[index];
    }

    private setSelectedFilters(): void {
        this.selectedExecutionDateFrom = isDefined(this.positionOverviewFilter.executionDateFrom)
            ? moment.utc(this.positionOverviewFilter.executionDateFrom)
            : null;
        this.selectedExecutionDateTo = isDefined(this.positionOverviewFilter.executionDateTo)
            ? moment.utc(this.positionOverviewFilter.executionDateTo)
            : null;
        this.selectedSettlementDateFrom = isDefined(this.positionOverviewFilter.hedgeDateFrom)
            ? moment.utc(this.positionOverviewFilter.hedgeDateFrom)
            : null;
        this.selectedSettlementDateTo = isDefined(this.positionOverviewFilter.hedgeDateTo)
            ? moment.utc(this.positionOverviewFilter.hedgeDateTo)
            : null;
        this.selectedViewType = this.translateService
            .instant(this.TRANSLATE_KEY_BASE + this.viewTypeFilterItemKeys
                .find(viewType => viewType === this.viewType));
    }

    private translateFilterItemKeys(): void {
        this.viewTypeFilterItemKeys
            .forEach(key =>
                this.viewTypeFilterItems.push(this.translateService.instant(this.TRANSLATE_KEY_BASE + key))
            );
    }

    closeModal(): void {
        this.modalController.dismiss().then();
    }

    dismissModal(): void {
        this.firebaseService.logCustomEvent('insights_apply_filters');
        this.modalController.dismiss({
            'positionOverviewFilter': this.positionOverviewFilter,
            'viewType': this.viewType
        }).then();
    }

    clearAll(): void {
        this.positionOverviewFilter = new PositionOverviewFilter();
        this.positionOverviewFilter.hedgeDateFrom = DateTimeUtilService.formatToIsoDate(moment().toString());
        this.setSelectedFilters();
    }
}
