<div class="main-div">
    <ng-container *ngIf="$companyChange | async"></ng-container>
    <ng-container *ngrxLet="$widgetData; let widgetData; suspenseTpl: loading">
        <app-bubble-chart *ngIf="hasValues(settlementCards) else noSettlementsChart"
                          [currencyOverviews]="widgetData[0].currencyOverviews"
                          [selectedCcy]="selectedSymbol"
                          [baseCurrency]="widgetData[1]"
                          (selectedCcyChange)="onCcyChange($event)"/>
        <ng-template #noSettlementsChart>
            <div class="d-flex justify-content-center">
                <ion-icon src="assets/icon/empty-bubble-chart.svg" class="empty-bubble-chart"/>
            </div>
        </ng-template>
        <ion-row *ngIf="hasValues(settlementCards)" class="ion-justify-content-between">
            <ion-col size="6">
                <div class="subtitle">
                    {{'insights.labels.net-settlements' | translate}}
                </div>
            </ion-col>
            <ion-col size="6">
                <ion-row>
                    <span class="settlement-date-label">{{'insights.labels.settlement' | translate}}</span>
                </ion-row>
                <ion-row>
                    <span class="date">{{datesLabel}}</span>
                </ion-row>
            </ion-col>
        </ion-row>
        <ng-container *ngIf="hasValues(settlementCards) else noTradesShown">
            <ion-content class="scroll-container"
                         [scrollEvents]="true"
                         [scrollY]="true">
                <app-bubble-chart-card *ngFor="let settlementCard of settlementCards"
                                       class="chart-card"
                                       [id]="settlementCard.currency"
                                       (click)="toggleSelectedCard(settlementCard.currency === selectedSymbol
                                                    ? null
                                                    : settlementCard.currency)"
                                       [settlementCard]="settlementCard"
                                       [selected]="settlementCard.currency === selectedSymbol"
                                       (selectSymbol)="goToDetails(settlementCard.currency)"/>
            </ion-content>
        </ng-container>
    </ng-container>
    <ng-template #noTradesShown>
        <div class="no-trades-message ion-text-center">{{'insights.no-settlements' | translate}}</div>
    </ng-template>
</div>
<ng-template #loading>
    <ion-spinner name="crescent"/>
</ng-template>

