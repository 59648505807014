import {Pipe, PipeTransform} from '@angular/core';
import {DecimalPipe, getLocaleNumberSymbol, NumberSymbol} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {isDefined, isNullOrUndefined} from '../../commons/utils';
import {GlobalizationService} from '../../services/globalization/globalization.service';

@Pipe({
    name: 'decimalAmount'
})
export class DecimalAmountPipe implements PipeTransform {

    public static AMOUNT_FORMAT_4_DIGITS = '1.4-4';
    public static AMOUNT_FORMAT_1_DIGIT = '1.1-1';
    public static AMOUNT_FORMAT_0_DIGITS = '1.0-0';
    private static DEFAULT_AMOUNT_FORMAT = '1.2-2';

    constructor(private readonly decimalPipe: DecimalPipe,
                private readonly translateService: TranslateService,
                private readonly globalizationService: GlobalizationService) {
    }

    transform(value: number, format?: string): string {
        return this.transformWithNumberPattern(
            value,
            isDefined(format) ? format : DecimalAmountPipe.DEFAULT_AMOUNT_FORMAT,
            this.globalizationService.getDecimalSeparator(),
            this.globalizationService.getGroupSeparator()
        );
    }

    transformWithNumberPattern(value: any,
                               digitsInfo: string,
                               decimal: string,
                               group: string): string | undefined | null {
        const defaultLang = this.translateService.getDefaultLang();
        const pipedValue = this.decimalPipe.transform(value, digitsInfo, defaultLang);

        const defaultDecimalSeparator = getLocaleNumberSymbol(defaultLang, NumberSymbol.CurrencyDecimal);
        const defaultGroupSeparator = getLocaleNumberSymbol(defaultLang, NumberSymbol.CurrencyGroup);
        const separatorsMatch = defaultDecimalSeparator === decimal && defaultGroupSeparator === group;

        if (isNullOrUndefined(pipedValue) || separatorsMatch) {
            return pipedValue;
        }
        const numberParts = pipedValue.split(defaultDecimalSeparator);
        const integerPart = numberParts[0].split(defaultGroupSeparator).join(group);
        return numberParts.length > 1 ? integerPart + decimal + numberParts[1] : integerPart;
    }
}
