import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {ApplicationSettings, BankContactSettings} from '../../../domain/contact-settings';
import {BankEntity} from '../../../domain/bank-entity';

@Component({
    selector: 'app-support-hours-alert',
    templateUrl: './support-hours-alert.component.html',
    styleUrls: ['./support-hours-alert.component.scss'],
})
export class SupportHoursAlertComponent implements OnInit {

    bankContactSettings: BankContactSettings;
    applicationSettings: ApplicationSettings;
    bankEntity: BankEntity;

    @Input() isBankHoliday: boolean;
    @Input() isOutsideSupportHours: boolean;

    constructor(private readonly modalController: ModalController) {
    }

    ngOnInit(): void {
    }

    dismiss(): void {
        this.modalController.dismiss().then();
    }
}
