<ion-list lines="none">
  <ion-item *ngFor="let language of availableLanguages"
            button
            class="ion-text-center"
            (click)="changeLanguage(language)">
    <span [class.bold]="currentLanguage === language">
      {{'languages.' + language | translate}}
    </span>
  </ion-item>
</ion-list>
