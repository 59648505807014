/// <reference types="@types/grecaptcha" />
import {Injectable, NgZone} from '@angular/core';
import {Observable, of, Subject} from 'rxjs';
import {isDefined} from '../../commons/utils';
import {ReCaptchaSettings} from '../../domain/re-captcha-settings';

type ActionSubscriptionCacheEntry = [string, Subject<string>];

@Injectable({
  providedIn: 'root'
})
export class ReCaptchaService {

  private siteKey: string;
  private grecaptcha: ReCaptchaV2.ReCaptcha;
  private actionSubscriptionsCache: ActionSubscriptionCacheEntry[] = [];
  private enabled = true;

  constructor(private zone: NgZone) {
  }

  init(reCaptchaSettings: ReCaptchaSettings): void {
    this.enabled = reCaptchaSettings.enabled;
    this.siteKey = reCaptchaSettings.siteKey;

    if (this.enabled) {
      this.addReCaptchaScript();
    } else {
      this.actionSubscriptionsCache = [];
    }
  }

  executeV3Action(action: string): Observable<string> {
    const subject = new Subject<string>();

    if (this.enabled) {
      if (isDefined(this.grecaptcha)) {
        this.executeActionWithSubject(action, subject);
      } else {
        this.actionSubscriptionsCache.push([action, subject]);
      }
    } else {
      return of(null);
    }

    return subject.asObservable();
  }

  private addReCaptchaScript(): void {
    const scriptElement = document.createElement('script');
    scriptElement.src = 'https://www.google.com/recaptcha/api.js?onload=reCaptchaLoaded&render=' + this.siteKey;
    scriptElement.type = 'text/javascript';
    document.getElementsByTagName('head')[0].appendChild(scriptElement);

    // @ts-ignore
    window.reCaptchaLoaded = () => this.reCaptchaLoaded(grecaptcha);
  }

  private reCaptchaLoaded(grecaptcha: ReCaptchaV2.ReCaptcha): void {
    this.grecaptcha = grecaptcha;

    if (this.actionSubscriptionsCache.length > 0) {
      this.actionSubscriptionsCache.forEach(([action, subject]) => this.executeActionWithSubject(action, subject));
      this.actionSubscriptionsCache = [];
    }
  }

  private executeActionWithSubject(action: string, subject: Subject<string>): void {
    this.zone.runOutsideAngular(() => {
      this.grecaptcha.ready(() => {
        this.grecaptcha
            .execute(this.siteKey, {action})
            .then((token: string) => {
              this.zone.run(() => {
                subject.next(token);
                subject.complete();
              });
            });
      });
    });
  }
}
