<div class="ion-no-padding overview-card" [class.selected-overview-card]="selected">
    <ion-grid class="ion-padding">
        <ion-row class="overview-card-header">
            <ion-col class="ion-no-padding" size="11">
                <span class="overview-card-title">{{settlementCard.currency}}</span>
            </ion-col>
            <ion-col class="ion-no-padding" size="1">
                <ion-icon class="ion-float-end" src="assets/icon/icon-arrow-right.svg"
                          (click)="onSymbolSelect()">
                </ion-icon>
            </ion-col>
        </ion-row>
        <ion-row class="row" *ngFor="let amount of settlementCard.amounts">
            <span>{{amount?.date | dateFormat:DateFormats.NUMERIC_DATE_POINT }}</span>
            <span>{{amount?.amount}}</span>
        </ion-row>
    </ion-grid>
</div>
