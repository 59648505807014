<ion-row class="filter-row">
    <ion-col size="11">
        <span *ngIf="!isTradingVolumeSelected"
              class="subtitle">{{'insights.subtitle-number-of-trades' | translate}}</span>
        <span *ngIf="isTradingVolumeSelected" class="subtitle">{{'insights.subtitle-volume' | translate}}</span>
    </ion-col>
    <ion-col size="1">
        <ion-icon src="assets/icon/icon_filter.svg"
                  class="icon-filter"
                  (click)="openFilters()">
        </ion-icon>
    </ion-col>
</ion-row>
