import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
    selector: 'app-page-title',
    templateUrl: './page-title.component.html',
    styleUrls: ['./page-title.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageTitleComponent {

    @Input() pageTitle: string;
    @Input() boldText = false;

    constructor() {
    }
}
