import {Platform} from '@ionic/angular';
import {Subscription} from 'rxjs';
import {Injectable, NgZone, OnDestroy} from '@angular/core';
import * as moment from 'moment';
import {Moment} from 'moment';

@Injectable()
export abstract class OnPlatformEvents implements OnDestroy {

    private static readonly TIMEOUT_IN_SECONDS = 2;
    private platformLastPauseTimestamp: Moment;
    private readonly subscription = new Subscription();

    protected constructor(platform: Platform,
                          ngZone: NgZone) {
        platform.ready().then(() => {
            const pauseSubscription = platform.pause.subscribe(() => {
                this.platformLastPauseTimestamp = moment();
                ngZone.run(() => this.ionPause());
            });
            this.addSubscription(pauseSubscription);

            const resumeSubscription = platform.resume.subscribe(() => {
                ngZone.run(() => this.ionResume());
                const elapsedTime = moment().diff(this.platformLastPauseTimestamp, 'seconds');
                if (elapsedTime > OnPlatformEvents.TIMEOUT_IN_SECONDS) {
                    ngZone.run(() => this.ionAfterTimeoutResume());
                }
            });
            this.addSubscription(resumeSubscription);
        });
    }

    abstract ionPause(): void;

    abstract ionResume(): void;

    ionAfterTimeoutResume(): void {
    }

    protected addSubscription(subscription: Subscription): void {
        this.subscription.add(subscription);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    clearSubscription(): void {
        this.subscription.unsubscribe();
    }
}
