export class AppPaths {
    public static readonly LOGIN_PIN_CODE_PAGE = '/login-pin-code';

    public static readonly TABS_PAGE = '/tabs';
    public static readonly NOTIFICATIONS_PAGE = 'tabs/notifications';
    public static readonly COMPANIES_PAGE = 'tabs/companies';
    public static readonly EXTRA_PAGE = 'tabs/more';

    public static readonly RATES_PAGE = 'tabs/rates';
    public static readonly RATE_DETAILS_PAGE = 'tabs/rates/rate-details';
    public static readonly RATE_LIMITS_PAGE = 'tabs/rates/rate-limits';
    public static readonly USER_CURRENCIES_MANAGEMENT_PAGE = 'tabs/rates/user-currency-management';
    public static readonly CURRENCY_SELECT_PAGE = 'tabs/rates/currency-select';

    public static readonly TRADING_LANDING_PAGE = 'tabs/hedging';
    public static readonly MANUAL_TRADE_PRE_TRADE_PAGE = 'tabs/hedging/manual-trade/pre-trade';
    public static readonly MANUAL_TRADE_QUOTING = 'tabs/hedging/manual-trade/pre-trade/quoting';
    public static readonly MANUAL_TRADE_SAVED_TRADES_PAGE = 'tabs/hedging/manual-trade/saved-trades';
    public static readonly MANUAL_TRADE_SAVED_BATCHES_PAGE = 'tabs/hedging/manual-trade/saved-batches';
    public static readonly MANUAL_TRADE_POST_TRADE = 'tabs/hedging/manual-trade/post-trade';

    public static readonly CHANGE_SETTLEMENT = 'tabs/hedging/rollover/select-trade';
    public static readonly ROLLOVER_PRE_TRADE_PAGE = 'tabs/hedging/rollover/pre-trade';
    public static readonly ROLLOVER_POST_TRADE = 'tabs/hedging/rollover/post-trade';

    public static readonly HEDGED_ORDERS_PAGE = 'tabs/hedged-orders';
    public static readonly POST_TRADE_PAGE = 'tabs/post-trade';

    public static readonly USER_PROFILE_PAGE = 'tabs/more/user-profile';
    public static readonly COMPANY_PROFILE_PAGE = 'tabs/more/company-profile';
    public static readonly SUGGEST_IMPROVEMENT_PAGE = 'tabs/more/suggest-improvement';
    public static readonly SUGGEST_IMPROVEMENT_CONFIRM_PAGE = 'tabs/more/suggest-improvement/confirm';
    public static readonly MOBILE_DEVICES_PAGE = 'tabs/more/mobile-devices';
    public static readonly SECURITY_PAGE = 'tabs/more/security';
    public static readonly BIOMETRICS_CONFIG_PAGE = 'tabs/more/security/biometrics-config';
    public static readonly CHANGE_PIN_CODE_PAGE = 'tabs/more/security/change-pin-code';
    public static readonly NOTIFICATION_SETTINGS_PAGE = 'tabs/more/notification-settings';
    public static readonly OPENING_HOURS_PAGE = 'tabs/more/opening-hours';
    public static readonly LANGUAGE_PAGE = 'tabs/more/language';
    public static readonly GLOSSARY_PAGE = 'tabs/more/glossary';

    public static readonly LOGIN_CONFIRMATION_PAGE = 'registration/login-confirmation';
    public static readonly DEVICE_REGISTRATION_PAGE = 'registration/device-registration';
    public static readonly SET_PIN_CODE_PAGE = 'registration/set-pin-code';
    public static readonly USER_CODE_PAGE = 'registration/user-code';
    public static readonly LOGIN_WITH_CODE_PAGE = 'registration/login-with-code';

    public static readonly DEFAULT_PAGE = '/default';
    public static readonly TRADING_ACTION_PAGE = '/trading-action';
    public static readonly TENOR_SELECT_PAGE = '/tenor-select';
    public static readonly LOGIN_INIT_PAGE = '/registration/login-init';
    public static readonly TOKEN_ENDPOINT = '/token';
}
