import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {InsightsPeriod} from '../../../../domain/insights-period';

@Component({
    selector: 'app-tenor-bar',
    templateUrl: './tenor-bar.component.html',
    styleUrls: ['./tenor-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TenorBarComponent implements OnInit {

    @Input() availableTenors: InsightsPeriod[];
    @Input() activeTenor: InsightsPeriod;
    @Output() changeActiveTenor: EventEmitter<InsightsPeriod> = new EventEmitter<InsightsPeriod>();

    constructor() {
    }

    ngOnInit(): void {
    }

    onTenorSelect(tenor: InsightsPeriod): void {
        this.changeActiveTenor.emit(tenor);
    }
}
