import {forwardRef, Inject, Injectable, InjectionToken} from '@angular/core';
import {PlatformEnum} from '../../domain/platform';
import {SecureStorage, SecureStorageObject} from '@awesome-cordova-plugins/secure-storage/ngx';
import {isDefined} from '../../commons/utils';
import {Device} from '@awesome-cordova-plugins/device/ngx';
import {StorageBackend} from '@openid/appauth';
import {AppConstants} from '../../commons/app-constants';

export const LOCAL_STORAGE_TOKEN = new InjectionToken('LOCAL_STORAGE');

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService extends StorageBackend {
    private secureStorageObject: SecureStorageObject;
    private storage = new Map();
    private storageAvailable = false;

    constructor(private secureStorage: SecureStorage,
                @Inject(LOCAL_STORAGE_TOKEN) private localStorageProvider: any,
                @Inject(forwardRef(() => Device)) private device: Device) {
        super();
    }

    private getPlatform(): PlatformEnum {
        return isDefined(this.device) && isDefined(this.device.platform) ? PlatformEnum.MOBILE : PlatformEnum.WEB;
    }

    initStorage(): Promise<void> {
        return new Promise(resolve => {
            if (this.getPlatform() === PlatformEnum.MOBILE) {
                this.secureStorage.create('secureStorage')
                    .then(storage => {
                        this.secureStorageObject = storage;
                        this.secureStorageObject.keys().then(keys => {
                            if (keys.length === 0) {
                                this.storageAvailable = true;
                                resolve();
                            } else {
                                for (const key of keys) {
                                    this.secureStorageObject.get(key).then(value => {
                                        this.storage.set(key, JSON.parse(value));
                                        if (keys.indexOf(key) === keys.length - 1) {
                                            this.storageAvailable = true;
                                            resolve();
                                        }
                                    });
                                }
                            }
                        });
                    })
                    .catch(() => {
                        this.storageAvailable = false;
                        resolve();
                    });
            } else {
                this.storageAvailable = true;
                resolve();
            }
        });
    }

    setItem<T>(key: string, value: T): Promise<void> {
        return new Promise(resolve => {
            if (this.getPlatform() === PlatformEnum.MOBILE) {
                this.secureStorageObject.set(key, JSON.stringify(value)).then(() => {
                    this.storage.set(key, value);
                    resolve();

                });
            } else {
                this.localStorageProvider.setItem(key, JSON.stringify(value));
                resolve();
            }
        });
    }

    getItem<T>(key: string): T {
        if (this.getPlatform() === PlatformEnum.MOBILE) {
            const object = this.storage.get(key);
            return isDefined(object) ? object : null;
        } else {
            const serializedObject = this.localStorageProvider.getItem(key);
            return isDefined(serializedObject) ? JSON.parse(serializedObject) : null;
        }
    }

    getKeys(): Promise<string[]> {
        return this.secureStorageObject.keys();
    }

    removeItem(key: string): Promise<void> {
        return new Promise(resolve => {
            if (this.getPlatform() === PlatformEnum.MOBILE) {
                this.secureStorageObject.remove(key).then(() => {
                    this.storage.delete(key);
                    resolve();
                });
            } else {
                this.localStorageProvider.removeItem(key);
                resolve();
            }
        });
    }

    clear(): Promise<void> {
        return new Promise(resolve => {
            if (this.getPlatform() === PlatformEnum.MOBILE) {
                const deviceId = this.getItem<string>(AppConstants.DEVICE_ID);
                this.secureStorageObject.clear().then(() => {
                    this.storage.clear();
                    this.setItem<string>(AppConstants.DEVICE_ID, deviceId).then(() => resolve());
                });
            } else {
                this.localStorageProvider.clear();
                resolve();
            }
        });
    }

    isStorageAvailable(): boolean {
        return this.storageAvailable;
    }
}

declare const localStorage: any;

export function localStorageFactory(): void {
    return localStorage;
}
