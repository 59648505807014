import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ModalOptions} from '@ionic/core';
import {DatePickerComponent} from '../../../hedging/components/date-picker/date-picker.component';
import {Moment} from 'moment';
import {ModalController} from '@ionic/angular';
import {isDefined, isNullOrUndefined} from '../../../../commons/utils';

@Component({
    selector: 'app-date-input',
    templateUrl: './date-input.component.html',
    styleUrls: ['./date-input.component.css'],
})
export class DateInputComponent implements OnInit, OnChanges {

    private modalObject: HTMLIonModalElement;

    readonly isDefined = isDefined;
    readonly isNullOrUndefined = isNullOrUndefined;

    dateDisplayValue: Date;
    isModalVisible = false;

    @Input() date: Moment;
    @Input() placeholder: string;
    @Input() minDate: Moment;
    @Input() maxDate: Moment;
    @Output() changeDate = new EventEmitter<Moment>();

    constructor(private readonly modalController: ModalController) {
    }

    ngOnInit(): void {
        if (isDefined(this.date)) {
            this.dateDisplayValue = this.date.toDate();
        }
    }

    async openDatePickerModal(): Promise<void> {
        const datePickerModalOptions: ModalOptions = {
            component: DatePickerComponent,
            componentProps: {
                minDate: this.minDate,
                maxDate: this.maxDate
            }
        };
        if (!this.isModalVisible) {
            this.isModalVisible = true;
            this.modalObject = await this.modalController.create(datePickerModalOptions);
            this.getModalData();
            await this.modalObject.present();
        }
    }

    private getModalData(): void {
        this.modalObject.onDidDismiss().then((modalData) => {
            if (isDefined(modalData.data)) {
                this.date = modalData.data.dueDate;
            }
            this.isModalVisible = false;
            if (isDefined(this.date)) {
                this.dateDisplayValue = this.date.toDate();
                this.changeDate.emit(this.date);
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (isDefined(changes.date.currentValue)) {
            this.dateDisplayValue = changes.date.currentValue.toDate();
        }
    }
}
