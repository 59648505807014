import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {isNullOrUndefined, isDefined} from '../../../../../commons/utils';

@Component({
    selector: 'app-filter-checkbox',
    templateUrl: './filter-checkbox.component.html',
    styleUrls: ['./filter-checkbox.component.css'],
})
export class FilterCheckboxComponent implements OnInit {

    readonly isNullOrUndefined = isNullOrUndefined;
    readonly isDefined = isDefined;

    @Input() checked = false;
    @Input() disabled = false;
    @Input() label: string;
    @Input() translateString;
    @Output() change = new EventEmitter<boolean>();

    constructor() {
    }

    ngOnInit(): void {
    }

    toggleCheckbox(): void {
        if (!this.disabled) {
            this.checked = !this.checked;
            this.change.emit(this.checked);
        }
    }
}
