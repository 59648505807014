import {Component, Input} from '@angular/core';
import {RegulatoryDocs} from '../../../domain/regulatory-docs';
import {InAppBrowser} from '@awesome-cordova-plugins/in-app-browser/ngx';
import {environment} from '../../../../environments/environment';
import {Subscription} from 'rxjs';
import {ViewWillLeave} from '@ionic/angular';

@Component({
    selector: 'app-regulatory-docs',
    templateUrl: './regulatory-docs.component.html',
    styleUrls: ['./regulatory-docs.component.scss']
})
export class RegulatoryDocsComponent implements ViewWillLeave {

    private readonly ANDROID_GOOGLE_DOC_PDF_VIEWER_URL = 'https://docs.google.com/viewerng/viewer?url=';

    @Input() regulatoryDocs: RegulatoryDocs;

    subscription = new Subscription();
    loaded: boolean;

    constructor(private readonly inAppBrowser: InAppBrowser) {
    }

    ionViewWillLeave(): void {
        this.subscription.unsubscribe();
    }

    openLink(link: string): void {
        this.loaded = false;
        const androidPlatform = environment.platform === 'android';
        const url = androidPlatform
            ? this.ANDROID_GOOGLE_DOC_PDF_VIEWER_URL + encodeURIComponent(link)
            : link;
        const browserRef = this.inAppBrowser.create(url, '_blank', {toolbarposition: 'top'});

        // Workaround for android devices where the plugin sometimes doesn't load the url
        if (androidPlatform) {
            this.subscription.add(browserRef.on('loadstart').subscribe(() => this.loaded = true));
            this.subscription.add(browserRef.on('loadstop')
                .subscribe(() => {
                    if (!this.loaded) {
                        browserRef.executeScript({code: 'window.location.replace(\'' + url + '\');'});
                    }
                })
            );
        }
    }
}
