import {Pipe, PipeTransform} from '@angular/core';
import {InsightsPeriod} from '../../domain/insights-period';
import {isDefined} from '../../commons/utils';
import {DatePipe} from "@angular/common";

@Pipe({
    name: 'positionChartPeriodLabel'
})
export class PositionChartPeriodLabelPipe implements PipeTransform {

    constructor(private readonly datePipe: DatePipe) {
    }

    transform(value: Date, tenor?: InsightsPeriod): string {
        if (isDefined(tenor)) {
            let date: Date;
            switch (tenor) {
                case InsightsPeriod.DAY:
                    date = new Date(value);
                    return date.toLocaleString('en-us', {weekday: 'short'});
                case InsightsPeriod.WEEK:
                    return this.datePipe.transform(value, 'w');
                case InsightsPeriod.MONTH:
                    date = new Date(value);
                    return date.toLocaleString('en-us', {month: 'short'});
                case InsightsPeriod.QUARTER:
                    date = new Date(value);
                    return Math.floor((date.getMonth() + 3) / 3).toString();
                case InsightsPeriod.YEAR:
                    date = new Date(value);
                    return date.toLocaleString('en-us', {year: 'numeric'});
                default:
            }
        } else {
            const date = new Date(value);
            return date.toLocaleString('en-us', {month: 'short'});
        }
    }
}
