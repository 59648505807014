import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PositionOverview} from '../../../../domain/position-overview';
import {CurrencyAmount} from '../../../../domain/currency-amount';
import {ModalOptions} from '@ionic/core';
import {
    PositionOverviewDetailsModalComponent
} from '../../insights-details-modal/position-overview-details-modal/position-overview-details-modal.component';
import {ModalController} from '@ionic/angular';
import {PositionOverviewFilter} from '../../../../domain/filter/position-overview-filter';
import {TradingRatePipe} from '../../../../pipes/rate/trading-rate';
import {HedgingProfileService} from '../../../../services/hedging-profile/hedging-profile.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {DecimalAmountPipe} from '../../../../pipes/decimal-input/decimal-input.pipe';

@Component({
    selector: 'app-currency-analytic-card',
    templateUrl: './currency-analytic-card.component.html',
    styleUrls: ['./currency-analytic-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CurrencyAnalyticCardComponent implements OnInit {

    private isDetailsModalVisible = false;
    private modalObject: HTMLIonModalElement;

    readonly ONE_MILLION = 1000000;
    readonly Math = Math;
    readonly TradingRatePipe = TradingRatePipe;
    readonly DecimalAmountPipe = DecimalAmountPipe;

    tradingCurrency: CurrencyAmount;
    contraCurrency: CurrencyAmount;
    baseCcy$: Observable<string>;

    @Input() index: number;
    @Input() highlight: boolean;
    @Input() positionOverview: PositionOverview;
    @Input() positionOverviewFilter: PositionOverviewFilter;
    @Input() highlightColor: string;
    @Input() numberOfTrades: boolean;
    @Input() volume: boolean;
    @Output() cardClick = new EventEmitter<number>();

    constructor(private readonly modalController: ModalController,
                private readonly hedgingProfileService: HedgingProfileService) {
    }

    ngOnInit(): void {
        this.baseCcy$ = this.hedgingProfileService.getHedgingProfile().pipe(
            map(hedgingProfile => hedgingProfile.baseCurrency)
        );
        [this.tradingCurrency, this.contraCurrency] = this.positionOverview.totalAmount.currencyAmounts;
    }

    onCardClick(event: MouseEvent): void {
        event.stopPropagation();
        this.cardClick.emit(this.index);
    }

    async openInsightsDetailsModal(event: Event): Promise<void> {
        event.stopPropagation();
        const insightsDetailsModalOptions: ModalOptions = {
            component: PositionOverviewDetailsModalComponent,
            componentProps: {
                symbol: this.positionOverview.symbol,
                positionOverviewFilter: this.positionOverviewFilter
            }
        };
        if (!this.isDetailsModalVisible) {
            this.isDetailsModalVisible = true;
            this.modalObject = await this.modalController.create(insightsDetailsModalOptions);
            await this.modalObject.present();
            this.modalObject.onDidDismiss().then(() => this.isDetailsModalVisible = false);
        }
    }

}
