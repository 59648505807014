<div class="container" (click)="openDatePickerModal()">
    <ion-grid>
        <ion-row>
            <ion-col size="2">
                <ion-icon src="assets/icon/icon-calendar.svg" class="calendar-icon"></ion-icon>
            </ion-col>
            <ion-col size="10" class="date-text">
              <span *ngIf="isNullOrUndefined(date)" class="placeholder">
                  {{placeholder | translate}}
              </span>
                <span *ngIf="isDefined(date)">
                  {{dateDisplayValue | dateFormat}}
              </span>
            </ion-col>
        </ion-row>
    </ion-grid>
</div>
