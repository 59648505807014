<div class="container">
    <ion-grid class="ion-no-margin">
        <ion-row>
            <ion-col class="ion-text-start" size="12">
                <ion-icon src="assets/icon/icon-close.svg"
                          class="icon-close"
                          (click)="dismiss()">
                </ion-icon>
            </ion-col>
        </ion-row>
        <ion-row class="title">
            {{"insights.title" | translate}}
        </ion-row>
        <ion-segment mode="ios" [(ngModel)]="selectedOverview">
            <ion-segment-button [value]="OverviewType.SETTLEMENT_OVERVIEW">
                {{'insights.toggle-button.settlement' | translate}}
            </ion-segment-button>
            <ion-segment-button [value]="OverviewType.POSITION_OVERVIEW">
                {{'insights.toggle-button.position' | translate}}
            </ion-segment-button>
        </ion-segment>
        <app-position-overview *ngIf="selectedOverview === OverviewType.POSITION_OVERVIEW">
        </app-position-overview>
        <app-settlement-overview *ngIf="selectedOverview === OverviewType.SETTLEMENT_OVERVIEW">
        </app-settlement-overview>
    </ion-grid>
</div>
