<div class="container">
    <ion-row>
        <ion-col size="6" class="subtitle">
            <div class="subtitle-container">
                {{'insights.settlement-overview.details.net-settlements' | translate}}
            </div>
        </ion-col>
        <ion-col size="6">
            <div class="period-text">
                <ion-row>
                    <ion-col size="12" class="ion-text-start">
                        {{'insights.settlement-overview.details.settlement' | translate}}
                    </ion-col>
                </ion-row>
                <ion-row class="dates-label">
                    <ion-col size="12" class="ion-text-start">
                        {{settlementOverviewFilter.hedgeDateFrom | date}} -
                        {{settlementOverviewFilter.hedgeDateTo | date}}
                    </ion-col>
                </ion-row>
            </div>
        </ion-col>
    </ion-row>
    <ion-row class="total-card">
        <ion-col size="3" class="vertical-align">
            <div class="total-label">
                {{'insights.settlement-overview.details.total' | translate}}
            </div>
        </ion-col>
        <ion-col size="9" class="ion-text-end">
            <div class="total-amount">{{total | decimalAmount}}</div>
        </ion-col>
    </ion-row>
    <ion-list class="list">
        <ion-item *ngFor="let dateOverview of settlementOverview.dateOverviews"
                  class="ion-no-padding item">
            <app-settlement-overview-details-list-item [dateOverview]="dateOverview"
                                                       [symbol]="symbol"
                                                       [activeTenor]="activeTenor"
                                                       [settlementOverviewFilter]="settlementOverviewFilter"/>
        </ion-item>
    </ion-list>
</div>
