import {Injectable} from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {HttpConstants} from '../../commons/http-constants';
import {HttpErrorResponse, HttpEvent, HttpHeaders, HttpParams, HttpRequest, HttpResponse} from '@angular/common/http';
import {HttpOptions} from '../../commons/http/http-utils';
import {catchError, map, tap} from 'rxjs/operators';
import {HTTP, HTTPResponse} from '@awesome-cordova-plugins/http/ngx';
import {isDefined} from '../../commons/utils';
import {DeviceService} from '../device/device.service';
import {PlatformEnum} from '../../domain/platform';
import {FirebaseService} from '../firebase/firebase.service';
import {AppConstants} from '../../commons/app-constants';
import {LoadingSpinnerService} from '../loading-service/loading-spinner.service';

@Injectable({
    providedIn: 'root'
})
export class EtHttpService {
    private sslPinnedModeEnabled = false;

    constructor(private ionicHttpClient: HTTP,
                private deviceService: DeviceService,
                private loadingSpinnerService: LoadingSpinnerService) {
    }

    private static toJson<T>(value: string): T {
        if (value && value.length > 0) {
            try {
                return JSON.parse(value);
            } catch (e) {
                return value as any;
            }
        }
        return null;
    }

    private handleSuccessResponse<T>(response: HTTPResponse): HttpResponse<T> {
        return new HttpResponse({
            url: response.url,
            status: response.status,
            statusText: 'OK',
            headers: new HttpHeaders(response.headers),
            body: EtHttpService.toJson<T>(response.data)
        });
    }

    private handleErrorResponse(response: HTTPResponse): Observable<never> {
        if (isDefined(response.headers['www-authenticate'])
            && response.headers['www-authenticate'].includes(AppConstants.INVALID_USER_INFO_RESPONSE)) {
            this.loadingSpinnerService.hideAll();
        }
        return throwError(new HttpErrorResponse({
            error: EtHttpService.toJson(response.error),
            headers: new HttpHeaders(response.headers),
            status: response.status,
            statusText: response.error,
            url: response.url
        }));
    }

    initialize(): Observable<void> {
        if (isDefined(this.ionicHttpClient)) {
            const apiBasePath = FirebaseService.getApiBasePath();
            this.ionicHttpClient.setHeader(apiBasePath, HttpConstants.ACCESS_CONTROL_ALLOW_ORIGIN_HEADER, '*');
            return from(this.ionicHttpClient.setServerTrustMode('pinned')).pipe(
                tap(() => this.sslPinnedModeEnabled = true),
                catchError(error => this.deviceService.getPlatform() === PlatformEnum.WEB
                    ? of(null)
                    : throwError(error)
                ));
        }
        return of();
    }

    isSslPinnedModeEnabled(): boolean {
        return this.sslPinnedModeEnabled;
    }

    request<R>(request: HttpRequest<any>): Observable<HttpEvent<R>> {
        const httpOptions = this.getHttpOptionsFromRequest(request);
        switch (request.method) {
            case 'GET': {
                return this.get(request.urlWithParams, httpOptions);
            }
            case 'DELETE': {
                return this.delete(request.urlWithParams, httpOptions);
            }
            case 'POST': {
                return this.post(request.urlWithParams, request.body, httpOptions);
            }
            case 'PUT': {
                return this.put(request.urlWithParams, request.body, httpOptions);
            }
            default:
                throw Error('Method not supported: ' + request.method);
        }
    }

    private get<T>(url: string, options: HttpOptions): Observable<HttpResponse<T>> {
        const headers = options ? this.headersToObject(options.headers) : {};
        this.setContentType(headers);
        return from(this.ionicHttpClient.get(url, {}, headers)).pipe(
            map(response => this.handleSuccessResponse<T>(response)),
            catchError(response => this.handleErrorResponse(response))
        );
    }

    private delete<T>(url: string, options: HttpOptions): Observable<HttpResponse<T>> {
        const headers = options ? this.headersToObject(options.headers) : {};
        this.setContentType(headers);
        return from(this.ionicHttpClient.delete(url, {}, headers)).pipe(
            map(response => this.handleSuccessResponse<T>(response)),
            catchError(response => this.handleErrorResponse(response))
        );
    }

    private post<T>(url: string, body: any | null, options: HttpOptions): Observable<HttpResponse<T>> {
        const headers = options ? this.headersToObject(options.headers) : {};
        this.setContentType(headers);
        const bodyObj = this.bodyToObject(body);
        return from(this.ionicHttpClient.post(url, bodyObj, headers)).pipe(
            map(response => this.handleSuccessResponse<T>(response)),
            catchError(response => this.handleErrorResponse(response))
        );
    }

    private put<T>(url: string, body: any | null, options: HttpOptions): Observable<HttpResponse<T>> {
        const headers = options ? this.headersToObject(options.headers) : {};
        this.setContentType(headers);
        const bodyObj = this.bodyToObject(body);
        return from(this.ionicHttpClient.put(url, bodyObj, headers)).pipe(
            map(response => this.handleSuccessResponse<T>(response)),
            catchError(response => this.handleErrorResponse(response))
        );
    }

    private getHttpOptionsFromRequest(httpRequest: HttpRequest<any>): HttpOptions {
        const httpOptions = {} as HttpOptions;
        if (httpRequest.headers) {
            httpOptions.headers = httpRequest.headers;
        }
        if (httpRequest.params) {
            httpOptions.params = this.singleKeyParamsToObject(httpRequest.params);
        }
        if (httpRequest.reportProgress) {
            httpOptions.reportProgress = httpRequest.reportProgress;
        }
        if (httpRequest.withCredentials) {
            httpOptions.withCredentials = httpRequest.withCredentials;
        }
        return httpOptions;
    }

    private headersToObject(headers: HttpHeaders | { [header: string]: string | string[] }): any {
        if (headers instanceof HttpHeaders) {
            const headerObj = {};
            headers.keys().forEach(key => headerObj[key] = headers.get(key).toString());
            return headerObj;
        }
        return headers;
    }

    private singleKeyParamsToObject(params: HttpParams | { [header: string]: string | string[] }): any {
        if (params instanceof HttpParams) {
            const headerObj = {};
            params.keys()
                .filter(key => params.getAll(key).length === 1)
                .forEach(key => headerObj[key] = params.get(key).toString());
            return headerObj;
        }
        return params;
    }

    private setContentType(headerObj: object): void {
        const contentType = headerObj[HttpConstants.CONTENT_TYPE_HEADER] as string;
        if (contentType && contentType === HttpConstants.FORM_URLENCODED) {
            this.ionicHttpClient.setDataSerializer('urlencoded');
        } else {
            this.ionicHttpClient.setDataSerializer('json');
        }
    }

    private bodyToObject(body: string | object): object {
        if (body === null) {
            return {};
        }
        if (typeof body === 'string') {
            const bodyObj = {};
            body.split('&').forEach(keyValuePair => {
                const keyAndValueArray = keyValuePair.split('=');
                bodyObj[decodeURIComponent(keyAndValueArray[0])] = decodeURIComponent(keyAndValueArray[1]);
            });
            return bodyObj;
        } else if (body instanceof FormData) {
            this.ionicHttpClient.setDataSerializer('multipart');
            return body;
        } else {
            return body;
        }
    }
}
