import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModalOptions} from '@ionic/core';
import {InsightsFilterComponent} from '../insights-filter/insights-filter.component';
import {copy, isDefined} from '../../../../commons/utils';
import {PersistedFilter} from '../../../../domain/persisted-filter';
import {PositionOverviewFilter} from '../../../../domain/filter/position-overview-filter';
import {ModalController} from '@ionic/angular';
import {LocalStorageService} from '../../../../services/local-storage/local-storage.service';
import {PositionOverviewComponent} from '../../insights-modal/position-overview/position-overview.component';

@Component({
    selector: 'app-insights-header',
    templateUrl: './insights-header.component.html',
    styleUrls: ['./insights-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InsightsHeaderComponent implements OnInit {

    private isModalVisible = false;
    private modalObject: HTMLIonModalElement;

    @Input() isTradingVolumeSelected: boolean;
    @Input() positionOverviewFilter: PositionOverviewFilter;
    @Output() onFilterClosed = new EventEmitter<PositionOverviewFilter>();

    constructor(private readonly localStorageService: LocalStorageService,
                private readonly modalController: ModalController) {
    }

    ngOnInit(): void {
    }

    async openFilters(): Promise<void> {
        const filtersModalOptions: ModalOptions = {
            component: InsightsFilterComponent,
            componentProps: {
                positionOverviewFilter: copy(this.positionOverviewFilter)
            }
        };

        if (!this.isModalVisible) {
            this.isModalVisible = true;
            this.modalObject = await this.modalController.create(filtersModalOptions);
            this.modalObject.onDidDismiss().then(modalData => {
                if (isDefined(modalData.data)) {
                    const positionOverviewFilter = modalData.data.positionOverviewFilter;
                    const persistedFilter: PersistedFilter<PositionOverviewFilter> = {
                        filter: positionOverviewFilter,
                        timeStamp: new Date().toString()
                    };
                    this.localStorageService.setItem(PositionOverviewComponent.FILTER_KEY, persistedFilter);
                    this.onFilterClosed.emit(positionOverviewFilter);
                }
                this.isModalVisible = false;
            });
            await this.modalObject.present();
        }
    }

}
