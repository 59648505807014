<ion-item>
    <ion-grid>
        <ion-row (click)="toggleExpand()">
            <ion-col size="6" class="ion-text-start"
                     [class.bold]="isFilterActive">
                {{filterNameKey | translate}}
            </ion-col>
            <ion-col size="5" class="ion-text-end">
                <span *ngIf="selectedFilterItem && canDisable"
                      class="font-color-light"
                      (click)="clearFilter($event)">
                    {{'reports.clear' | translate}}
                </span>
            </ion-col>
            <ion-col size="1" class="ion-text-end">
                <ion-icon src="assets/icon/icon-expand-arrow.svg" [class.vertical-flip]="isExpanded"></ion-icon>
            </ion-col>
        </ion-row>
        <div *ngIf="isExpanded">
            <div *ngIf="filterItems.length > 0">
                <div *ngFor="let filterItem of filterItems; let i = index">
                    <app-filter-checkbox [checked]="selectedFilterItem === filterItem"
                                         [label]="filterItem"
                                         [disabled]="selectedFilterItem === filterItem && !canDisable"
                                         (change)="onFilterSelect($event, filterItem)">
                    </app-filter-checkbox>
                </div>
            </div>
            <div *ngIf="filterItems.length === 0">
                <span class="no-items">{{'hedged-orders.filters.filter-names.no-items' | translate}}</span>
            </div>
        </div>
    </ion-grid>
</ion-item>
