import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HttpUtils} from '../../commons/http/http-utils';
import {Observable} from 'rxjs';
import {FirebaseService} from '../firebase/firebase.service';

@Injectable({
    providedIn: 'root'
})
export class PinCodeService {

    private readonly url = `${FirebaseService.getIssuer()}/custom-credentials/pin`;

    constructor(private http: HttpClient) {
    }

    createPin(pinCode: string, pinCodeConfirmation: string): Observable<any> {
        const pinDTO = {
            'pinCode': pinCode,
            'pinCodeConfirmation': pinCodeConfirmation
        };
        return this.http.post(this.url, pinDTO, HttpUtils.getDefaultRequestOptions());
    }

    hasPin(): Observable<any> {
        return this.http.get(this.url, HttpUtils.getDefaultRequestOptions());
    }

    validPin(userId: string, pin: string): Observable<any> {
        const pinValidationDTO = {
            'userId': userId,
            'pin': pin
        };
        return this.http.post(this.url + '/valid', pinValidationDTO, HttpUtils.getDefaultRequestOptions());
    }

    changePin(oldPinCode: string, newPinCode: string, newPinCodeConfirmation: string): Observable<any> {
        const pinChangeDTO = {
            'oldPinCode': oldPinCode,
            'newPinCode': newPinCode,
            'newPinCodeConfirmation': newPinCodeConfirmation
        };
        return this.http.put(this.url, pinChangeDTO, HttpUtils.getDefaultRequestOptions());
    }

}
