<ion-item lines="none" class="ion-no-padding" (click)="onCardClick($event)">
    <div class="card" [class.highlight]="highlight">
        <div *ngIf="highlight" class="highlight-color" [style.background]="highlightColor"></div>
        <ion-grid>
            <ion-row>
                <ion-col size="11">
                    <ion-row class="ion-padding-start">
                        <ion-col size="8" class="ion-text-start symbol">{{positionOverview.symbol}}</ion-col>
                        <ion-col size="4" class="ion-text-center">
                            <div *ngIf="numberOfTrades" class="trades">
                                <span class="trades-count">{{positionOverview.tradesCount}}</span>
                                <span>{{'insights.labels.trades' | translate}}</span>
                            </div>
                            <ng-container *ngIf="baseCcy$ | async as baseCcy">
                                <div *ngIf="volume" class="trades">
                                    <span class="trades-count">{{positionOverview.tradingVolume | thousandSuffixes}}</span>
                                    <span>{{baseCcy}}</span>
                                </div>
                            </ng-container>
                        </ion-col>
                    </ion-row>
                    <ion-row class="ion-padding-start">
                        <ion-col size="4" class="ion-text-start currency-bold">{{tradingCurrency.currency}}</ion-col>
                        <ion-col size="4" class="ion-text-start currency-bold">{{contraCurrency.currency}}</ion-col>
                        <ion-col size="4" class="ion-text-start rate-grey vertical-line"><span></span>
                            {{'insights.labels.average-rate' | translate}}
                        </ion-col>
                    </ion-row>
                    <ion-row class="ion-padding-start">
                        <ion-col size="4"
                                 class="ion-text-start"
                                 [class.rate]="Math.abs(tradingCurrency.amount) < ONE_MILLION"
                                 [class.rate-small]="Math.abs(tradingCurrency.amount) >= ONE_MILLION">
                            {{tradingCurrency.amount | decimalAmount: DecimalAmountPipe.AMOUNT_FORMAT_0_DIGITS}}
                        </ion-col>
                        <ion-col size="4"
                                 class="ion-text-start"
                                 [class.rate]="Math.abs(contraCurrency.amount) < ONE_MILLION"
                                 [class.rate-small]="Math.abs(contraCurrency.amount) >= ONE_MILLION">
                            {{contraCurrency.amount | decimalAmount: DecimalAmountPipe.AMOUNT_FORMAT_0_DIGITS}}
                        </ion-col>
                        <ion-col size="4"
                                 class="ion-text-start rate-grey vertical-line">
                            {{positionOverview.totalAmount.averageRate| tradingRate:TradingRatePipe.FOUR_RATE_FORMAT}}
                        </ion-col>
                    </ion-row>
                </ion-col>
                <ion-col size="1">
                    <ion-icon src="assets/icon/icon-arrow-right.svg"
                              class="icon-details"
                              (click)="openInsightsDetailsModal($event)"></ion-icon>
                </ion-col>
            </ion-row>
            <ion-row></ion-row>
        </ion-grid>
    </div>
</ion-item>
