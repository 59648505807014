import * as Highcharts from 'highcharts';
import {WrapProceedFunction} from 'highcharts';
import * as Variablepie from 'highcharts/modules/variable-pie';

export class HighchartsCustomizations {

    static init(): void {
        this.customizeChart(Highcharts);
        // @ts-ignore
        Variablepie(Highcharts);
    }

    /**
     * Override the chart {@link drawPoints} method to use a path element instead of a rect to draw each bar and add
     * custom border radius to the top left and right of each bar, while keeping it 0 for the bottom.
     */
    public static customizeChart(H: any): void {
        H.wrap(H.seriesTypes.column.prototype, 'drawPoints', function (proceed: WrapProceedFunction): void {
            if (this.chart.options['applyCustomisation']) {
                const topMargin = this.options.topMargin || 0;
                const bottomMargin = this.options.bottomMargin || 0;

                this.points
                    .filter(point => point.y !== 0)
                    .forEach(point => {
                        point.dlBox = point.shapeArgs;

                        point.shapeType = 'path';
                        const w = point.shapeArgs.width;
                        const h = point.shapeArgs.height;
                        const x = point.shapeArgs.x;
                        const y = point.shapeArgs.y;

                        const radiusTopLeft = H.relativeLength(5, w);
                        const radiusTopRight = H.relativeLength(5, w);

                        point.shapeArgs = {
                            d: [
                                'M', x + radiusTopLeft, y + topMargin,
                                'L', x + w - radiusTopRight, y + topMargin,
                                'C', x + w - radiusTopRight / 2, y, x + w, y + radiusTopRight / 2, x + w, y + radiusTopRight,
                                'L', x + w, y + h,
                                'C', x + w, y + h, x + w, y + h, x + w, y + h + bottomMargin,
                                'L', x, y + h + bottomMargin,
                                'C', x, y + h, x, y + h, x, y + h,
                                'L', x, y + radiusTopLeft,
                                'C', x, y + radiusTopLeft / 2, x + radiusTopLeft / 2, y, x + radiusTopLeft, y,
                                'Z'
                            ]
                        };
                    });
            }
            proceed.apply(this, Array.prototype.slice.call(arguments, 1));
        });
    }
}

