import {Pipe, PipeTransform} from '@angular/core';
import {CompanyService} from '../../services/company/company.service';

@Pipe({
    name: 'companyShortName'
})
export class CompanyShortNamePipe implements PipeTransform {

    constructor() {
    }

    transform(companyCode: string): string {
        return CompanyService.getCachedCompanies().find(company => company.code === companyCode)?.shortName;
    }
}
