import {AuditData} from './audit-data';

export class UserFeedback extends AuditData {
    id: string;
    campaignName: string;
    dataPlatform: string;
    ratingValue: number;
    feedback: string;
    metric: string;
    userId: string;
    firstName: string;
    lastName: string;
    companyCode: string;
    companyName: string;
}

export class UserFeedbackConstants {
    public static readonly MAX_RATING_VALUE = 5;
    public static readonly CSAT = 'CSAT';
    public static readonly CES = 'CES';
}
