<div class="smartlook-hide keyboard ion-no-padding">
    <ion-row class="first-row">
        <ion-col>
            <div class="keyboard-button right-border bottom-border ion-activatable"
                 (click)="handleInput('1')">1
                <ion-ripple-effect class="ripple-color"/>
            </div>
        </ion-col>
        <ion-col>
            <div class="keyboard-button right-border bottom-border ion-activatable"
                 (click)="handleInput('2')">2
                <ion-ripple-effect class="ripple-color"/>
            </div>
        </ion-col>
        <ion-col>
            <div class="keyboard-button bottom-border ion-activatable"
                 (click)="handleInput('3')">3
                <ion-ripple-effect class="ripple-color"/>
            </div>
        </ion-col>
    </ion-row>
    <ion-row>
        <ion-col>
            <div class="keyboard-button bottom-border right-border ion-activatable"
                 (click)="handleInput('4')">4
                <ion-ripple-effect class="ripple-color"/>
            </div>
        </ion-col>
        <ion-col>
            <div class="keyboard-button bottom-border right-border ion-activatable"
                 (click)="handleInput('5')">5
                <ion-ripple-effect class="ripple-color"/>
            </div>
        </ion-col>
        <ion-col>
            <div class="keyboard-button bottom-border ion-activatable"
                 (click)="handleInput('6')">6
                <ion-ripple-effect class="ripple-color"/>
            </div>
        </ion-col>
    </ion-row>
    <ion-row>
        <ion-col>
            <div class="keyboard-button right-border bottom-border ion-activatable"
                 (click)="handleInput('7')">7
                <ion-ripple-effect class="ripple-color"/>
            </div>
        </ion-col>
        <ion-col>
            <div class="keyboard-button right-border bottom-border ion-activatable"
                 (click)="handleInput('8')">8
                <ion-ripple-effect class="ripple-color"/>
            </div>
        </ion-col>
        <ion-col>
            <div class="keyboard-button bottom-border ion-activatable"
                 (click)="handleInput('9')">9
                <ion-ripple-effect class="ripple-color"/>
            </div>
        </ion-col>
    </ion-row>
    <ion-row>
        <ion-col>
            <div class="keyboard-button right-border ion-activatable"
                 (click)="loginWithBiometrics()">
                <ion-icon *ngIf="biometricType === BiometricType.FACE"
                          [src]="'assets/icon/faceid.svg'"
                          class="biometrics"/>
                <ion-icon *ngIf="biometricType === BiometricType.FINGER"
                          name="finger-print-outline"
                          class="biometrics"/>
                <ion-ripple-effect class="ripple-color"/>
            </div>
        </ion-col>
        <ion-col>
            <div class="keyboard-button right-border bottom-border ion-activatable"
                 (click)="handleInput('0')">0
                <ion-ripple-effect class="ripple-color"/>
            </div>
        </ion-col>
        <ion-col>
            <div class="keyboard-button ion-activatable"
                 (click)="handleInput('delete')">
                <ion-icon name="backspace" class="delete-icon"/>
                <ion-ripple-effect class="ripple-color"/>
            </div>
        </ion-col>
    </ion-row>
</div>
