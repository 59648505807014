<ion-grid class="open-hours">
    <ion-row class="text-small">
        <ng-container *ngIf="tradingScheduleConfiguration$ | async as tradingScheduleConfiguration">
            <ion-col *ngIf="tradingScheduleConfiguration" size="6" class="ion-text-start">
                <div [class.open]="tradingScheduleConfiguration.scheduleInterval === ScheduleInterval.INSIDE"
                     [class.closed]="tradingScheduleConfiguration.scheduleInterval === ScheduleInterval.OUTSIDE">
                    <div class="opening-hours-trading">{{'opening-hours.trading' | translate}}</div>
                    <ion-badge class="status-badge"
                               [class.badge-open]="tradingScheduleConfiguration.scheduleInterval === ScheduleInterval.INSIDE"
                               [class.badge-closed]="tradingScheduleConfiguration.scheduleInterval === ScheduleInterval.OUTSIDE">
                        {{'opening-hours.' + tradingScheduleConfiguration.scheduleInterval | lowercase | translate}}
                    </ion-badge>
                    <div>
                        <span *ngIf="tradingScheduleConfiguration.scheduleInterval === ScheduleInterval.INSIDE">
                            {{'opening-hours.closes-date' | translate: getScheduleConfigurationTranslateArgs(tradingScheduleConfiguration)}}
                            <br>
                            {{'opening-hours.closes-time' | translate: getScheduleConfigurationTranslateArgs(tradingScheduleConfiguration)}}
                        </span>
                        <span *ngIf="tradingScheduleConfiguration.scheduleInterval === ScheduleInterval.OUTSIDE">
                            {{'opening-hours.open-date' | translate: getScheduleConfigurationTranslateArgs(tradingScheduleConfiguration)}}
                            <br>
                            {{'opening-hours.open-time' | translate: getScheduleConfigurationTranslateArgs(tradingScheduleConfiguration)}}
                        </span>
                    </div>
                </div>
            </ion-col>
        </ng-container>
        <ng-container *ngIf="supportScheduleConfiguration$ | async as supportScheduleConfiguration">
            <ion-col *ngIf="supportScheduleConfiguration" size="6" class="ion-text-end">
                <div [class.open]="supportScheduleConfiguration.scheduleInterval === ScheduleInterval.INSIDE"
                     [class.closed]="supportScheduleConfiguration.scheduleInterval === ScheduleInterval.OUTSIDE">
                    <div class="opening-hours-support">{{'opening-hours.support' | translate}}</div>
                    <ion-badge class="status-badge"
                               [class.badge-open]="supportScheduleConfiguration.scheduleInterval === ScheduleInterval.INSIDE"
                               [class.badge-closed]="supportScheduleConfiguration.scheduleInterval === ScheduleInterval.OUTSIDE">
                        {{'opening-hours.' + supportScheduleConfiguration.scheduleInterval | lowercase | translate}}
                    </ion-badge>
                    <div>
                        <span *ngIf="supportScheduleConfiguration.scheduleInterval === ScheduleInterval.INSIDE">
                            {{'opening-hours.closes-date' | translate: getScheduleConfigurationTranslateArgs(supportScheduleConfiguration)}}
                            <br>
                            {{'opening-hours.closes-time' | translate: getScheduleConfigurationTranslateArgs(supportScheduleConfiguration)}}
                        </span>
                        <span *ngIf="supportScheduleConfiguration.scheduleInterval === ScheduleInterval.OUTSIDE
                                     && isDefined(supportScheduleConfiguration.formattedDate)">
                            {{'opening-hours.open-date' | translate: getScheduleConfigurationTranslateArgs(supportScheduleConfiguration)}}
                            <br>
                            {{'opening-hours.open-time' | translate: getScheduleConfigurationTranslateArgs(supportScheduleConfiguration)}}
                        </span>
                    </div>
                </div>
            </ion-col>
        </ng-container>
    </ion-row>
</ion-grid>
