import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';

import {IonicModule} from '@ionic/angular';

import {GeneralInfoPage} from './general-info.page';
import {CommonsModule} from '../../commons/commons.module';

const routes: Routes = [
    {
        path: '',
        component: GeneralInfoPage
    }
];

@NgModule({
    imports: [
        CommonsModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
    ],
    declarations: [GeneralInfoPage]
})
export class GeneralInfoPageModule {
}
