import {Injectable} from '@angular/core';
import {User} from '../../domain/user';
import {Observable, of, Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {hasValues, isDefined, isNullOrUndefined} from '../../commons/utils';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {HttpUtils} from '../../commons/http/http-utils';
import {FirebaseService} from '../firebase/firebase.service';
import {TenantRoles} from '../../domain/tenant-roles';
import {UserUtils} from '../../utils/user-utils';
import {CompanyService} from '../company/company.service';

@Injectable({
    providedIn: 'root'
})
export class PrincipalService {
    private userIdentity: User;
    private authenticated = false;
    private authenticationState = new Subject<User>();
    private userIdentitySubject: Subject<User>;

    constructor(private readonly http: HttpClient) {
    }

    authenticate(identity: User): void {
        this.userIdentity = identity;
        this.authenticated = !isNullOrUndefined(identity);
        this.authenticationState.next(this.userIdentity);
    }

    hasAnyAuthority(authorities: string[]): Observable<boolean> {
        return of(this.hasAnyAuthorityDirect(authorities));
    }

    hasAnyAuthorityDirect(authorities: string[]): boolean {
        if (isDefined(this.userIdentity)) {
            const tenantRoles = this.userIdentity.tenantRoles;
            if (!this.authenticated || !this.userIdentity || !tenantRoles) {
                return false;
            }

            for (let i = 0; i < authorities.length; i++) {
                const tenant = isDefined(CompanyService.getCurrentCompany())
                    ? CompanyService.getCurrentCompany().code
                    : UserUtils.getDefaultTenant(this.userIdentity);
                if (this.hasAuthorityInTenant(tenant, authorities[i], tenantRoles)) {
                    return true;
                }
            }
        }
        return false;
    }

    hasAuthority(authority: string): Observable<boolean> {
        let tenant;
        return this.identity().pipe(
            tap(user => tenant = isDefined(CompanyService.getCurrentCompany())
                ? CompanyService.getCurrentCompany().code
                : UserUtils.getDefaultTenant(user)),
            switchMap(user =>
                of(this.hasAuthorityInTenant(tenant, authority, user.tenantRoles))),
            catchError(() => of(false))
        );
    }

    identity(force?: boolean): Observable<User> {
        if (force === true) {
            this.userIdentity = undefined;
        }

        if (isDefined(this.userIdentity)) {
            return of(this.userIdentity);
        }

        if (isDefined(this.userIdentitySubject)) {
            return this.userIdentitySubject;
        }

        this.userIdentitySubject = new Subject<User>();
        this.getCurrentUser().subscribe(
            account => {
                this.userIdentity = account;
                this.authenticated = true;
                this.authenticationState.next(this.userIdentity);

                const userIdentitySubject = this.userIdentitySubject;
                this.userIdentitySubject = null;
                userIdentitySubject.next(this.userIdentity);
                userIdentitySubject.complete();
            },
            () => {
                this.userIdentity = null;
                this.authenticated = false;
                this.authenticationState.next(this.userIdentity);

                const userIdentitySubject = this.userIdentitySubject;
                this.userIdentitySubject = null;
                userIdentitySubject.next(this.userIdentity);
                userIdentitySubject.complete();
            });
        return this.userIdentitySubject;
    }

    getAuthenticationState(): Observable<User> {
        return this.authenticationState.asObservable();
    }

    private hasAuthorityInTenant(tenant: string,
                                 authority: string,
                                 tenantRoles: TenantRoles[]): boolean {
        if (tenantRoles) {
            const tenantRole = tenantRoles.find(userRole => userRole.tenant === tenant);
            return isDefined(tenantRole) && isDefined(tenantRole.authorities.find(auth => auth === authority));
        }
        return false;
    }

    private getCurrentUser(): Observable<User> {
        const url = FirebaseService.getApiBasePath() + '/api/global/account';
        return this.http.get<User>(url, HttpUtils.getRequestOptions());
    }

    isAuthorized(): Observable<boolean> {
        return this.identity()
            .pipe(
                map(user => hasValues(user?.tenantRoles[0]?.authorities))
            );
    }
}

