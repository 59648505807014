import {Pipe} from '@angular/core';
import {BaseRatePipe} from './base-rate';
import {AppLanguage} from '../../domain/app-language';

@Pipe({
    name: 'chartRate'
})
export class ChartRatePipe extends BaseRatePipe {

    getLanguage(): string {
        return AppLanguage.EN;
    }
}
