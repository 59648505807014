import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {SettlementOverviewFilter} from '../../../../domain/filter/settlement-overview-filter';
import {HttpConstants} from '../../../../commons/http-constants';
import {FieldNames} from '../../../../commons/field-names';
import {Pageable} from '../../../../commons/pageable';
import {Sort, SortDirection} from '../../../../commons/sort';
import {map, switchMap, tap} from 'rxjs/operators';
import {BehaviorSubject, Observable, zip} from 'rxjs';
import {SettlementOverview} from '../../../../domain/settlement-overview';
import {InsightsService} from '../../../../services/insights/insights.service';
import {HedgingProfileService} from '../../../../services/hedging-profile/hedging-profile.service';
import {DateTimeUtilService} from '../../../../services/date-time-util/date-time-util.service';
import {ModalOptions} from '@ionic/core';
import {copy, isDefined, isNullOrUndefined} from '../../../../commons/utils';
import {
    SettlementOverviewFilterComponent
} from '../../components/settlement-overview-filter/settlement-overview-filter.component';
import {InsightsPeriod} from '../../../../domain/insights-period';
import {TimePeriod} from '../../../../domain/time-period';
import {DateOverview} from '../../../../domain/date-overview';

@Component({
    selector: 'app-settlement-overview-details-modal',
    templateUrl: './settlement-overview-details-modal.component.html',
    styleUrls: ['./settlement-overview-details-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettlementOverviewDetailsModalComponent implements OnInit {

    private readonly pageSize = HttpConstants.DEFAULT_PAGE_SIZE;
    private readonly FILTER_DAYS = 30;

    private modalObject: HTMLIonModalElement;
    private isModalVisible = false;
    private sort = FieldNames.HEDGE_DATE_FROM;
    private page = 0;

    settlementOverviewFilter = new SettlementOverviewFilter();
    availableTenors: InsightsPeriod[] = [];
    activeTenor: InsightsPeriod;
    noCurrencyData = true;

    baseCurrency$: Observable<string>;
    getData$: BehaviorSubject<InsightsPeriod>;
    settlementOverview$: Observable<SettlementOverview>;
    pageData$: Observable<[SettlementOverview, string]>;

    @Input() symbol;

    constructor(private readonly modalController: ModalController,
                private readonly insightsService: InsightsService,
                private readonly hedgingProfileService: HedgingProfileService) {
    }

    ngOnInit(): void {
        this.settlementOverviewFilter.hedgeDateFrom = DateTimeUtilService.formatToIsoDate(new Date());

        const futureDate = new Date();
        futureDate.setDate(futureDate.getDate() + this.FILTER_DAYS);

        this.settlementOverviewFilter.hedgeDateTo = DateTimeUtilService
            .formatToIsoDate(futureDate);

        this.setAvailableTenors();
        this.getData$ = new BehaviorSubject<InsightsPeriod>(null);
        this.baseCurrency$ = this.getData$.pipe(
            switchMap(() => this.hedgingProfileService.getHedgingProfile()),
            map(hedgingProfile => hedgingProfile.baseCurrency)
        );

        this.getSettlementOverview();
    }

    goBack(): void {
        this.modalController.dismiss().then();
    }

    private getSettlementOverview(): void {
        const pageable: Pageable = {
            page: this.page,
            size: this.pageSize,
            sort: Sort.by(this.sort, SortDirection.DESC)
        };

        this.settlementOverview$ = this.getData$.pipe(
            tap(period => this.activeTenor = period),
            tap(() => this.checkMinDate()),
            switchMap(period => this.insightsService.getSettlementOverviews(
                    this.settlementOverviewFilter,
                    pageable,
                    period
                )
            ),
            tap(settlement => this.noCurrencyData = settlement.currencyOverviews
                .findIndex(overview => overview.currency === this.symbol) === -1),
            tap(settlement => this.setAvailableTenors(settlement.dateOverviews))
        );

        this.pageData$ = zip(this.settlementOverview$, this.baseCurrency$);
    }

    async openFilters(): Promise<void> {
        const filtersModalOptions: ModalOptions = {
            component: SettlementOverviewFilterComponent,
            componentProps: {
                settlementOverviewFilter: copy(this.settlementOverviewFilter)
            }
        };

        if (!this.isModalVisible) {
            this.isModalVisible = true;
            this.modalObject = await this.modalController.create(filtersModalOptions);
            this.modalObject.onDidDismiss().then(modalData => {
                if (isDefined(modalData.data)) {
                    this.settlementOverviewFilter = modalData.data.settlementOverviewFilter;
                    const period = this.timePeriodToTenors({
                        startDate: new Date(this.settlementOverviewFilter.hedgeDateFrom),
                        endDate: new Date(this.settlementOverviewFilter.hedgeDateTo)
                    })[0];
                    this.getData$.next(period);
                }
                this.isModalVisible = false;
            });
            await this.modalObject.present();
        }
    }

    private setAvailableTenors(dateOverviews?: DateOverview[]): void {
        const startDate = new Date(isDefined(this.settlementOverviewFilter.hedgeDateFrom)
            ? this.settlementOverviewFilter.hedgeDateFrom
            : dateOverviews[0].hedgeDatePeriod.startDate);

        const endDate = new Date(isDefined(this.settlementOverviewFilter.hedgeDateTo)
            ? this.settlementOverviewFilter.hedgeDateTo
            : dateOverviews[dateOverviews.length - 1].hedgeDatePeriod.endDate);

        const timePeriod: TimePeriod = {
            startDate,
            endDate
        };
        this.availableTenors = this.timePeriodToTenors(timePeriod);
    }

    private timePeriodToTenors(timePeriod: TimePeriod): InsightsPeriod[] {
        const differenceInTime = timePeriod.endDate.getTime() - timePeriod.startDate.getTime();
        const differenceInDays = differenceInTime / (1000 * 3600 * 24) + 1;

        if (differenceInDays < 8) {
            this.activeTenor = InsightsPeriod.DAY;
            return [InsightsPeriod.DAY];
        } else if (differenceInDays > 7 && differenceInDays < 32) {
            this.activeTenor = InsightsPeriod.WEEK;
            return [InsightsPeriod.WEEK];
        } else if (differenceInDays > 31 && differenceInDays < 93) {
            const tenors = [InsightsPeriod.WEEK, InsightsPeriod.MONTH];
            if (isNullOrUndefined(this.activeTenor)) {
                this.activeTenor = InsightsPeriod.WEEK;
            }
            return tenors;
        } else if (differenceInDays > 92 && differenceInDays < 366) {
            const tenors = [InsightsPeriod.MONTH, InsightsPeriod.QUARTER];
            if (isNullOrUndefined(this.activeTenor)) {
                this.activeTenor = InsightsPeriod.MONTH;
            }
            return tenors;
        } else {
            const tenors = [InsightsPeriod.QUARTER, InsightsPeriod.YEAR];
            if (isNullOrUndefined(this.activeTenor)) {
                this.activeTenor = InsightsPeriod.QUARTER;
            }
            return tenors;
        }
    }

    onTenorSelect(tenor: InsightsPeriod): void {
        this.activeTenor = tenor;
        this.getData$.next(this.activeTenor);
    }

    checkMinDate(): void {
        if (isNullOrUndefined(this.settlementOverviewFilter.hedgeDateFrom)) {
            this.settlementOverviewFilter.hedgeDateFrom = DateTimeUtilService.formatToIsoDate(new Date());
        }
    }
}
