import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import SwiperCore, {Pagination} from 'swiper';
import {OverviewType} from '../../../domain/overview-type';

SwiperCore.use([Pagination]);

@Component({
    selector: 'app-insights-modal',
    templateUrl: './insights-modal.component.html',
    styleUrls: ['./insights-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InsightsModalComponent implements OnInit {
    public readonly OverviewType = OverviewType;

    public selectedOverview: string;

    constructor(private readonly modalController: ModalController) {
    }

    dismiss(): void {
        this.modalController.dismiss().then();
    }

    ngOnInit(): void {
        this.selectedOverview = OverviewType.SETTLEMENT_OVERVIEW;
    }
}
