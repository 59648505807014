import {Pipe, PipeTransform} from '@angular/core';
import {DateTimeUtilService} from '../../services/date-time-util/date-time-util.service';

@Pipe({
    name: 'timeSince'
})
export class TimeSincePipe implements PipeTransform {

    constructor(private dateTimeUtilService: DateTimeUtilService) {
    }

    transform(date: Date): string {
        return this.dateTimeUtilService.getTimeSinceLabel(date);
    }
}
