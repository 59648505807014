<div class="container">
    <ion-row>
        <ion-col size="12" class="ion-text-start text-light period-header">
            <span *ngIf="activeTenor === InsightsPeriod.DAY">
                 {{startDate | date}}
            </span>
            <span *ngIf="activeTenor !== InsightsPeriod.DAY">
                 {{startDate | date}} - {{endDate| date}}
            </span>
        </ion-col>
    </ion-row>
    <ion-row>
        <ion-col size="4">
            <ion-row>
                <ion-col size="12" class="text-light">
                    {{'insights.total' | translate}}
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col size="12" class="text-normal-bold">
                    {{(dateOverviewPosition?.totalNetAmount | decimalAmount) ?? '-'}}
                </ion-col>
            </ion-row>
        </ion-col>
        <ion-col size="4">
            <ion-row>
                <ion-col size="2">
                    <div class="pill-light"></div>
                </ion-col>
                <ion-col size="10">
                    <ion-row>
                        <ion-col size="12" class="text-light">{{'insights.side.BUY' | translate}}</ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col size="12" class="text-normal">
                            {{(dateOverviewPosition?.buyAmount | decimalAmount) ?? '-'}}
                        </ion-col>
                    </ion-row>
                </ion-col>
            </ion-row>
        </ion-col>
        <ion-col size="4">
            <ion-row>
                <ion-col size="2">
                    <div class="pill-dark"></div>
                </ion-col>
                <ion-col size="10">
                    <ion-row>
                        <ion-col size="12" class="text-light">{{'insights.side.SELL' | translate}}</ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col size="12" class="text-normal">
                            {{(dateOverviewPosition?.sellAmount | decimalAmount) ?? '-'}}
                        </ion-col>
                    </ion-row>
                </ion-col>
            </ion-row>
        </ion-col>
    </ion-row>
</div>
