import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Moment} from 'moment';
import {isDefined} from '../../../../../commons/utils';

@Component({
    selector: 'app-expandable-date-filter',
    templateUrl: './expandable-date-filter.component.html',
    styleUrls: ['./expandable-date-filter.component.css'],
})
export class ExpandableDateFilterComponent implements OnInit, OnChanges {

    isExpanded = false;
    isFilterActive: boolean;

    @Input() filterNameKey: string;
    @Input() dateFrom: Moment;
    @Input() dateTo: Moment;
    @Input() minDateFrom: Moment;
    @Input() maxDateFrom: Moment;
    @Input() minDateTo: Moment;
    @Input() maxDateTo: Moment;
    @Input() clearable = true;
    @Output() changeDateFromFilter = new EventEmitter<Moment>();
    @Output() changeDateToFilter = new EventEmitter<Moment>();

    constructor() {
    }

    ngOnInit(): void {
        this.isFilterActive = isDefined(this.dateFrom) || isDefined(this.dateTo);
    }

    toggleExpand(): void {
        this.isExpanded = !this.isExpanded;
    }

    onDateFromChanged(dateFrom: Moment): void {
        if (dateFrom.isAfter(this.dateTo) && isDefined(this.dateTo)) {
            this.dateFrom = this.dateTo;
            this.onDateToChanged(dateFrom);
        } else {
            this.dateFrom = dateFrom;
        }

        this.changeDateFromFilter.emit(this.dateFrom);
        this.isFilterActive = isDefined(this.dateFrom) || isDefined(this.dateTo);
    }

    onDateToChanged(dateTo: Moment): void {
        if (dateTo.isBefore(this.dateFrom) && isDefined(this.dateFrom)) {
            this.dateTo = this.dateFrom;
            this.onDateFromChanged(dateTo);
        } else {
            this.dateTo = dateTo;
        }

        this.changeDateToFilter.emit(this.dateTo);
        this.isFilterActive = isDefined(this.dateFrom) || isDefined(this.dateTo);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.isFilterActive = isDefined(this.dateFrom) || isDefined(this.dateTo);
    }

    clearFilter(event: MouseEvent): void {
        event.stopPropagation();
        this.dateFrom = null;
        this.dateTo = null;
        this.isFilterActive = false;
        this.changeDateFromFilter.emit();
        this.changeDateToFilter.emit();
    }
}
