import {Pipe} from '@angular/core';
import {BaseRatePipe} from './base-rate';
import {DecimalAmountPipe} from '../decimal-input/decimal-input.pipe';

@Pipe({
    name: 'marketRate'
})
export class MarketRatePipe extends BaseRatePipe {

    constructor(decimalAmountPipe: DecimalAmountPipe) {
        super(decimalAmountPipe);
    }
}
