<div class="card ion-no-padding ion-no-margin">
    <ion-row>
        <ion-col *ngIf="isNetPosition" size="1" class="ion-no-padding ion-align-items-end">
            <div class="legend-color trading-ccy-buy-color"></div>
        </ion-col>
        <ion-col [size]="isNetPosition ? 3 : 4" [class.ccy-value-column]="!isNetPosition" class="ion-no-padding">
            <ion-row class="trading-ccy">
                {{tradingCurrency.currency}}
            </ion-row>
            <ion-row [class.trading-ccy-value]="Math.abs(tradingCurrency.amount) < TEN_MILLION"
                     [class.amount-ccy-small]="Math.abs(tradingCurrency.amount) >= TEN_MILLION">
                {{tradingCurrency.amount | decimalAmount:DecimalAmountPipe.AMOUNT_FORMAT_0_DIGITS}}
            </ion-row>
        </ion-col>
        <ion-col *ngIf="isNetPosition" size="1" class="ion-no-padding ion-align-items-end">
            <div class="legend-color contra-ccy-buy-color"></div>
        </ion-col>
        <ion-col [size]="isNetPosition ? 3 : 4" [class.ccy-value-column]="!isNetPosition" class="ion-no-padding">
            <ion-row class="contra-ccy">
                {{contraCurrency.currency}}
            </ion-row>
            <ion-row [class.contra-ccy-value]="Math.abs(contraCurrency.amount) < TEN_MILLION"
                     [class.amount-ccy-small]="Math.abs(contraCurrency.amount) >= TEN_MILLION">
                {{contraCurrency.amount | decimalAmount:DecimalAmountPipe.AMOUNT_FORMAT_0_DIGITS}}
            </ion-row>
        </ion-col>
        <ion-col size="4" class="rate vertical-line">
            <ion-row class="rate-label">{{'insights.labels.average-rate' | translate}}</ion-row>
            <ion-row
                    class="rate-value">{{positionOverview.totalAmount.averageRate | tradingRate: TradingRatePipe.FOUR_RATE_FORMAT}}
            </ion-row>
        </ion-col>
    </ion-row>
</div>

