import {Pipe, PipeTransform} from '@angular/core';
import {isNullOrUndefined} from '../../commons/utils';

@Pipe({
    name: 'phone',
})
export class PhonePipe implements PipeTransform {
    private readonly PHONE_NUMBER_SPECIAL_CHARACTERS_REGEX = new RegExp(/[\s-]/g);

    transform(value: string): string {
        if (isNullOrUndefined(value)) {
            return null;
        }

        return value.replace(this.PHONE_NUMBER_SPECIAL_CHARACTERS_REGEX, '');
    }
}
