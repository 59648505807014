import {Injectable, OnInit} from '@angular/core';
import {AppConstants} from '../../commons/app-constants';
import {isDefined} from '../../commons/utils';
import {forkJoin, Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {getLocaleNumberSymbol, NumberSymbol} from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class GlobalizationService {

    private locale: string;
    private decimalSeparator: string;
    private groupSeparator: string;

    constructor(private translateService: TranslateService) {
    }

    private static convertToObservable(func: Function): Observable<any> {
        return new Observable(observer =>
            func.call(func, result => {
                observer.next(result);
                observer.complete();
            }, err => {
                observer.error(err);
                observer.complete();
            })
        );
    }

    initGlobalization(): void {
        const globalization = navigator[AppConstants.GLOBALIZATION];
        if (isDefined(globalization)) {
            const localeObservable = GlobalizationService.convertToObservable(globalization.getLocaleName);
            const numberPatternObservable = GlobalizationService.convertToObservable(globalization.getNumberPattern);
            forkJoin([localeObservable, numberPatternObservable]).subscribe(
                ([locale, numberPattern]) => {
                    this.setLocale(locale);
                    this.setNumberPattern(numberPattern);
                },
                () => {
                    this.setDefaultLocale();
                    this.setDefaultNumberPattern();
                }
            );
        } else {
            this.setDefaultLocale();
            this.setDefaultNumberPattern();
        }
    }

    private setLocale(locale: { value: string }): void {
        this.locale = locale.value;
    }

    private setDefaultLocale(): void {
        this.locale = this.translateService.getDefaultLang();
    }

    private setNumberPattern(numberPattern: { decimal: string, grouping: string; }): void {
        this.decimalSeparator = numberPattern.decimal;
        this.groupSeparator = numberPattern.grouping;
    }

    private setDefaultNumberPattern(): void {
        this.decimalSeparator = getLocaleNumberSymbol(this.translateService.getDefaultLang(),
            NumberSymbol.CurrencyDecimal);
        this.groupSeparator = getLocaleNumberSymbol(this.translateService.getDefaultLang(),
            NumberSymbol.CurrencyGroup);
    }

    public getLocale(): string {
        return this.locale;
    }

    public getDecimalSeparator(): string {
        return this.decimalSeparator;
    }

    public getGroupSeparator(): string {
        return this.groupSeparator;
    }
}
