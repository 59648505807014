import {Component, Input, OnInit} from '@angular/core';
import * as moment from 'moment';
import {Moment} from 'moment';
import {ModalController} from '@ionic/angular';
import {FirebaseService} from '../../../../services/firebase/firebase.service';
import {DateTimeUtilService} from '../../../../services/date-time-util/date-time-util.service';
import {isDefined} from '../../../../commons/utils';
import {SettlementOverviewFilter} from '../../../../domain/filter/settlement-overview-filter';

@Component({
    selector: 'app-settlement-overview-filter',
    templateUrl: './settlement-overview-filter.component.html',
    styleUrls: ['./settlement-overview-filter.component.scss'],
})
export class SettlementOverviewFilterComponent implements OnInit {

    private readonly FILTER_DAYS = 30;

    readonly TODAY = moment();

    selectedSettlementDateFrom: Moment;
    selectedSettlementDateTo: Moment;

    @Input() settlementOverviewFilter: SettlementOverviewFilter;

    constructor(private readonly modalController: ModalController,
                private readonly firebaseService: FirebaseService) {
    }

    ngOnInit(): void {
        this.setSelectedFilters();
    }

    onSettlementDateFromFilterChange(dateFrom: Moment): void {
        this.selectedSettlementDateFrom = dateFrom;
        this.settlementOverviewFilter.hedgeDateFrom = DateTimeUtilService.formatToIsoDate(dateFrom);
    }

    onSettlementDateToFilterChange(dateTo: Moment): void {
        this.selectedSettlementDateTo = dateTo;
        this.settlementOverviewFilter.hedgeDateTo = DateTimeUtilService.formatToIsoDate(dateTo);
    }

    private setSelectedFilters(): void {
        this.selectedSettlementDateFrom = isDefined(this.settlementOverviewFilter.hedgeDateFrom)
            ? moment.utc(this.settlementOverviewFilter.hedgeDateFrom)
            : null;
        this.selectedSettlementDateTo = isDefined(this.settlementOverviewFilter.hedgeDateTo)
            ? moment.utc(this.settlementOverviewFilter.hedgeDateTo)
            : null;
    }

    closeModal(): void {
        this.modalController.dismiss().then();
    }

    dismissModal(): void {
        this.firebaseService.logCustomEvent('settlement_overview_apply_filters');
        this.modalController.dismiss({
            'settlementOverviewFilter': this.settlementOverviewFilter,
        }).then();
    }

    clearAll(): void {
        this.settlementOverviewFilter = new SettlementOverviewFilter();
        this.settlementOverviewFilter.hedgeDateFrom = DateTimeUtilService.formatToIsoDate(moment().toString());
        const futureDate = new Date();
        futureDate.setDate(futureDate.getDate() + this.FILTER_DAYS);
        this.settlementOverviewFilter.hedgeDateTo = DateTimeUtilService.formatToIsoDate(futureDate);
        this.setSelectedFilters();
    }
}
