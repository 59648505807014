<div [class]="event.messageStatus === MessageStatus.UNREAD ? 'bullet' : 'bullet-free'">
    <div class="container-wrapper">
        <label>{{elapsedTimeLabel}}</label>
        <div class="body"
             [ngSwitch]="event.eventMessageType">
            <ng-container *ngSwitchCase="EventMessageType.RATE_NOTIFICATION">
                {{'notifications-page.notification.rate-notification.' + event.data.notificationDirection | translate:
                {
                    tradingCurrency: event.data.currencyPair.tradingCurrency,
                    contraCurrency: event.data.currencyPair.contraCurrency,
                    rateLimitThreshold: event.data.rateLimitThreshold | decimalAmount: DecimalAmountPipe.AMOUNT_FORMAT_4_DIGITS
                } }}
            </ng-container>
            <ng-container *ngSwitchCase="EventMessageType.UPCOMING_SETTLEMENT">
                <span>{{'notifications-page.notification.upcoming-settlement' | translate:
                    {
                        currency: event.data[0].tradingCurrency,
                        amount: event.data[0].hedgeAmount | decimalAmount,
                        side: event.data[0].side | lowercase,
                        settlementDate: event.data[0].hedgeDate | dateFormat
                    } }}
                </span>
                <span *ngIf="event.data.length > 1">{{'notifications-page.notification.more-items' | translate:
                    {
                        count: event.data.length - 1
                    } }}
                </span>
            </ng-container>
            <ng-container *ngSwitchCase="EventMessageType.UPCOMING_TRADE_PLAN">
                <span>{{'notifications-page.notification.upcoming-trade-plan' | translate:
                    {
                        eventName: event.data.eventName,
                        projectName: event.data.projectName,
                        companyShortName: event.data.companyCode | companyShortName
                    } }}
                </span>
                <p *ngIf="event.data.allEventIds.length > 1" class="more-items">
                    {{'notifications-page.notification.more-items'| translate:
                    {count: event.data.allEventIds.length - 1}
                    }}
                </p>
                <div class="open-in-trading">
                    {{TRADE_PLAN_RATE_NOTIFICATION_TRANSLATE_PREFIX + 'open-in-trading' | translate}}
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="EventMessageType.TRADE_PLAN_RATE_NOTIFICATION">
                <span>
                    {{
                        TRADE_PLAN_RATE_NOTIFICATION_TRANSLATE_PREFIX + tradePlanRateNotification.status | translate: {
                            symbol: tradePlanRateNotification.symbol,
                            threshold: tradePlanRateNotification.threshold | decimalAmount: DecimalAmountPipe.AMOUNT_FORMAT_4_DIGITS
                        }
                    }}
                </span>
                <div>
                    {{
                        TRADE_PLAN_RATE_NOTIFICATION_TRANSLATE_PREFIX + 'rate-alert-row-2' | translate: {
                            projectName: tradePlanRateNotification.projectName,
                            eventName: tradePlanRateNotification.eventName
                        }
                    }}
                </div>
                <div>
                    {{
                        TRADE_PLAN_RATE_NOTIFICATION_TRANSLATE_PREFIX + tradePlanRateNotification.hedgeSide | translate: {
                            amount: tradePlanRateNotification.amount,
                            tradingCurrency: tradePlanRateNotification.tradingCurrency,
                        }
                    }}
                     <span *ngIf="isDefined(tradePlanRateNotification.dueDate)">
                        {{tradePlanRateNotification.dueDate | dateFormat}}
                    </span>
                    <span *ngIf="isDefined(tradePlanRateNotification.tenor)">
                        {{'tenor.' + tradePlanRateNotification.tenor | translate}}
                    </span>
                </div>
                <p *ngIf="projectEventsCount > 1" class="more-items">
                    {{'notifications-page.notification.more-items' | translate: {count: projectEventsCount - 1} }}
                </p>
                <div class="open-in-trading">
                    {{TRADE_PLAN_RATE_NOTIFICATION_TRANSLATE_PREFIX + 'open-in-trading' | translate}}
                </div>
            </ng-container>
            <ng-container *ngSwitchDefault></ng-container>
        </div>
    </div>
</div>
