import {Component, Input, OnInit} from '@angular/core';
import {NavController} from '@ionic/angular';
import {AppPaths} from '../../app-paths';
import {Observable} from 'rxjs';
import {CompanyInitialsService} from '../../../services/company-initials/company-initials.service';

@Component({
    selector: 'app-change-company',
    templateUrl: './change-company.component.html',
    styleUrls: ['./change-company.component.scss']
})
export class ChangeCompanyComponent implements OnInit {

    @Input() disabled: boolean;

    companyInitials$: Observable<string>;

    constructor(private readonly companyInitialsService: CompanyInitialsService,
                private readonly navCtrl: NavController) {
    }

    ngOnInit(): void {
        this.companyInitials$ = this.companyInitialsService.companyInitialsSubject;
    }

    goToChangeCompany(): void {
        this.navCtrl.navigateForward(AppPaths.COMPANIES_PAGE).then();
    }
}
