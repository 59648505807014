import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {HedgingProfile} from '../../domain/hedging-profile';
import {HttpUtils} from '../../commons/http/http-utils';
import {tap} from 'rxjs/operators';
import {CompanyService} from '../company/company.service';
import {FirebaseService} from '../firebase/firebase.service';

@Injectable({
    providedIn: 'root'
})
export class HedgingProfileService {

    private cachedProfiles = {};

    constructor(public http: HttpClient) {
    }

    public getHedgingProfile(): Observable<HedgingProfile> {
        const companyCode = CompanyService.getCurrentCompany().code;
        if (this.cachedProfiles[companyCode]) {
            return of(this.cachedProfiles[companyCode]);
        } else {
            const url = `${FirebaseService.getApiBasePath()}/api/global/hedging-profiles/current`;
            return this.http.get<HedgingProfile>(url, HttpUtils.getRequestOptions())
                .pipe(tap(profile => this.cachedProfiles[companyCode] = profile));
        }
    }

    public clearCache(): void {
        this.cachedProfiles = {};
    }
}
