import {Pipe, PipeTransform} from '@angular/core';
import {DecimalAmountPipe} from '../decimal-input/decimal-input.pipe';

@Pipe({
    name: 'baseRate'
})
export abstract class BaseRatePipe implements PipeTransform {

    constructor(private decimalAmountPipe: DecimalAmountPipe) {
    }

    transform(value: number,
              decimals: string = this.getDigits()): string {
        return this.decimalAmountPipe.transform(value, decimals);
    }

    getDigits(): string {
        return '1.4-4';
    }
}
