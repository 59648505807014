import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {Tenors} from '../../../domain/tenors';
import {ActiveTenorService} from '../../../services/active-tenor/active-tenor.service';

@Component({
    selector: 'app-tenors-bar',
    templateUrl: './tenors-bar.component.html',
    styleUrls: ['./tenors-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TenorsBarComponent implements OnInit {

    activeTenor: Tenors;
    tenorList: Tenors[];

    constructor(private activeTenorService: ActiveTenorService) {
        this.tenorList = [];
        Object.keys(Tenors).forEach(key => this.tenorList.push(Tenors[key]));
    }

    changeTenor(tenor: Tenors): void {
        this.activeTenor = tenor;
        this.activeTenorService.changeMessage(tenor);
    }

    ngOnInit(): void {
        this.activeTenorService.onTenorChange().subscribe(message => this.activeTenor = message);
    }
}
