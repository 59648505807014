import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy, Platform} from '@ionic/angular';
import {StatusBar} from '@awesome-cordova-plugins/status-bar/ngx';

import {AppComponent} from './app.component';
import {FormsModule} from '@angular/forms';
import {
    LOCAL_STORAGE_TOKEN,
    localStorageFactory,
    LocalStorageService
} from './services/local-storage/local-storage.service';
import {SecureStorage} from '@awesome-cordova-plugins/secure-storage/ngx';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {HTTP} from '@awesome-cordova-plugins/http/ngx';
import {NgIdleModule} from '@ng-idle/core';
import {ErrorHandlerInterceptor} from './commons/http/interceptors/error-handler.interceptor';
import {AuthExpiredInterceptor} from './commons/http/interceptors/auth-expired.interceptor';
import {AuthInterceptor} from './commons/http/interceptors/auth.interceptor';
import {SslPinningRequestInterceptor} from './commons/http/interceptors/ssl-pinning-request-interceptor.service';
import {DeviceInterceptor} from './commons/http/interceptors/device.interceptor';
import {AppRoutingModule} from './app-routing.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {PipesModule} from './pipes/pipes.module';
import {Device} from '@awesome-cordova-plugins/device/ngx';
import {FirebaseX} from '@awesome-cordova-plugins/firebase-x/ngx';
import {ChartDataPipe} from './pipes/chart-date/chart-date';
import {CurrenciesService} from './services/currencies/currencies.service';
import {HedgingProfileService} from './services/hedging-profile/hedging-profile.service';
import {CompanyService} from './services/company/company.service';
import {CacheService} from './services/cache/cache.service';
import {SharedDataService} from './services/shared-data/shared-data.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppVersionInterceptor} from './commons/http/interceptors/app-version-interceptor';
import {GeneralInfoPageModule} from './pages/general-info/general-info.module';
import {InternetConnectionInterceptor} from './commons/http/interceptors/internet-connection.interceptor';
import {InAppBrowser} from '@awesome-cordova-plugins/in-app-browser/ngx';
import {RequestLoadingSpinnerInterceptor} from './commons/http/interceptors/request-loading-spinner.interceptor';
import {MobileAccessibility} from '@awesome-cordova-plugins/mobile-accessibility/ngx';
import {CallNumber} from '@awesome-cordova-plugins/call-number/ngx';
import {EmailComposer} from '@awesome-cordova-plugins/email-composer/ngx';
import {Clipboard} from '@awesome-cordova-plugins/clipboard/ngx';
import {Camera} from '@awesome-cordova-plugins/camera/ngx';
import {registerLocaleData} from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import localeRo from '@angular/common/locales/ro';
import localeFi from '@angular/common/locales/fi';
import localeBe from '@angular/common/locales/be';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import {AuthModule} from './auth/auth.module';
import {AuthGuardService} from './auth/auth-guard.service';
import {LoggerModule} from 'ngx-logger';
import {environment} from '../environments/environment';
import {JwtHelperService} from '@auth0/angular-jwt';
import {FirebaseService} from './services/firebase/firebase.service';
import {Supplier} from './commons/functional-types';
import {AuthorizationService} from './auth/authorization.service';
import {MatDialogModule} from '@angular/material/dialog';
import {ClientContextInterceptor} from './commons/http/interceptors/client-context.interceptor';
import {IonicStorageModule} from '@ionic/storage-angular';
import {FingerprintAIO} from '@awesome-cordova-plugins/fingerprint-aio/ngx';
import {GlobalTenantInterceptor} from './commons/http/interceptors/global-tenant.interceptor';
import {RxStompService} from './services/rx-stomp/rx-stomp.service';
import {HighchartsCustomizations} from './commons/highcharts-customizations';
import {BarcodeScanner} from '@awesome-cordova-plugins/barcode-scanner/ngx';
import {SwiperModule} from 'swiper/angular';
import {LetModule} from '@ngrx/component';
import {SocialSharing} from '@awesome-cordova-plugins/social-sharing/ngx';
import {File} from '@awesome-cordova-plugins/file/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';

registerLocaleData(localeNl);
registerLocaleData(localeRo);
registerLocaleData(localeFi);
registerLocaleData(localeBe);
registerLocaleData(localeDe);
registerLocaleData(localeEn);

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        FormsModule,
        PipesModule,
        HttpClientModule,
        MatDialogModule,
        GeneralInfoPageModule,
        NgIdleModule.forRoot(),
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        IonicStorageModule.forRoot(),
        AuthModule,
        LoggerModule.forRoot({
            level: environment.logLevel
        }),
        SwiperModule,
        LetModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: environment.production,
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        })
    ],
    providers: [
        StatusBar,
        InAppBrowser,
        {
            provide: APP_INITIALIZER,
            useFactory: firebaseConfigFactory,
            deps: [Platform, FirebaseService, AuthorizationService, LocalStorageService],
            multi: true
        },
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {provide: LOCAL_STORAGE_TOKEN, useFactory: localStorageFactory},
        {provide: HTTP_INTERCEPTORS, useClass: GlobalTenantInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: AuthExpiredInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: RequestLoadingSpinnerInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: InternetConnectionInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: DeviceInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: AppVersionInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ClientContextInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: SslPinningRequestInterceptor, multi: true},
        LocalStorageService,
        SecureStorage,
        HTTP,
        Device,
        FirebaseX,
        ChartDataPipe,
        CurrenciesService,
        HedgingProfileService,
        CompanyService,
        CacheService,
        SharedDataService,
        RxStompService,
        MobileAccessibility,
        CallNumber,
        EmailComposer,
        Clipboard,
        Camera,
        AuthGuardService,
        JwtHelperService,
        FingerprintAIO,
        BarcodeScanner,
        File,
        SocialSharing
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

export function firebaseConfigFactory(platform: Platform,
                                      firebase: FirebaseService,
                                      authorizationService: AuthorizationService,
                                      localStorage: LocalStorageService): Supplier<Promise<boolean>> {
    HighchartsCustomizations.init();
    return () => new Promise<boolean>(resolve => {
        platform.ready()
            .then(() => firebase.setRemoteConfig()
                .then(() => authorizationService.startUpAsync()
                    .then(() => localStorage.initStorage()
                        .then(() => resolve(true)))));
    });
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http, './../assets/i18n/', '.json');
}
