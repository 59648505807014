<ion-row class="margin-top">
    <ion-col *ngFor="let tenor of availableTenors; let i = index"
             [class.center-col]="availableTenors.length === 1"
             [class.center-col-left]="availableTenors.length === 2 && i === 0"
             [class.center-col-right]="availableTenors.length === 2 && i === 1"
             size="4"
             class="ion-text-center">
        <app-tenor-selector [tenor]="tenor"
                            [activeTenor]="activeTenor"
                            (selectTenor)="onTenorSelect($event)">
        </app-tenor-selector>
    </ion-col>
</ion-row>
