<div (click)="toggleCheckbox()">
    <ion-row>
        <ion-col size="1">
            <div *ngIf="!checked" class="checkbox-unchecked">
            </div>
            <div *ngIf="checked">
                <ion-icon src="assets/icon/icon-check.svg" class="checkbox-unchecked"></ion-icon>
            </div>
        </ion-col>
        <ion-col size="11" [class.disabled]="disabled">
            <span *ngIf="isNullOrUndefined(translateString)">{{label}}</span>
            <span *ngIf="isDefined(translateString)">
              {{translateString + label | translate}}
            </span>
        </ion-col>
    </ion-row>
</div>
