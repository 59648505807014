import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {isNullOrUndefined, isDefined} from '../../../../../commons/utils';

@Component({
    selector: 'app-expandable-radio-button-filter',
    templateUrl: './expandable-radio-button-filter.component.html',
    styleUrls: ['./expandable-radio-button-filter.component.css'],
})
export class ExpandableRadioButtonFilterComponent implements OnInit, OnChanges {

    readonly isNullOrUndefined = isNullOrUndefined;
    readonly isDefined = isDefined;

    isExpanded = false;
    isFilterActive: boolean;

    @Input() filterNameKey: string;
    @Input() filterItems: string[];
    @Input() selectedFilterItem: string;
    @Input() translateString: string;
    @Input() canDisable = true;
    @Output() changeFilters = new EventEmitter<string>();

    constructor() {
    }

    ngOnInit(): void {
        this.isFilterActive = isDefined(this.selectedFilterItem);
    }

    onFilterSelect(checked: boolean, filterItem: string): void {
        if (checked) {
            this.selectedFilterItem = filterItem;
            this.changeFilters.emit(this.selectedFilterItem);
            this.isFilterActive = true;
        } else if (this.canDisable) {
            this.isFilterActive = false;
            this.changeFilters.emit();
        }
    }

    clearFilter(event: MouseEvent): void {
        event.stopPropagation();
        this.isFilterActive = false;
        this.changeFilters.emit();
    }

    toggleExpand(): void {
        this.isExpanded = !this.isExpanded;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.isFilterActive = isDefined(this.selectedFilterItem);
    }
}
