<ion-row *ngIf="vm$ | async as vm">
    <ion-col [size]="showLanguageSelector ? 7 : 8">
        <p *ngIf="mainPage" class="main-page-title">{{pageTitle}}</p>
        <ng-container *ngIf="noInternetPage">
            <ng-container *ngIf="onLine$ | async as onLine">
                <div *ngIf="onLine && !mainPage"
                     class="back-button-header">
                    <ion-icon class="back-button"
                              name="chevron-back"
                              (click)="emitGoBackEvent()"/>
                    <span class="back-label" (click)="emitGoBackEvent()">{{pageTitle}}</span>
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="!noInternetPage">
            <div *ngIf="!mainPage"
                 class="back-button-header">
                <ion-icon class="back-button"
                          name="chevron-back"
                          (click)="emitGoBackEvent()"/>
                <span class="back-label" (click)="emitGoBackEvent()">{{pageTitle}}</span>
            </div>
        </ng-container>
    </ion-col>
    <ion-col *ngIf="displayInsightsIcon"
             size="1"
             class="icons">
        <ion-icon class="icon-insights"
                  src="assets/icon/icon-insights.svg"
                  (click)="openInsightsModal()"/>
    </ion-col>
    <ion-col size="1" class="icons">
        <app-notification-count *ngIf="authenticatedContext"/>
    </ion-col>
    <ion-col size="2" class="icons">
        <app-change-company *ngIf="authenticatedContext"
                            [disabled]="companySelectorDisabled"/>
    </ion-col>
    <ion-col *ngIf="showLanguageSelector"
             id="language-trigger"
             size="2"
             class="icons"
             (click)="isLanguageListPopoverOpen$.next(true)">
        <span class="language-selector">{{vm.currentLanguage | uppercase}}</span>
        <ion-icon name="globe-outline" class="icon-language"/>
    </ion-col>
    <ion-popover *ngIf="showLanguageSelector" trigger="language-trigger"
                 [isOpen]="vm.isLanguageListPopoverOpen"
                 (ionPopoverDidDismiss)="isLanguageListPopoverOpen$.next(false)">
        <ng-template>
            <ion-content>
                <app-language-list [availableLanguages]="vm.availableLanguages"
                                   [currentLanguage]="vm.currentLanguage"
                                   (changeCurrentLanguage)="changeCurrentLanguage($event)"/>
            </ion-content>
        </ng-template>
    </ion-popover>
</ion-row>
