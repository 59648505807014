import {Injectable} from '@angular/core';
import * as uuid from 'uuid';

@Injectable({
    providedIn: 'root'
})
export class ClientContextService {

    private static readonly clientContextId: string = uuid.v4();

    getClientContextId(): string {
        return ClientContextService.clientContextId;
    }
}
