import {Injectable} from '@angular/core';
import {LoadingController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {hasValues, isDefined, isNullOrUndefined} from '../../commons/utils';
import {Dictionary} from '../../commons/dictionary';
import {interval, noop, Subscription} from 'rxjs';
import {ToastService} from '../toast/toast.service';

@Injectable({
    providedIn: 'root'
})
export class LoadingSpinnerService {

    /**
     * Loading dialog is presented only when there is an active request running
     * for more than <code>REQUEST_RUNNING_TIME_TO_PRESENT</code>.
     */
    private readonly REQUEST_RUNNING_TIME_TO_PRESENT = 2500;
    private readonly REQUEST_TIMEOUT = 60000;

    private loadingElement: HTMLIonLoadingElement;
    private initiatingDialog = false;
    private schedulerSubscription: Subscription;
    private activeRequests: Dictionary<number> = {};

    constructor(private loadingController: LoadingController,
                private translateService: TranslateService,
                private toastService: ToastService) {
        this.startScheduler();
    }

    show(requestId: string): void {
        this.activeRequests[requestId] = Date.now();
    }

    hide(requestId: string): void {
        this.activeRequests[requestId] ? delete this.activeRequests[requestId] : noop();
    }

    hideAll(): void {
        this.activeRequests = {};
    }

    startScheduler(): void {
        if (isNullOrUndefined(this.schedulerSubscription)) {
            this.schedulerSubscription = interval(250).subscribe(() => {
                const now = Date.now();
                const hasLongRunningRequests = hasValues(
                    Object.values(this.activeRequests)
                        .filter(startTime => startTime + this.REQUEST_RUNNING_TIME_TO_PRESENT < now)
                );

                if (hasLongRunningRequests && isNullOrUndefined(this.loadingElement)
                    && this.initiatingDialog === false) {
                    this.initiatingDialog = true;
                    const loadingDialogOptions = {
                        message: this.translateService.instant('loading.message')
                    };
                    this.loadingController.create(loadingDialogOptions)
                        .then(loadingElement => {
                            this.loadingElement = loadingElement;
                            this.loadingElement.present();
                            this.initiatingDialog = false;
                        })
                        .catch(() => this.initiatingDialog = false);
                }
                if (!hasLongRunningRequests && isDefined(this.loadingElement)) {
                    this.loadingElement.dismiss().then(() => this.loadingElement = null);
                }

                this.removeTimeoutRequests();
            });
        }
    }

    showLoadingDialogForKeycloak(): void {
        const loadingDialogOptions = {
            message: this.translateService.instant('loading.message')
        };
        this.loadingController.create(loadingDialogOptions)
            .then(loadingElement => {
                this.loadingElement = loadingElement;
                this.loadingElement.present();
            });
    }

    hideLoadingDialogForKeycloak(): void {
        if (isDefined(this.loadingElement)) {
            this.loadingElement.dismiss().then(() => this.loadingElement = null);
        }
    }

    private removeTimeoutRequests(): void {
        const now = Date.now();
        const timeoutRequestIds = Object.keys(this.activeRequests)
            .filter(requestId => this.activeRequests[requestId] + this.REQUEST_TIMEOUT < now);
        if (hasValues(timeoutRequestIds)) {
            timeoutRequestIds.forEach(requestId => delete this.activeRequests[requestId]);
            this.toastService.error(this.translateService.instant('error-message.requests-timeout'));
        }
    }

    stopScheduler(): void {
        if (isDefined(this.schedulerSubscription)) {
            this.schedulerSubscription.unsubscribe();
            this.schedulerSubscription = null;
        }
    }
}
