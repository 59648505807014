import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BehaviorSubject, combineLatest, interval, Observable, Subscription} from 'rxjs';
import {ModalController} from '@ionic/angular';
import {ModalOptions} from '@ionic/core';
import {map} from 'rxjs/operators';
import {InsightsModalComponent} from '../../../pages/insights/insights-modal/insights-modal.component';
import {FirebaseService} from '../../../services/firebase/firebase.service';
import {TranslateService} from '@ngx-translate/core';
import {AppLanguageService} from '../../../services/app-language/app-language.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit, OnDestroy {

    private isModalVisible = false;
    private modalObject: HTMLIonModalElement;
    private subscription = new Subscription();

    private currentLanguage$ = new BehaviorSubject<string>(this.translateService.currentLang);
    private availableLanguages$ = this.appLanguageService.getAppLanguages();

    onLine$: Observable<boolean>;
    isLanguageListPopoverOpen$ = new BehaviorSubject<boolean>(false);

    vm$ = combineLatest([
        this.isLanguageListPopoverOpen$,
        this.currentLanguage$,
        this.availableLanguages$
    ]).pipe(
        map(([isLanguageListPopoverOpen, currentLanguage, availableLanguages]) => ({
            isLanguageListPopoverOpen,
            currentLanguage,
            availableLanguages
        }))
    );

    @Input() authenticatedContext = true;
    @Input() companySelectorDisabled = true;
    @Input() displayInsightsIcon = true;
    @Input() mainPage: boolean;
    @Input() noInternetPage = false;
    @Input() pageTitle: string;
    @Input() parentComponent = '';
    @Input() showLanguageSelector = false;
    @Output() goBack: EventEmitter<void> = new EventEmitter<void>();

    constructor(private readonly modalController: ModalController,
                private readonly firebaseService: FirebaseService,
                private readonly translateService: TranslateService,
                private readonly appLanguageService: AppLanguageService) {
    }

    emitGoBackEvent(): void {
        this.goBack.emit();
    }

    ngOnInit(): void {
        this.onLine$ = interval(500)
            .pipe(map(() => window.navigator.onLine));
    }

    async openInsightsModal(): Promise<void> {
        const insightsModalOptions: ModalOptions = {
            component: InsightsModalComponent
        };
        if (!this.isModalVisible) {
            this.firebaseService.logCustomEvent('open_insights_modal');
            this.isModalVisible = true;
            this.modalObject = await this.modalController.create(insightsModalOptions);
            await this.modalObject.present();
            this.modalObject.onDidDismiss().then(() => this.isModalVisible = false);
        }
    }

    changeCurrentLanguage(targetLanguage: string): void {
        if (targetLanguage !== this.currentLanguage$.value) {
            this.subscription.add(this.translateService.use(targetLanguage).subscribe());
            this.currentLanguage$.next(targetLanguage);
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
