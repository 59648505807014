import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpUtils} from '../http-utils';

@Injectable()
export class InternetConnectionInterceptor implements HttpInterceptor {

    constructor() {
    }

    private static createNoInternetConnectionError(request: HttpRequest<any>): HttpErrorResponse {
        return new HttpErrorResponse({
            error: 'Error processing request. No internet connection.',
            status: 419,
            statusText: 'Error processing request. No internet connection.',
            url: request.url
        });
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const isResourceRequest = HttpUtils.isLocalResourceRequest(req);
        if (!window.navigator.onLine && !isResourceRequest) {
            return throwError(InternetConnectionInterceptor.createNoInternetConnectionError(req));
        } else {
            return next.handle(req);
        }
    }
}
