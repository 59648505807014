import {Injectable, Injector} from '@angular/core';
import {Company} from '../../domain/company';
import {noop, Observable, of, Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map, tap} from 'rxjs/operators';
import {HttpUtils} from '../../commons/http/http-utils';
import {CompanyHierarchy} from '../../domain/company-hierarchy';
import {FirebaseService} from '../firebase/firebase.service';
import {isDefined, isNullOrUndefined} from '../../commons/utils';
import {ModalService} from '../modal/modal.service';
import {ModalConfigs} from '../../commons/modal-configs';

@Injectable({
    providedIn: 'root'
})
export class CompanyService {

    private static currentCompany: Company;
    private static cachedRootCompany: Company;
    private static cachedAllCompanies: Company[];

    private companySubject = new Subject<Company>();
    // Cannot inject GeneralInfoService here due to the injection of ModalController which creates a circular dependency
    private modalService: ModalService;

    static parseCompanyTree(companyList: Company[], company: CompanyHierarchy): Company[] {
        if (isDefined(company.data)) {
            companyList.push(company.data);
        }

        for (const children of company.children) {
            this.parseCompanyTree(companyList, children);
        }

        return companyList;
    }

    constructor(private http: HttpClient,
                private injector: Injector) {
    }

    public static getCurrentCompany(): Company {
        return this.currentCompany;
    }

    public static getCachedRootCompany(): Company {
        return this.cachedRootCompany;
    }

    public static getCachedCompanies(): Company[] {
        return this.cachedAllCompanies;
    }

    getRootCompany(companyCode: string): Observable<Company> {
        if (CompanyService.cachedRootCompany) {
            return of(CompanyService.cachedRootCompany);
        } else {
            return this.loadByCode(companyCode).pipe(
                tap(company => this.loadRootCompany(company))
            );
        }
    }

    loadByCode(companyCode: string): Observable<Company> {
        const url = `${FirebaseService.getApiBasePath()}/api/global/companies/${companyCode}`;
        return this.http.get<Company>(url, HttpUtils.getRequestOptions());
    }

    private loadRootCompany(rootCompany: Company): void {
        CompanyService.cachedRootCompany = rootCompany;
        this.changeCompany(rootCompany);
    }

    getCompanies(): Observable<Company[]> {
        if (CompanyService.cachedAllCompanies) {
            return of(CompanyService.cachedAllCompanies);
        } else {
            const url = `${FirebaseService.getApiBasePath()}/api/global/companies/hierarchy`;
            const httpOptions = HttpUtils.getRequestOptions();
            return this.http.get<CompanyHierarchy>(url, httpOptions).pipe(
                map(result => this.processCompanyHierarchy(result)),
                tap(processedList => CompanyService.cachedAllCompanies = processedList)
            );
        }
    }

    getCompanyVersions(companyCode: string): Observable<Company[]> {
        const url = `${FirebaseService.getApiBasePath()}/api/global/companies/${companyCode}/versions`;
        return this.http.get<Company[]>(url, HttpUtils.getRequestOptions());
    }

    getCompanyHierarchyOverview(companyCode: string): Observable<CompanyHierarchy> {
        const url = `${FirebaseService.getApiBasePath()}/api/global/companies/hierarchy/overview/${companyCode}`;
        return this.http.get<CompanyHierarchy>(url, HttpUtils.getDefaultRequestOptions());
    }

    private processCompanyHierarchy(companyHierarchy: CompanyHierarchy): Company[] {
        let result = [];
        if (isDefined(companyHierarchy.data)) {
            result.push(companyHierarchy.data);
        }

        companyHierarchy.children.forEach(hierarchyItem => {
            result = result.concat(this.processCompanyHierarchy(hierarchyItem));
        });

        return result;
    }

    hasChildCompanies(): boolean {
        return CompanyService.currentCompany && CompanyService.cachedAllCompanies.length > 1;
    }

    changeCompany(company: Company): void {
        CompanyService.currentCompany = company;
        this.companySubject.next(company);
    }

    onCompanyChange(): Observable<Company> {
        return this.companySubject.asObservable();
    }

    clearCache(): void {
        CompanyService.cachedRootCompany = null;
        CompanyService.cachedAllCompanies = null;
        CompanyService.currentCompany = null;
    }

    checkTermsAndConditions(): void {
        this.injectModalService();
        this.loadByCode(CompanyService.currentCompany.code).subscribe(company =>
            company.termsOfUseRequested
                ? this.modalService.showModal(ModalConfigs.termsAndConditionsModal).then()
                : noop()
        );
    }

    private injectModalService(): void {
        isNullOrUndefined(this.modalService)
            ? this.modalService = this.injector.get(ModalService)
            : noop();
    }
}
