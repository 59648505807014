import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuardService} from './auth/auth-guard.service';
import {DefaultComponent} from './modules/authorization/default/default.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/default',
        pathMatch: 'full'
    },
    {
        path: 'default',
        component: DefaultComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: '',
        loadChildren: () => import('./modules/authorization/authorization.module').then(m => m.AuthorizationModule),
    },
    {
        path: 'general-info',
        loadChildren: () => import('./pages/general-info/general-info.module').then(m => m.GeneralInfoPageModule)
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
