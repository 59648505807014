import {ManualTrade} from '../../domain/manual-trade';
import {Pipe, PipeTransform} from '@angular/core';
import {isDefined} from '../../commons/utils';
import {RequestOrder} from '../../domain/request-order';
import {ManualTradeReport} from '../../domain/manual-trade-report';

@Pipe({
    name: 'reference'
})
export class ReferencePipe implements PipeTransform {

    transform(referenceSource: ManualTrade | RequestOrder | ManualTradeReport): string {
        let reference;
        if (isDefined((<ManualTrade | ManualTradeReport>referenceSource).referenceId.value)) {
            const ref = referenceSource as ManualTrade;
            reference = ref.referenceId.value;
            if (isDefined(ref.referenceSubId.value)) {
                reference += '_' + ref.referenceSubId.value;
            }
        } else if (isDefined((<RequestOrder>referenceSource).referenceId)) {
            const ref = referenceSource as RequestOrder;
            reference = ref.referenceId;
            if (isDefined(ref.referenceSubId)) {
                reference += '_' + ref.referenceSubId;
            }
        }
        return reference;
    }
}
