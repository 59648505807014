import {filter, take} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {AuthorizationService} from './authorization.service';
import {AppConstants} from '../commons/app-constants';
import {LocalStorageService} from '../services/local-storage/local-storage.service';
import {NavController} from '@ionic/angular';
import {isDefined} from '../commons/utils';
import {DeviceStatus} from '../domain/device-status';
import {AppPaths} from '../commons/app-paths';
import {PrincipalService} from '../services/principal/principal.service';
import {UserDeviceService} from '../services/user-device/user-device.service';
import {DeviceService} from '../services/device/device.service';
import {AppLanguageService} from '../services/app-language/app-language.service';
import {CacheService} from '../services/cache/cache.service';
import {SharedDataService} from '../services/shared-data/shared-data.service';
import {EtHttpService} from '../services/et-http/et-http.service';
import {ConfigurationService} from '../services/configuration/configuration.service';
import {ToastService} from '../services/toast/toast.service';
import {TranslateService} from '@ngx-translate/core';
import {TokenResponse} from '@openid/appauth';
import {PinCodeService} from '../services/pin-code/pin-code.service';
import {GlobalizationService} from '../services/globalization/globalization.service';
import {environment} from '../../environments/environment';
import {noop, Observable, Subscription} from 'rxjs';
import {AlertService} from '../services/alert/alert.service';
import {CompanyService} from '../services/company/company.service';
import {UserUtils} from '../utils/user-utils';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService {

    private subscription: Subscription;

    constructor(private authorizationService: AuthorizationService,
                private navCtrl: NavController,
                private localStorage: LocalStorageService,
                private principal: PrincipalService,
                private userDeviceService: UserDeviceService,
                private deviceService: DeviceService,
                private appLanguage: AppLanguageService,
                private cacheService: CacheService,
                private sharedDataService: SharedDataService,
                private http: EtHttpService,
                private configurationService: ConfigurationService,
                private toast: ToastService,
                private translate: TranslateService,
                private pinCodeService: PinCodeService,
                private globalizationService: GlobalizationService,
                private alertService: AlertService,
                private companyService: CompanyService) {
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        this.http.initialize().subscribe(() => {
            if (this.localStorage.isStorageAvailable()) {
                this.configurationService.getMobileAppLatestVersion().subscribe(version => {
                    version !== environment.apiVersion
                        ? this.alertService.presentAlertUpdateVersion()
                        : noop();
                });
                if (isDefined(this.localStorage.getItem(AppConstants.SECURED_TOKEN))) {
                    this.navCtrl.navigateRoot(AppPaths.LOGIN_PIN_CODE_PAGE);
                } else {
                    this.subscription = this.authorizationService.getAuthService().isAuthenticated$
                        .subscribe((authenticated) => authenticated
                            ? this.authorizationService.getAuthService().token$
                                .pipe(take(1))
                                .subscribe((token) =>
                                    token.accessToken ? this.checkAuthState(token) : noop())
                            : this.navCtrl.navigateRoot(AppPaths.LOGIN_INIT_PAGE)
                        );
                }
            } else {
                this.appLanguage.setTranslationLanguage().subscribe();
                const messageKey = 'landing.secure-storage-unavailable';
                this.toast.errorWithCancelButton(this.translate.instant(messageKey));
            }
            if (isDefined(this.subscription)) {
                this.subscription.unsubscribe();
            }
        });
        return this.authorizationService.getAuthService().isAuthenticated$;
    }

    public checkAuthState(tokenResponse: TokenResponse): void {
        this.sharedDataService.setSharedData<string>(AppConstants.ACCESS_TOKEN, tokenResponse.accessToken);
        this.authorizationService.clearAuthStorage();
        this.principal.identity()
            .pipe(
                filter(user => isDefined(user))
            )
            .subscribe(user => {
                this.appLanguage.setTranslationLanguage(user.langKey)
                    .subscribe(() => this.globalizationService.initGlobalization());
                this.cacheService.clearCache();
                const defaultTenant = UserUtils.getDefaultTenant(user);
                this.companyService.getRootCompany(defaultTenant).subscribe(() => {
                    this.authorizationService.applyPostLoginActions().subscribe(() => {
                        this.userDeviceService.getUserDevice(user.userId, this.deviceService.getDeviceId())
                            .subscribe(device => {
                                if (isDefined(device)) {
                                    if (device.status === DeviceStatus.INACTIVE) {
                                        this.navCtrl.navigateForward(AppPaths.DEVICE_REGISTRATION_PAGE);
                                    } else {
                                        this.pinCodeService.hasPin().subscribe(() => {
                                            this.navCtrl.navigateRoot(AppPaths.LOGIN_PIN_CODE_PAGE);
                                        }, () => {
                                            this.navCtrl.navigateForward(AppPaths.SET_PIN_CODE_PAGE);
                                        });
                                    }
                                } else {
                                    this.navCtrl.navigateForward(AppPaths.DEVICE_REGISTRATION_PAGE);
                                }
                                if (isDefined(this.subscription)) {
                                    this.subscription.unsubscribe();
                                }
                            });
                    });
                });
            });
    }
}
