<ion-content>
    <ion-row>
        <ion-col size="9" class="ion-padding-start">
            <p>{{'date-picker.select-a-date' | translate}}</p>
        </ion-col>
        <ion-col size="3" class="ion-padding-end">
            <ion-button class="main-btn small-btn btn-font"
                        size="small"
                        mode="ios"
                        (click)="closeModal()">
                {{'date-picker.close' | translate}}
            </ion-button>
        </ion-col>
    </ion-row>
    <ion-row>
        <ion-col>
            <mat-calendar [minDate]="minDueDate"
                          [maxDate]="maxDueDate"
                          [dateFilter]="filterInvalidDays"
                          [selected]="dueDate"
                          [startAt]="dueDate"
                          (selectedChange)="changeHedgeDate($event)">
            </mat-calendar>
        </ion-col>
    </ion-row>
    <ion-row>
        <ion-col class="ion-text-center ion-padding-start ion-padding-end">
            <ion-button [disabled]="disabledSetDate"
                        class="main-btn set-btn" size="small"
                        (click)="dismissModal()">
                {{'date-picker.set-date' | translate}}
            </ion-button>
        </ion-col>
    </ion-row>
</ion-content>
