import { Pipe, PipeTransform } from '@angular/core';
import {DecimalPipe} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'percentage'
})
export class PercentagePipe implements PipeTransform {

  private readonly minimumPercentage = 0.1;
  private static DEFAULT_PERCENTAGE_FORMAT = '1.1-1';
  private static PERCENTAGE_FORMAT = '1.1-';

  constructor(private decimalPipe: DecimalPipe,
              private translateService: TranslateService) {
  }

  transform(percentage: number): string {
    if (percentage < this.minimumPercentage) {
      let decimals = Math.abs(Math.floor(Math.log(percentage) / Math.log(10)));
      let format = PercentagePipe.PERCENTAGE_FORMAT.concat(decimals.toString());
      return this.decimalPipe.transform(percentage, format, this.translateService.getDefaultLang()).concat('%');
    }
    return this.decimalPipe
            .transform(percentage, PercentagePipe.DEFAULT_PERCENTAGE_FORMAT, this.translateService.getDefaultLang())
            .concat('%');
  }

}
