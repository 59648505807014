import {Injectable} from '@angular/core';
import {UserFeedbackService} from '../user-feedback/user-feedback.service';
import {UserFeedback, UserFeedbackConstants} from '../../domain/user-feedback';
import {AuthorizationService} from '../../auth/authorization.service';
import {PlatformEnum} from '../../domain/platform';
import {hasValues} from '../../commons/utils';
import {InAppMessageAction} from 'onesignal-cordova-plugin/dist/models/InAppMessage';
import OneSignal from 'onesignal-cordova-plugin';
import {AppLanguageService} from '../app-language/app-language.service';
import {Platform} from '@ionic/angular';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class OneSignalService {

    private static readonly SPLIT_LINE = '-';
    userFeedBacks = [];

    constructor(private readonly userFeedback: UserFeedbackService,
                private readonly authService: AuthorizationService,
                private readonly languageService: AppLanguageService,
                private readonly platform: Platform) {
        this.authService.onTokenUpdate().subscribe(() => {
            if (hasValues(this.userFeedBacks)) {
                this.userFeedBacks.forEach(feedback => this.userFeedback.insert(feedback).subscribe());
                this.userFeedBacks = [];
            }
        });
    }

    private static processFeedback(message: string): string {
        return message.split(OneSignalService.SPLIT_LINE)[1].trim();
    }

    private static processRating(message: string): string {
        return message.split(OneSignalService.SPLIT_LINE)[0].trim();
    }

    init(): void {
        const apiKey = this.platform.is('ios')
            ? environment.oneSignal.apiKeyIos
            : environment.oneSignal.apiKeyAndroid;
        OneSignal.setAppId(apiKey);
        OneSignal.setLanguage(this.languageService.getTranslationLanguage());
        OneSignal.setLocationShared(false);
        const that = this;
        const iamClickCallback = function (messageAction: InAppMessageAction): void {
            that.saveInAppMessageResponse(messageAction);
        };
        OneSignal.setInAppMessageClickHandler(iamClickCallback);
    }

    private saveInAppMessageResponse(messageAction: InAppMessageAction): void {
        const userFeedback = new UserFeedback();
        userFeedback.feedback = OneSignalService.processFeedback(messageAction.clickName);
        userFeedback.ratingValue = Number(OneSignalService.processRating(messageAction.clickName));
        userFeedback.metric = UserFeedbackConstants.CSAT;
        userFeedback.dataPlatform = PlatformEnum.MOBILE;
        userFeedback.campaignName = 'platform';
        this.userFeedBacks.push(userFeedback);
    }
}
