import {Directive, ElementRef, EventEmitter, HostListener, Output} from '@angular/core';
import {ElementState} from './element-state';

@Directive({
  selector: '[appOneClick]'
})
export class OneClickDirective {

  @Output('appOneClick') clicked = new EventEmitter<ElementState>();

  constructor(private elementRef: ElementRef) {
  }

  private setDisabledValue(value: boolean): void {
    this.elementRef.nativeElement.disabled = value;
  }

  @HostListener('click') onClick(): void {
    this.setDisabledValue(true);
    const elementState = new ElementState(value => this.setDisabledValue(value));
    this.clicked.emit(elementState);
  }

  enable(): void {
    this.setDisabledValue(false);
  }

  disable(): void {
    this.setDisabledValue(true);
  }

}
