import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'filterCurrencies',
})
export class FilterCurrenciesPipe implements PipeTransform {
    transform(currencies: string[], contraCurrency: string) {
        if (currencies) {
            return currencies.filter(currency => currency !== contraCurrency);
        }
    }
}
