import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {DateOverview} from '../../../../domain/date-overview';
import {DateOverviewPosition} from '../../../../domain/date-overview-position';
import {InsightsPeriod} from '../../../../domain/insights-period';
import {SettlementOverviewFilter} from '../../../../domain/filter/settlement-overview-filter';

@Component({
    selector: 'app-settlement-overview-details-list-item',
    templateUrl: './settlement-overview-details-list-item.component.html',
    styleUrls: ['./settlement-overview-details-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettlementOverviewDetailsListItemComponent implements OnInit {
    readonly InsightsPeriod = InsightsPeriod;
    dateOverviewPosition: DateOverviewPosition;
    startDate: Date;
    endDate: Date;

    @Input() activeTenor: InsightsPeriod;
    @Input() dateOverview: DateOverview;
    @Input() symbol: string;
    @Input() settlementOverviewFilter: SettlementOverviewFilter;

    constructor() {
    }

    ngOnInit(): void {
        this.dateOverviewPosition = this.dateOverview.dateOverviewPositions
            .find(dateOverviewPosition => dateOverviewPosition.currency === this.symbol);
        const filterStartDate = new Date(this.settlementOverviewFilter.hedgeDateFrom);
        this.startDate = new Date(this.dateOverview.hedgeDatePeriod.startDate);
        this.startDate = this.startDate < filterStartDate
            ? filterStartDate
            : this.dateOverview.hedgeDatePeriod.startDate;
        const filterEndDate = new Date(this.settlementOverviewFilter.hedgeDateTo);
        this.endDate = new Date(this.dateOverview.hedgeDatePeriod.endDate);

        if (this.endDate < filterEndDate) {
            this.endDate.setDate(this.endDate.getDate() - 1);
        } else {
            this.endDate = filterEndDate;
        }
    }

}
