import {GeneralInfoPage} from '../pages/general-info/general-info.page';
import {environment} from '../../environments/environment';

export class ModalConfigs {

    static readonly termsOfUseModal = {
        component: GeneralInfoPage,
        componentProps: {
            title: 'general-info.terms-of-use-title',
            body: 'general-info.terms-of-use-body',
            backLabel: 'components.back-button',
            backButton: true,
            dismiss: true
        }
    };

    static readonly termsAndConditionsModal = {
        component: GeneralInfoPage,
        componentProps: {
            title: 'terms-and-conditions.title',
            body: 'terms-and-conditions.message',
            backLabel: 'components.back-button',
            backButton: true,
            dismiss: false,
            termsAndConditionsModal: true
        }
    };

    static readonly deprecatedAppVersion = {
        component: GeneralInfoPage,
        componentProps: {
            title: 'general-info.new-app-version',
            body: 'error-message.new-app-version',
            backLabel: '',
            backButton: false,
            dismiss: true,
            storeLink: environment.platform.toLowerCase() === 'ios'
                ? environment.iosStoreLink
                : environment.androidStoreLink
        }
    };

    static readonly noInternetConnectionModal = {
        component: GeneralInfoPage,
        componentProps: {
            title: 'no-connection.title',
            body: 'no-connection.body',
            backLabel: 'components.back-button',
            backButton: true,
            dismiss: true,
            noInternetPage: true
        }
    };

    static readonly maintenanceModal = {
        component: GeneralInfoPage,
        componentProps: {
            title: 'opening-hours.maintenance-title',
            body: 'opening-hours.maintenance-body',
            appName: {appName: environment.appName},
            backLabel: 'components.back-button',
            backButton: true,
            dismiss: true
        }
    };
}
