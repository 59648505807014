export enum Side {
    BUY = 'BUY', SELL = 'SELL'
}

export namespace Side {

    export function getOppositeSide(side: Side): Side {
        return side === Side.BUY ? Side.SELL : Side.BUY;
    }
}
