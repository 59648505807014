export enum Timezones {
    LOCALE = 'locale',
    COMPANY = 'company',
    UTC = 'utc'
}

export enum DateFormats {
    DAY_FULL_MONTH_YEAR = 'd LLLL y',
    DAY_MONTH_INITIALS_YEAR = 'dd MMM yyyy',
    DAY_MONTH_YEAR_TIME = 'dd MMM yyyy, HH:mm',
    DAY_MONTH_TIME = 'dd MMM HH:mm',
    DAY_INITIALS_DAY_MONTH_YEAR_TIME = 'EEE, dd MMM yyyy, HH:mm',
    MONTH_DAY = 'MMM dd',
    DAY_OF_WEEK_MONTH_YEAR = 'EEE, dd MMM yy',
    HOUR_MINUTE = 'HH:mm',
    DAY_OF_WEEK_MONTH = 'EEE, d MMM',
    WEEK = 'w',
    MONTH = 'MMM',
    DAY = 'd',
    MONTH_YEAR = 'MMM, YYYY',
    NUMERIC_DATE = 'dd/MM/yyyy',
    NUMERIC_DATE_POINT = 'dd.MM.yyyy'
}
