import {Injectable} from '@angular/core';
import {
    BIOMETRIC_ERRORS,
    FingerprintAIO,
    FingerprintOptions,
    FingerprintSecretOptions
} from '@awesome-cordova-plugins/fingerprint-aio/ngx';
import {AlertService} from '../alert/alert.service';
import {LocalStorageService} from '../local-storage/local-storage.service';
import {hasStringValues, isDefined} from '../../commons/utils';
import {AppConstants} from '../../commons/app-constants';
import {BiometricsConfigService} from './biometrics-config.service';
import {BiometricType} from '../../domain/biometric-type';
import {TranslateService} from '@ngx-translate/core';
import {ConfigurationService} from '../configuration/configuration.service';

@Injectable({
    providedIn: 'root'
})
export class BiometricsService {

    private readonly biometricOptions: FingerprintOptions;
    private biometricSecretOptions: FingerprintSecretOptions;

    constructor(private readonly faio: FingerprintAIO,
                private readonly alertService: AlertService,
                private readonly localStorageService: LocalStorageService,
                private readonly biometricsConfig: BiometricsConfigService,
                private readonly translateService: TranslateService,
                private readonly configurationService: ConfigurationService) {
        this.biometricOptions = {disableBackup: true};
        this.biometricSecretOptions = {disableBackup: true, secret: ''};
    }

    isLoginWithBiometricsAvailable(): Promise<void> {
        return new Promise<void>(resolve => {
            this.configurationService.isBiometricEnabled()
                .subscribe(biometricsEnabled => {
                        if (biometricsEnabled) {
                            this.faio.isAvailable()
                                .then((result: any) => {
                                    this.biometricsConfig.setBiometricType(result === 'face'
                                        ? BiometricType.FACE
                                        : BiometricType.FINGER);
                                    resolve(void 0);
                                });
                        } else {
                            this.biometricsConfig.setBiometricAllowed(false);
                            this.biometricsConfig.setRegistered(false).then(() => resolve(void 0));
                        }
                    }
                );
        });
    }

    loginWithBiometrics(): Promise<string> {
        if (isDefined(this.biometricsConfig.getBiometricType())) {
            if (this.biometricsConfig.isRegistered()) {
                return this.faio.loadBiometricSecret(this.biometricOptions);
            } else {
                return new Promise<string>(resolve => {
                    const biometricType = this.biometricsConfig.getBiometricType() === BiometricType.FACE
                        ? this.translateService.instant('security.faceid')
                        : this.translateService.instant('security.fingerprint');
                    this.alertService.presentAlertBiometricRegisterMethod(biometricType)
                        .then(() => resolve(AppConstants.EMPTY_STRING));
                });
            }
        }
    }

    isBiometricRegistered(): void {
        const registered = isDefined(this.localStorageService
            .getItem<boolean>(AppConstants.REGISTERED_BIOMETRIC))
            ? this.localStorageService.getItem<boolean>(AppConstants.REGISTERED_BIOMETRIC)
            : false;
        this.biometricsConfig.setRegistered(registered).then();
    }

    registerBiometricSecret(secret: string): Promise<void | string> {
        return new Promise(resolve => {
            this.biometricSecretOptions = {disableBackup: true, secret: secret};
            this.faio.registerBiometricSecret(this.biometricSecretOptions)
                .then(() => {
                    this.biometricsConfig.setRegistered(this.isConfiguredSecret(secret))
                        .then(() => resolve(void 0));
                }).catch((error) => {
                if (error.code === BIOMETRIC_ERRORS.BIOMETRIC_DISMISSED
                    || error.code === BIOMETRIC_ERRORS.BIOMETRIC_LOCKED_OUT) {
                    const registered = this.biometricsConfig.isRegistered();
                    if (!registered) {
                        this.biometricsConfig.setBiometricAllowed(false);
                        this.biometricsConfig.setRegistered(false).then(() => resolve(AppConstants.EMPTY_STRING));
                    }
                    resolve(AppConstants.DELETE);
                } else {
                    this.biometricsConfig.setBiometricAllowed(false);
                    this.biometricsConfig.setBiometricType(null);
                    this.biometricsConfig.setRegistered(false)
                        .then(() => this.alertService.presentAlertBiometricUnsupportedType()
                            .then((result) => resolve(result)));
                }
            });
        });
    }

    isConfiguredSecret(secret: string): boolean {
        return hasStringValues(secret) && secret !== AppConstants.DEFAULT_SECRET;
    }
}
