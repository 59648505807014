import {hasStringValues, hasValues, isDefined, isEmpty, isNullOrUndefined, isString} from './utils';

export class Sort {

    private readonly orders: Order[];
    private readonly implicit: boolean;

    private constructor(orders: Order[]) {
        this.orders = orders;
    }

    static empty(): Sort {
        return new Sort([]);
    }

    static by(property: string, direction: SortDirection, implicit: boolean = true): Sort {
        return new Sort([]).and(property, direction);
    }

    static byMaterialSort(property: string, direction: 'asc' | 'desc' | '', implicit: boolean = false): Sort {
        return direction !== ''
            ? Sort.by(property, SortDirection.of(direction))
            : Sort.empty();
    }

    static parse(value: string | string[]): Sort {
        if (isNullOrUndefined(value)) {
            return Sort.empty();
        } else if (isString(value)) {
            const order = Order.parse(value as string);
            return isNullOrUndefined(order) ? Sort.empty() : new Sort([order]);
        } else if (Array.isArray(value) && hasValues(value)) {
            const orders = value
                .map(it => Order.parse(it))
                .filter(it => isDefined(it));
            return hasValues(orders) ? new Sort(orders) : Sort.empty();
        }

        return Sort.empty();
    }

    isExplicitSortBy(property: string): boolean {
        return !this.implicit && this.firstProperty === property;
    }

    and(property: string, direction: SortDirection): Sort {
        const newOrders = this.orders.concat(new Order(property, direction));
        return new Sort(newOrders);
    }

    format(): string[] {
        return this.orders
            .map(order => order.format())
            .filter(it => hasStringValues(it));
    }

    isEmpty(): boolean {
        return isEmpty(this.orders);
    }

    get firstProperty(): string | null {
        return this.orders.length === 0 ? null : this.orders[0].property;
    }

    get firstDirection(): string | null {
        return this.orders.length === 0 ? null : this.orders[0].direction;
    }
}

export enum SortDirection {
    ASC = 'ASC',
    DESC = 'DESC'
}

export namespace SortDirection {

    export function of(direction: string): SortDirection {
        switch (direction) {
            case 'asc':
                return SortDirection.ASC;
            case 'desc':
                return SortDirection.DESC;
            default:
                throw new Error('Invalid sort direction ' + direction);
        }
    }

    export function opposite(direction: string): SortDirection {
        switch (direction) {
            case 'asc':
                return SortDirection.DESC;
            case 'desc':
                return SortDirection.ASC;
            default:
                throw new Error('Invalid sort direction ' + direction);
        }
    }
}

class Order {

    constructor(public readonly property: string,
                public readonly direction: SortDirection) {
    }

    static parse(value: string): Order {
        const array = hasStringValues(value) ? value.split(',') : [];
        if (array.length === 2) {
            const sortDirection = SortDirection.of(array[1]);
            return new Order(array[0], sortDirection);
        } else if (array.length === 1) {
            return new Order(array[0], SortDirection.ASC);
        }

        return null;
    }

    format(): string {
        return isNullOrUndefined(this.direction) ? '' : `${this.property},${this.direction.toLowerCase()}`;
    }
}
