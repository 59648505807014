<ion-content>
  <ion-grid>
    <ion-row class="ion-margin-top">
      <ion-col size="6" class="ion-text-start">
                <span (click)="closeModal()">
                    <ion-icon src="assets/icon/icon-close.svg" class="icon-close"></ion-icon>
                    <span class="text-close">{{'reports.close' | translate}}</span>
                </span>
      </ion-col>
      <ion-col size="6" class="ion-text-end clear-all">
        <span (click)="clearAll()">{{'reports.clear-all' | translate}}</span>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-list>
    <app-expandable-date-filter [filterNameKey]="'hedged-orders.filters.filter-names.settlement-date'"
                                [dateFrom]="selectedSettlementDateFrom"
                                [dateTo]="selectedSettlementDateTo"
                                (changeDateFromFilter)="onSettlementDateFromFilterChange($event)"
                                (changeDateToFilter)="onSettlementDateToFilterChange($event)">
    </app-expandable-date-filter>
    <app-expandable-date-filter [filterNameKey]="'hedged-orders.filters.filter-names.trade-date'"
                                [dateFrom]="selectedExecutionDateFrom"
                                [dateTo]="selectedExecutionDateTo"
                                [maxDateFrom]="TODAY"
                                [maxDateTo]="TODAY"
                                (changeDateFromFilter)="onTradeDateFromFilterChange($event)"
                                (changeDateToFilter)="onTradeDateToFilterChange($event)">
    </app-expandable-date-filter>
    <app-expandable-radio-button-filter *ngIf="showViewType"
                                        [filterNameKey]="'hedged-orders.filters.filter-names.view-type'"
                                        [filterItems]="viewTypeFilterItems"
                                        [selectedFilterItem]="selectedViewType"
                                        [canDisable]="false"
                                        (changeFilters)="onViewTypeFilterChange($event)">
    </app-expandable-radio-button-filter>
  </ion-list>
  <ion-grid>
    <ion-row>
      <ion-col size="12" class="ion-text-center">
        <ion-button class="main-btn quotes-btn"
                    (click)="dismissModal()">{{'hedged-orders.filters.apply-filters' | translate}}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
