import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {UserDevice, UserDeviceType} from '../../domain/user-device';
import {HttpUtils} from '../../commons/http/http-utils';
import {DeviceService} from '../device/device.service';
import {flatMap, tap} from 'rxjs/operators';
import {DeviceDetectorService} from 'ngx-device-detector';
import {FirebaseService} from '../firebase/firebase.service';

@Injectable({
    providedIn: 'root'
})
export class UserDeviceService {

    private readonly userDeviceIdUpdate = new Subject<string>();

    constructor(private http: HttpClient,
                private deviceService: DeviceService,
                private deviceDetectorService: DeviceDetectorService) {
    }

    getUserDevice(userId: string, deviceId: string): Observable<UserDevice> {
        const url = `${FirebaseService.getApiBasePath()}/api/global/user-devices/${userId}/${deviceId}`;
        return this.http.get<UserDevice>(url, HttpUtils.getRequestOptions());
    }

    getUserDevices(userId: string): Observable<UserDevice[]> {
        const url = `${FirebaseService.getApiBasePath()}/api/global/user-devices/${userId}`;
        return this.http.get<UserDevice[]>(url, HttpUtils.getRequestOptions());
    }

    saveUserDevice(userDevice: UserDevice): Observable<UserDevice> {
        const url = `${FirebaseService.getApiBasePath()}/api/global/user-devices`;
        this.addDeviceInfo(userDevice);
        return this.http.put<UserDevice>(url, userDevice, HttpUtils.getRequestOptions());
    }

    updateDevice(userDevice: UserDevice): Observable<UserDevice> {
        const url = `${FirebaseService.getApiBasePath()}/api/global/user-devices`;
        return this.http.put<UserDevice>(url, userDevice, HttpUtils.getRequestOptions());
    }

    deleteUserDevice(userId: string, deviceId: string): Observable<void> {
        const url = `${FirebaseService.getApiBasePath()}/api/global/user-devices/${userId}/${deviceId}`;
        return this.http.delete<void>(url, HttpUtils.getRequestOptions());
    }

    private getUserDeviceType(): UserDeviceType | null {
        if (this.deviceDetectorService.isDesktop()) {
            return UserDeviceType.DESKTOP;
        } else if (this.deviceDetectorService.isTablet()) {
            return UserDeviceType.TABLET;
        } else if (this.deviceDetectorService.isMobile()) {
            return UserDeviceType.MOBILE;
        }
        return null;
    }

    private addDeviceInfo(userDevice: UserDevice): void {
        const deviceInfo = this.deviceDetectorService.getDeviceInfo();
        userDevice.type = this.getUserDeviceType();
        userDevice.userAgent = deviceInfo.userAgent;
        userDevice.operatingSystem = deviceInfo.os;
        userDevice.operatingSystemVersion = deviceInfo.os_version;
        userDevice.browser = deviceInfo.browser;
        userDevice.browserVersion = deviceInfo.browser_version;
        userDevice.device = deviceInfo.device;
    }

    register(userId: string): Observable<UserDevice> {
        const url = `${FirebaseService.getApiBasePath()}/api/global/user-devices`;
        return this.getUserDevice(userId, this.deviceService.getDeviceId()).pipe(
            tap(userDevice => this.addDeviceInfo(userDevice)),
            tap(userDevice => this.userDeviceIdUpdate.next(userDevice.deviceId)),
            flatMap(userDevice => this.http.put<UserDevice>(url, userDevice, HttpUtils.getRequestOptions()))
        );
    }

    onUserDeviceIdUpdate(): Observable<string> {
        return this.userDeviceIdUpdate.asObservable();
    }
}
