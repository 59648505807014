import {Component, EventEmitter, Output} from '@angular/core';
import {ToastService} from '../../../services/toast/toast.service';
import {TranslateService} from '@ngx-translate/core';
import {NavController} from '@ionic/angular';
import {AppConstants} from '../../app-constants';
import {BiometricsConfigService} from '../../../services/biometrics/biometrics-config.service';
import {BiometricsService} from '../../../services/biometrics/biometrics.service';

@Component({
    selector: 'app-set-pin',
    templateUrl: './set-pin.component.html',
    styleUrls: ['./set-pin.component.scss']
})
export class SetPinComponent {

    readonly samePinRegex = '^(\\d)\\1+$';

    pin = '';
    confirmPin = '';

    @Output() matchedPinChange = new EventEmitter<string>();
    @Output() matchedConfirmationPinChange = new EventEmitter<string>();

    constructor(private toast: ToastService,
                private translate: TranslateService,
                private navCtrl: NavController,
                private biometricsService: BiometricsService,
                private biometricsConfig: BiometricsConfigService) {
    }

    handleInput(pinDigit: string): void {
        if (pinDigit === AppConstants.DELETE) {
            this.deleteLastDigit();
        } else if (this.pin.length < 5) {
            this.pin += pinDigit;
        } else {
            this.confirmPin += pinDigit;
        }
        if (this.pin.length === 5 && (this.pin.match(this.samePinRegex) || this.isConsecutive())) {
            this.toast.error(this.translate.instant('configure-pin-code.pin-complexity-error')).then(() => {
                this.pin = AppConstants.EMPTY_STRING;
                this.confirmPin = AppConstants.EMPTY_STRING;
            });
        } else {
            if (this.confirmPin.length === 5) {
                if (this.pinMatchCheck()) {
                    if (this.biometricsConfig.isBiometricAllowed()) {
                        this.biometricsService.registerBiometricSecret(this.pin).then();
                    }
                    this.matchedPinChange.emit(this.pin);
                    this.matchedConfirmationPinChange.next(this.confirmPin);
                } else {
                    this.toast.error(this.translate.instant('configure-pin-code.pin-match-error'))
                        .then(() => {
                            this.pin = AppConstants.EMPTY_STRING;
                            this.confirmPin = AppConstants.EMPTY_STRING;
                        });
                }
            }
        }
    }

    pinMatchCheck(): boolean {
        return this.pin === this.confirmPin;
    }

    goBack(): void {
        this.navCtrl.pop().then();
    }

    deleteLastDigit(): void {
        if (this.confirmPin.length > 0) {
            this.confirmPin = this.confirmPin.substr(0, this.confirmPin.length - 1);
        } else {
            this.pin = this.pin.substr(0, this.pin.length - 1);
        }
    }

    private isConsecutive(): boolean {
        for (let i = 0; i < this.pin.length - 1; i++) {
            if (!(+this.pin.charAt(i) + 1 === +this.pin.charAt(i + 1))) {
                return false;
            }
        }
        return true;
    }
}
