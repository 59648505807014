import {Pipe, PipeTransform} from '@angular/core';
import {DecimalPipe} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
    name: 'thousandSuffixes'
})
export class ThousandSuffixesPipe implements PipeTransform {

    suffixes = ['K', 'M', 'B', 'T'];

    constructor(private decimalPipe: DecimalPipe,
                private translateService: TranslateService) {
    }

    transform(value: number): string {
        if (value < 1000000) {
            return this.decimalPipe.transform(value, '1.0-0', this.translateService.getDefaultLang());
        } else {
            let exp = Math.floor(Math.log(value) / Math.log(1000));
            return (value / Math.pow(1000, exp)).toFixed(2) + this.suffixes[exp - 1];
        }
    }

}
