<ion-row>
    <p class="description">
        {{'previous-pin-code.description' | translate}}
    </p>
</ion-row>
<div class="prev-pin-label">
    <span>{{'previous-pin-code.type-pin' | translate}}</span>
</div>
<div class="prev-pin">
    <ion-icon [name]="currentUserPin.length>0 ? 'radio-button-on' : 'radio-button-off'"></ion-icon>
    <ion-icon [name]="currentUserPin.length>1 ? 'radio-button-on' : 'radio-button-off'"></ion-icon>
    <ion-icon [name]="currentUserPin.length>2 ? 'radio-button-on' : 'radio-button-off'"></ion-icon>
    <ion-icon [name]="currentUserPin.length>3 ? 'radio-button-on' : 'radio-button-off'"></ion-icon>
    <ion-icon [name]="currentUserPin.length>4 ? 'radio-button-on' : 'radio-button-off'"></ion-icon>
</div>
<app-keyboard (keyEmitter)="handleInput($event)"></app-keyboard>
