import {ChangeDetectionStrategy, Component, Input, OnChanges, OnInit} from '@angular/core';
import {PositionOverviewFilter} from '../../../../domain/filter/position-overview-filter';
import {isDefined} from '../../../../commons/utils';
import {
    FilterPeriods
} from '../../insights-details-modal/position-overview-details-modal/position-overview-details-modal.component';

@Component({
    selector: 'app-insights-dates-label',
    templateUrl: './insights-dates-label.component.html',
    styleUrls: ['./insights-dates-label.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InsightsDatesLabelComponent implements OnInit, OnChanges {

    shownDates: ShownDates;

    @Input() positionOverviewFilter: PositionOverviewFilter;
    @Input() filterPeriods: FilterPeriods;
    @Input() backgroundGrey = false;

    constructor() {
    }

    ngOnInit(): void {
        this.calculateShownDates();
    }

    ngOnChanges(): void {
        this.calculateShownDates();
    }

    private calculateShownDates(): void {
        this.shownDates = {
            settlementDateFrom: isDefined(this.positionOverviewFilter.hedgeDateFrom)
                ? new Date(this.positionOverviewFilter.hedgeDateFrom)
                : this.filterPeriods.settlementPeriod.startDate,
            settlementDateTo: isDefined(this.positionOverviewFilter.hedgeDateTo)
                ? new Date(this.positionOverviewFilter.hedgeDateTo)
                : this.filterPeriods.settlementPeriod.endDate,
            tradeDateFrom: isDefined(this.positionOverviewFilter.executionDateFrom)
                ? new Date(this.positionOverviewFilter.executionDateFrom)
                : this.filterPeriods.tradesPeriod.startDate,
            tradeDateTo: isDefined(this.positionOverviewFilter.executionDateTo)
                ? new Date(this.positionOverviewFilter.executionDateTo)
                : this.filterPeriods.tradesPeriod.endDate,
            showSettlementDates: isDefined(this.positionOverviewFilter.hedgeDateFrom)
                || isDefined(this.positionOverviewFilter.hedgeDateTo),
            showTradeDates: isDefined(this.positionOverviewFilter.executionDateFrom)
                || isDefined(this.positionOverviewFilter.executionDateTo)
        };
    }
}

export class ShownDates {
    settlementDateFrom: Date;
    settlementDateTo: Date;
    tradeDateFrom: Date;
    tradeDateTo: Date;
    showSettlementDates = false;
    showTradeDates = false;
}
