import {Component, Input, OnInit} from '@angular/core';
import {AppConstants} from '../../app-constants';

@Component({
    selector: 'app-pin-form',
    templateUrl: './pin-form.component.html',
    styleUrls: ['./pin-form.component.scss'],
})
export class PinFormComponent implements OnInit {

    @Input() pin = AppConstants.EMPTY_STRING;

    constructor() {
    }

    ngOnInit(): void {
    }

}
