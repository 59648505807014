import {EventEmitter} from '@angular/core';
import {Consumer} from '../functional-types';

export class ElementState {

    private readonly eventEmitter = new EventEmitter<boolean>();

    constructor(consumer: Consumer<boolean>) {
        this.eventEmitter.subscribe(value => consumer(value));
    }

    disable(): void {
        this.eventEmitter.emit(true);
    }

    enable(): void {
        this.eventEmitter.emit(false);
    }
}