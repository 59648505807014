<div class="bank-holiday-alert">
    <ion-grid class="ion-no-margin">
        <ion-row>
            <ion-col class="ion-text-end" size="12">
                <ion-icon src="assets/icon/icon-close.svg" class="icon-close"
                          (click)="dismiss()">
                </ion-icon>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col class="ion-text-center">
                <ion-icon *ngIf="isBankHoliday"
                          class="support-hours-icon"
                          src="assets/icon/bank-holiday.svg">
                </ion-icon>
                <ion-icon *ngIf="isOutsideSupportHours"
                          class="support-hours-icon"
                          src="assets/icon/outside-support-hours.svg">

                </ion-icon>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col *ngIf="isBankHoliday" class="ion-text-center mat-card-title">
                {{'bank-holiday.alert-header' | translate}}
            </ion-col>
            <ion-col *ngIf="isOutsideSupportHours" class="ion-text-center mat-card-title">
                {{'outside-support-hours.alert-header' | translate}}
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col>
                <app-open-hours></app-open-hours>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col class="ion-text-center">
                <ion-button class="main-btn dismiss-button" size="small"
                            (click)="dismiss()">
                    {{'bank-holiday.alert-button' | translate}}
                </ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
</div>
