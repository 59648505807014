import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Dictionary} from '../../dictionary';
import {Company} from '../../../domain/company';
import {ScheduleConfiguration, ScheduleInterval} from '../../../domain/schedule-configuration';
import {CompanyService} from '../../../services/company/company.service';
import {isDefined} from '../../utils';
import {ConfigurationService} from '../../../services/configuration/configuration.service';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {switchMap} from 'rxjs/operators';

@Component({
    selector: 'app-open-hours',
    templateUrl: './open-hours.component.html',
    styleUrls: ['./open-hours.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpenHoursComponent implements OnInit, OnDestroy {

    readonly isDefined = isDefined;
    readonly ScheduleInterval = ScheduleInterval;

    private company: Company;
    private timezoneCity: string;

    private getSchedule$: BehaviorSubject<void>;
    private companySubscription: Subscription;

    tradingScheduleConfiguration$: Observable<ScheduleConfiguration>;
    supportScheduleConfiguration$: Observable<ScheduleConfiguration>;

    constructor(private readonly route: ActivatedRoute,
                private readonly companyService: CompanyService,
                private readonly configurationService: ConfigurationService) {
    }

    ngOnInit(): void {
        this.getSchedule$ = new BehaviorSubject<void>(undefined);
        this.supportScheduleConfiguration$ = this.getSchedule$.pipe(
            switchMap(() => this.configurationService.getSupportHoursConfiguration())
        );
        this.tradingScheduleConfiguration$ = this.getSchedule$.pipe(
            switchMap(() => this.configurationService.getTradingHoursConfiguration())
        );

        this.getSchedule();

        this.companySubscription = this.companyService.onCompanyChange().subscribe(() => {
            this.getSchedule();
        });

    }

    getScheduleConfigurationTranslateArgs(scheduleConfiguration: ScheduleConfiguration): Dictionary<string> {
        const args: Dictionary<string> = {};
        if (isDefined(scheduleConfiguration)) {
            args['date'] = scheduleConfiguration.formattedDate;
        }
        if (isDefined(this.timezoneCity)) {
            args['timezone'] = this.timezoneCity;
        }
        return args;
    }

    private getSchedule(): void {
        this.getSchedule$.next();

        this.company = CompanyService.getCurrentCompany();
        this.timezoneCity = isDefined(this.company)
            ? this.company.timezone.indexOf('/') >= 0
                ? this.company.timezone.split('/')[1]
                : this.company.timezone
            : null;
    }

    ngOnDestroy(): void {
        if (isDefined(this.companySubscription)) {
            this.companySubscription.unsubscribe();
        }
    }
}
