import {Injectable} from '@angular/core';
import {Dictionary} from '../../commons/dictionary';
import {noop, Observable} from 'rxjs';
import {FirebaseService} from '../firebase/firebase.service';
import {CompanyService} from '../company/company.service';
import {HttpUtils} from '../../commons/http/http-utils';
import {TranslateService} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {GlossarySelector} from '../../modules/more/glossary/util/glossary-selector';
import {isDefined} from '../../commons/utils';

@Injectable({providedIn: 'root'})
export class GlossaryService {
    private glossaryKeywords: Dictionary<string> = {};

    constructor(private readonly companyService: CompanyService,
                private readonly translateService: TranslateService,
                private readonly http: HttpClient) {
    }

    getContent(): Observable<Dictionary<string>> {
        const langKey = this.translateService.currentLang;
        const path = `${FirebaseService.getApiBasePath()}/api/global/contextual-help/${langKey}/flat`;
        return this.http.get<Dictionary<string>>(path, HttpUtils.getRequestOptions());
    }

    setGlossaryKeywords(content: Dictionary<string>): void {
        const pattern = /help\.glossary\.(.+)\.title/;
        this.glossaryKeywords = {};
        Object
            .keys(content)
            .filter(key => key.match(pattern))
            .forEach(key => {
                const chapterTitle = content[key].toLowerCase();
                this.glossaryKeywords[chapterTitle] = key.replace(pattern, '$1');
            });
    }

    scrollToSelectedItem(glossarySelector: GlossarySelector): void {
        const chapter = glossarySelector.chapter;
        if (isDefined(chapter)) {
            const element = document.getElementById(chapter);
            isDefined(element) ? element.scrollIntoView({behavior: 'smooth'}) : noop();
        }
    }

    getGlossaryChapterTitles(): string[] {
        return Object.keys(this.glossaryKeywords);
    }

    getGlossaryChapter(chapterTitle: string): string {
        return this.glossaryKeywords[chapterTitle.toLowerCase()];
    }
}
