<app-insights-header [isTradingVolumeSelected]="isTradingVolumeSelected"
                     [positionOverviewFilter]="positionOverviewFilter"
                     (onFilterClosed)="filterChanged($event)">
</app-insights-header>
<ng-container *ngIf="{totalVolumes: positionsOverviewTotalVolume$ | async,
                              nrOfTrades: positionsOverviewNrOfTrades$ | async } as groupedPositionsOverview">
    <div *ngIf="!isVolumeChartLoading && !isTotalChartLoading && !showNoTrades" class="chart-row">
        <swiper #swiper
                [config]="swiperConfigs"
                (slideChange)="detectSwiperChanges()">
            <ng-template swiperSlide>
                <ng-container>
                    <app-currency-doughnut-chart [positionsOverview]="groupedPositionsOverview.totalVolumes"
                                                 [colors]="colors"
                                                 [selectedSegmentIndex]="selectedSegmentIndex"
                                                 [volume]="true"
                                                 [totalNumberOfTrades]="totalNumberOfTrades"
                                                 [totalVolume]="totalVolume"
                                                 [parsingKey]="tradingVolumeParsingKey"
                                                 (clickSegment)="onSegmentClicked($event)">
                    </app-currency-doughnut-chart>
                </ng-container>
            </ng-template>
            <ng-template swiperSlide>
                <ng-container>
                    <app-currency-doughnut-chart [positionsOverview]="groupedPositionsOverview.nrOfTrades"
                                                 [colors]="colors"
                                                 [selectedSegmentIndex]="selectedSegmentIndex"
                                                 [volume]="false"
                                                 [totalNumberOfTrades]="totalNumberOfTrades"
                                                 [totalVolume]="totalVolume"
                                                 [parsingKey]="numberOfTradesParsingKey"
                                                 (clickSegment)="onSegmentClicked($event)">
                    </app-currency-doughnut-chart>
                </ng-container>
            </ng-template>
        </swiper>
    </div>
</ng-container>
<ng-container *ngIf="isVolumeChartLoading &&isTotalChartLoading">
    <ion-spinner name="crescent"></ion-spinner>
</ng-container>
<div *ngIf="showNoTrades"
     class="empty-chart-wrap">
    <ion-icon src="assets/icon/empty_doughnut.svg"
              class="empty-doughnut">
    </ion-icon>
</div>
<ng-container *ngIf="filterPeriods$ | async as filterPeriods">
    <app-insights-dates-label *ngIf="!isVolumeChartLoading && !showNoTrades"
                              [positionOverviewFilter]="positionOverviewFilter"
                              [filterPeriods]="filterPeriods">
    </app-insights-dates-label>
</ng-container>
<ion-content *ngIf="!isVolumeChartLoading && !showNoTrades"
             [scrollY]="!isVolumeChartLoading"
             [class.scroll-container]="!isVolumeChartLoading"
             [scrollEvents]="true">
    <ng-container>
        <ion-list>
            <ng-container *ngIf="!isTradingVolumeSelected">
                <app-currency-analytic-card
                        *ngFor="let positionOverview of nrOfTradesPositionsOverview, let i = index"
                        [index]="i"
                        [positionOverview]="positionOverview"
                        [positionOverviewFilter]="positionOverviewFilter"
                        [highlight]="i === selectedSegmentIndex
                                                || (selectedSegmentIndex >= 4 && i >= 4)"
                        [highlightColor]="isDefined(colors[selectedSegmentIndex])
                                                ? colors[selectedSegmentIndex]
                                                : colors[colors.length - 1]"
                        [numberOfTrades]="!isTradingVolumeSelected"
                        [volume]="isTradingVolumeSelected"
                        (cardClick)="onCardClicked($event)">
                </app-currency-analytic-card>
            </ng-container>
            <ng-container *ngIf="isTradingVolumeSelected">
                <app-currency-analytic-card
                        *ngFor="let positionOverview of totalVolumesPositionsOverview, let i = index"
                        [index]="i"
                        [positionOverview]="positionOverview"
                        [positionOverviewFilter]="positionOverviewFilter"
                        [highlight]="i === selectedSegmentIndex
                                                || (selectedSegmentIndex >= 4 && i >= 4)"
                        [highlightColor]="isDefined(colors[selectedSegmentIndex])
                                                ? colors[selectedSegmentIndex]
                                                : colors[colors.length - 1] "
                        [numberOfTrades]="!isTradingVolumeSelected"
                        [volume]="isTradingVolumeSelected"
                        (cardClick)="onCardClicked($event)">
                </app-currency-analytic-card>
            </ng-container>
        </ion-list>
    </ng-container>
</ion-content>
<div *ngIf="!isVolumeChartLoading && showNoTrades" class="ion-text-center no-trades-label">
    <ion-label class="no-trades">{{'insights.no-trades-shown' | translate }}</ion-label>
</div>
