<div class="tenors">
    <ion-grid class="ion-no-padding">
        <ion-row>
            <ion-col *ngFor="let tenor of tenorList" class="ion-align-items-center buttons-container"
                     (click)="changeTenor(tenor)">
                <div class="tenor-badge" [class.tenor-badge-active]="tenor === activeTenor">
                    {{'tenor.tenor-abbreviations.' + tenor | translate}}
                </div>
            </ion-col>
        </ion-row>
    </ion-grid>
</div>
