import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import {Moment} from 'moment';
import {Comparator} from '../../commons/functional-types';
import {isNullOrUndefined} from '../../commons/utils';
import 'moment-timezone';

@Injectable({
    providedIn: 'root'
})
export class DateTimeUtilService {

    public static max(granularity: 'day'): (a: Moment, b: Moment) => Moment {
        return (a, b) => a.isSameOrAfter(b, granularity) ? a : b;
    }

    constructor(private translateService: TranslateService) {
    }

    static readonly ascendingComparator: Comparator<Moment> = (a, b) => {
        if (a.isBefore(b)) {
            return -1;
        } else if (a.isAfter(b)) {
            return 1;
        } else {
            return 0;
        }
    };

    static formatToIsoDate(date: string | Date | Moment): string | null {
        return isNullOrUndefined(date) ? null : moment(date).format('YYYY-MM-DD');
    }

    static readonly descendingComparator: Comparator<Moment> = (a, b) => DateTimeUtilService.ascendingComparator(b, a);

    static formatTimezone(timezone: string): string {
        return moment().tz(timezone).format('Z');
    }

    static formatUtc(): string {
        return moment().utc().format('Z');
    }

    public getTimeSinceLabel(date: Date): string {
        const createdTime = moment(date).utc();
        const now = moment().utc();
        const endOfDay = moment.utc().endOf('day');
        const duration = moment.duration(now.diff(createdTime));
        const seconds = Math.floor(duration.asSeconds());
        if (seconds < 60) {
            return this.translateService.instant('activity.time-since.just-now');
        } else {
            const minutes = Math.floor(duration.asMinutes());
            if (minutes < 60) {
                return this.translateService.instant('activity.time-since.minutes-ago', {
                    minutes: minutes
                });
            } else {
                const hours = Math.floor(duration.asHours());
                if (hours < 24) {
                    const today = createdTime.isSameOrBefore(endOfDay);
                    if (today) {
                        return this.translateService.instant('activity.time-since.hours-ago', {
                            hours: hours
                        });
                    } else {
                        return this.translateService.instant('activity.time-since.yesterday');
                    }
                } else {
                    const days = Math.floor(duration.asDays());
                    if (days < 7) {
                        return this.translateService.instant('activity.time-since.days-ago', {
                            days: days
                        });
                    } else {
                        const weeks = Math.floor(duration.asWeeks());
                        if (weeks < 5) {
                            return this.translateService.instant('activity.time-since.weeks-ago', {
                                weeks: weeks
                            });
                        } else {
                            const months = Math.floor(duration.asMonths());
                            if (months < 12) {
                                return this.translateService.instant('activity.time-since.months-ago', {
                                    months: months
                                });
                            } else {
                                const years = Math.floor(duration.asYears());
                                return this.translateService.instant('activity.time-since.years-ago', {
                                    years: years
                                });
                            }
                        }
                    }
                }
            }
        }
    }
}
