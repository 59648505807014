import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';

@Pipe({
    name: 'time',
})
export class TimePipe implements PipeTransform {

    hourMinute = 'HH:mm';

    constructor(private datePipe: DatePipe) {
    }

    transform(value: Date | string): string {
        if (typeof value === 'string') {
            const date = new Date(value);
            return this.datePipe.transform(date, this.hourMinute);
        } else {
            return this.datePipe.transform(value, this.hourMinute);
        }
    }
}
