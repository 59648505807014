import {DeviceStatus} from './device-status';

export class UserDevice {
    id: string;
    userId: string;
    deviceName: string;
    deviceId: string;
    firebaseToken: string;
    status: DeviceStatus;
    mobileClient: boolean;
    type: UserDeviceType;
    userAgent: string;
    operatingSystem: string;
    operatingSystemVersion: string;
    browser: string;
    browserVersion: string;
    device: string;
    lastRegisterDate: Date;
}

export enum UserDeviceType {
    DESKTOP = 'DESKTOP', MOBILE = 'MOBILE', TABLET = 'TABLET'
}
