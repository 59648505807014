import {BehaviorSubject, MonoTypeOperatorFunction, noop, Observable, of, UnaryFunction} from 'rxjs';
import {Dictionary} from './dictionary';
import {AbstractControl, AsyncValidatorFn, ValidationErrors} from '@angular/forms';
import {debounceTime, distinctUntilChanged, switchMap, take} from 'rxjs/operators';
import {ActivatedRoute, ActivatedRouteSnapshot} from '@angular/router';

export function isNullOrUndefined(value: any): boolean {
    return value === undefined || value === null;
}

export function isDefined(value: any): boolean {
    return !isNullOrUndefined(value);
}

export function isString(value: any): boolean {
    return typeof value === 'string';
}

export function isNumber(value: any): boolean {
    return (typeof value === 'number') && !Number.isNaN(value);
}

export function isPositiveNumber(value: any): boolean {
    return isNumber(value) && value >= 0;
}

export function isNegativeNumber(value: any): boolean {
    return isNumber(value) && value <= 0;
}

export function isObject(value: any): boolean {
    return value !== null && typeof value === 'object';
}

export function copy<T = object | number | string>(value: T): T {
    return JSON.parse(JSON.stringify(value));
}

export function isStringEmptyOrNull(s: string): boolean {
    return isNullOrUndefined(s) || s === '';
}

export function hasStringValues(value: string): boolean {
    return !isStringEmptyOrNull(value);
}

export function isEmpty(values: any[] | Dictionary<any>): boolean {
    if (isNullOrUndefined(values)) {
        return true;
    }
    return Array.isArray(values) ? values.length === 0 : Object.keys(values).length === 0;
}

export function hasValues(values: any[] | Dictionary<any>): boolean {
    return !isEmpty(values);
}

export function parseBoolean(value?: string): boolean {
    return isDefined(value) && value.toLowerCase() === 'true';
}

/**
 * Always provide an initial value to the reduce function, mostly 0.
 */
export const sum = (total, amount) => parseFloat(total) + parseFloat(amount);

export const count = (total) => parseFloat(total) + 1;

export function asyncFilter<T>(asyncPredicate: UnaryFunction<T, Observable<boolean>>): MonoTypeOperatorFunction<T> {
    return (source: Observable<T>) => asyncFilterImpl(source, asyncPredicate);
}

function asyncFilterImpl<T>(source: Observable<T>,
                            asyncPredicate: UnaryFunction<T, Observable<boolean>>): Observable<T> {
    return new Observable<T>(subscriber =>
        source.subscribe((value: T) => asyncPredicate(value).subscribe(
            predicateValue => predicateValue ? subscriber.next(value) : noop(),
            err => subscriber.error(err)
        ))
    );
}

export function areArraysEqual(array1: any[], array2: any[]): boolean {
    if (isNullOrUndefined(array1) && isNullOrUndefined(array2)) {
        return true;
    }
    if (isNullOrUndefined(array1) || isNullOrUndefined(array2)) {
        return false;
    }

    return array1.length === array2.length && array1.every(element => array2.includes(element));
}

export function includesAnyElement<T>(array: T[], elements: T[]): boolean {
    return elements.some(element => array.includes(element));
}

export function debouncedAsyncValidator<T>(validator: UnaryFunction<T, Observable<ValidationErrors | null>>,
                                           time: number = 800): AsyncValidatorFn {
    const valuesChange = new BehaviorSubject<T>(null);
    const validationResult$ = valuesChange.asObservable().pipe(
        distinctUntilChanged(),
        debounceTime(time),
        switchMap(value => isDefined(value) && value.toString().trim().length > 0
            ? validator(value)
            : of(null)
        )
    );
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
        valuesChange.next(control.value);
        return validationResult$.pipe(take(1));
    };
}

export function isStringOrArrayEmpty(value: string | string[]): boolean {
    if (isNullOrUndefined(value)) {
        return true;
    }
    return Array.isArray(value) ? value.length === 0 : isStringEmptyOrNull(value);
}

export function getCurrentActivatedRouteSnapshot(activatedRoute: ActivatedRoute): ActivatedRouteSnapshot {
    let snapshot = activatedRoute.snapshot;
    let activatedRouteChild = activatedRoute.firstChild;
    while (isDefined(activatedRouteChild)) {
        snapshot = activatedRouteChild.snapshot;
        activatedRouteChild = activatedRouteChild.firstChild;
    }
    return snapshot;
}

/**
 * Always provide an initial value to the reduce function, mostly [].
 */
export function flatMap<T>(array1: T[], array2: T[]): T[] {
    return array1.concat(array2);
}

export const unique = (element, index, array) => array.indexOf(element) === index;

export function createNonce(): string {
    const unreserved = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
    let size = 45;
    let id = '';
    while (0 < size--) {
        // tslint:disable-next-line:no-bitwise
        id += unreserved[(Math.random() * unreserved.length) | 0];
    }
    return base64UrlEncode(id);
}

export function base64UrlEncode(str): string {
    const base64 = btoa(str);
    return base64.replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '');
}

export function countSubstringOccurrences(inputString: string, substring: string): number {
    const escapedSubstring = substring.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(escapedSubstring, 'g');
    const matches = inputString.match(regex);

    return matches ? matches.length : 0;
}
