import {Injectable, Injector} from '@angular/core';
import {PlatformEnum} from '../../domain/platform';
import {Device} from '@awesome-cordova-plugins/device/ngx';
import {LocalStorageService} from '../local-storage/local-storage.service';
import {isNullOrUndefined} from '../../commons/utils';
import {AppConstants} from '../../commons/app-constants';

@Injectable({
    providedIn: 'root'
})
export class DeviceService {

    private static readonly WEB_BROWSER = 'web_browser';

    private readonly device: Device;
    private readonly localStorage: LocalStorageService;

    constructor(private injector: Injector,
                localStorage: LocalStorageService) {
        try {
            this.device = injector.get(Device);
        } catch (error) {
            this.device = null;
        }
        this.localStorage = localStorage;
    }

    getDeviceId(): string {
        let deviceId = this.localStorage.getItem<string>(AppConstants.DEVICE_ID);
        if (isNullOrUndefined(deviceId)) {
            const uuid = isNullOrUndefined(this.device) || isNullOrUndefined(this.device.uuid)
                ? DeviceService.WEB_BROWSER
                : this.device.uuid;
            this.localStorage.setItem<string>(AppConstants.DEVICE_ID, uuid).then();
            deviceId = uuid;
        }
        return deviceId;
    }

    getPlatform(): PlatformEnum {
        return isNullOrUndefined(this.device) || isNullOrUndefined(this.device.uuid)
            ? PlatformEnum.WEB
            : PlatformEnum.MOBILE;
    }
}
