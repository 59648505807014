import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';
import {HasAnyAuthorityDirective} from './has-any-authority.directive';
import {OneClickDirective} from './one-click-directive/one-click.directive';
import {MidRateVisibleDirective} from './mid-rate-visible.directive';
import {InsightsFilterComponent} from '../pages/insights/components/insights-filter/insights-filter.component';
import {
    ExpandableDateFilterComponent
} from '../modules/hedged-orders/components/filters/expandable-date-filter/expandable-date-filter.component';
import {DateInputComponent} from '../modules/hedged-orders/components/date-input/date-input.component';
import {
    ExpandableCheckboxFilterComponent
} from '../modules/hedged-orders/components/filters/expandable-checkbox-filter/expandable-checkbox-filter.component';
import {
    ExpandableRadioButtonFilterComponent
} from '../modules/hedged-orders/components/filters/expandable-radio-button-filter/expandable-radio-button-filter.component';
import {InsightsModalComponent} from '../pages/insights/insights-modal/insights-modal.component';
import {IonicModule} from '@ionic/angular';
import {
    CurrencyAnalyticCardComponent
} from '../pages/insights/components/currency-analytic-card/currency-analytic-card.component';
import {PipesModule} from '../pipes/pipes.module';
import {
    CurrencyDoughnutChartComponent
} from '../pages/insights/components/currency-doughnut-chart/currency-doughnut-chart.component';
import {
    DoughnutChartLabelComponent
} from '../pages/insights/components/doughnut-chart-label/doughnut-chart-label.component';
import {
    PositionOverviewDetailsModalComponent
} from '../pages/insights/insights-details-modal/position-overview-details-modal/position-overview-details-modal.component';
import {
    BarChartTotalCardComponent
} from '../pages/insights/components/bar-chart-total-card/bar-chart-total-card.component';
import {
    BarChartSideCardComponent
} from '../pages/insights/components/bar-chart-side-card/bar-chart-side-card.component';
import {
    FilterCheckboxComponent
} from '../modules/hedged-orders/components/filters/filter-checkbox/filter-checkbox.component';
import {TenorBarComponent} from '../pages/insights/components/tenor-bar/tenor-bar.component';
import {
    InsightsDatesLabelComponent
} from '../pages/insights/components/insights-dates-label/insights-dates-label.component';
import {SwiperModule} from 'swiper/angular';
import {InsightsHeaderComponent} from '../pages/insights/components/insights-header/insights-header.component';
import {PageTitleComponent} from './components/page-title/page-title.component';
import {HeaderComponent} from './components/header/header.component';
import {TenorsBarComponent} from './components/tenors-bar/tenors-bar.component';
import {UnauthorisedCardComponent} from './components/unauthorised-card/unauthorised-card.component';
import {OpenHoursComponent} from './components/open-hours/open-hours.component';
import {SupportHoursAlertComponent} from './components/support-hours-alert/support-hours-alert.component';
import {NotificationMessageComponent} from './components/notification-message/notification-message.component';
import {NotificationCountComponent} from './components/notification-count/notification-count.component';
import {TenorSelectorComponent} from '../pages/insights/components/tenor-selector/tenor-selector.component';
import {ChangeCompanyComponent} from './components/change-company/change-company.component';
import {SetPinComponent} from './components/set-pin/set-pin.component';
import {KeyboardComponent} from '../modules/authorization/components/keyboard/keyboard.component';
import {
    PreviousPinCheckComponent
} from '../modules/authorization/components/previous-pin-check/previous-pin-check.component';
import {
    PositionOverviewComponent
} from '../pages/insights/insights-modal/position-overview/position-overview.component';
import {
    SettlementOverviewComponent
} from '../pages/insights/insights-modal/settlement-overview/settlement-overview.component';
import {BubbleChartComponent} from '../pages/insights/components/bubble-chart/bubble-chart.component';
import {BubbleChartCardComponent} from '../pages/insights/components/bubble-chart-card/bubble-chart-card.component';
import {PinFormComponent} from './components/pin-form/pin-form.component';
import {QrCodeDescriptionComponent} from './components/qr-code-description/qr-code-description.component';
import {
    SettlementOverviewDetailsModalComponent
} from '../pages/insights/insights-details-modal/settlement-overview-details-modal/settlement-overview-details-modal.component';
import {
    SettlementOverviewFilterComponent
} from '../pages/insights/components/settlement-overview-filter/settlement-overview-filter.component';
import {
    SettlementOverviewBarChartComponent
} from '../pages/insights/components/settlement-overview-bar-chart/settlement-overview-bar-chart.component';
import {
    SettlementOverviewDetailsListComponent
} from '../pages/insights/components/settlement-overview-details-list/settlement-overview-details-list.component';
import {
    SettlementOverviewDetailsListItemComponent
} from '../pages/insights/components/settlement-overview-details-list-item/settlement-overview-details-list-item.component';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {LanguageListComponent} from './language-list/language-list.component';
import {LetModule} from '@ngrx/component';
import {ShareComponent} from './components/share/share.component';
import {ScrollableContainerComponent} from './components/scrollable-table-container/scrollable-container.component';
import {RegulatoryDocsComponent} from './components/regulatory-docs/regulatory-docs.component';

@NgModule({
    declarations: [
        BarChartSideCardComponent,
        BarChartTotalCardComponent,
        ChangeCompanyComponent,
        CurrencyAnalyticCardComponent,
        CurrencyDoughnutChartComponent,
        DateInputComponent,
        DoughnutChartLabelComponent,
        ExpandableCheckboxFilterComponent,
        ExpandableDateFilterComponent,
        ExpandableRadioButtonFilterComponent,
        FilterCheckboxComponent,
        HasAnyAuthorityDirective,
        HeaderComponent,
        InsightsDatesLabelComponent,
        PositionOverviewDetailsModalComponent,
        InsightsFilterComponent,
        InsightsHeaderComponent,
        InsightsModalComponent,
        MidRateVisibleDirective,
        NotificationCountComponent,
        NotificationMessageComponent,
        OneClickDirective,
        OpenHoursComponent,
        PageTitleComponent,
        SupportHoursAlertComponent,
        TenorBarComponent,
        TenorsBarComponent,
        TenorSelectorComponent,
        UnauthorisedCardComponent,
        SetPinComponent,
        KeyboardComponent,
        PreviousPinCheckComponent,
        PositionOverviewComponent,
        SettlementOverviewComponent,
        BubbleChartComponent,
        BubbleChartCardComponent,
        PinFormComponent,
        QrCodeDescriptionComponent,
        SettlementOverviewDetailsModalComponent,
        SettlementOverviewFilterComponent,
        SettlementOverviewBarChartComponent,
        SettlementOverviewDetailsListComponent,
        SettlementOverviewDetailsListItemComponent,
        LanguageListComponent,
        ShareComponent,
        ScrollableContainerComponent,
        RegulatoryDocsComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        PipesModule,
        ReactiveFormsModule,
        SwiperModule,
        TranslateModule,
        CdkVirtualScrollViewport,
        LetModule
    ],
    providers: [],
    exports: [
        ChangeCompanyComponent,
        CommonModule,
        DateInputComponent,
        ExpandableCheckboxFilterComponent,
        ExpandableDateFilterComponent,
        ExpandableRadioButtonFilterComponent,
        FilterCheckboxComponent,
        FormsModule,
        HasAnyAuthorityDirective,
        HeaderComponent,
        InsightsDatesLabelComponent,
        InsightsHeaderComponent,
        MidRateVisibleDirective,
        NotificationCountComponent,
        NotificationMessageComponent,
        OneClickDirective,
        OpenHoursComponent,
        PageTitleComponent,
        ReactiveFormsModule,
        SupportHoursAlertComponent,
        SwiperModule,
        TenorsBarComponent,
        TenorSelectorComponent,
        TranslateModule,
        UnauthorisedCardComponent,
        SetPinComponent,
        KeyboardComponent,
        PreviousPinCheckComponent,
        PositionOverviewComponent,
        SettlementOverviewComponent,
        BubbleChartComponent,
        PinFormComponent,
        QrCodeDescriptionComponent,
        SettlementOverviewDetailsModalComponent,
        SettlementOverviewFilterComponent,
        SettlementOverviewBarChartComponent,
        BubbleChartCardComponent,
        SettlementOverviewDetailsListComponent,
        SettlementOverviewDetailsListItemComponent,
        LanguageListComponent,
        LetModule,
        ShareComponent,
        ScrollableContainerComponent,
        RegulatoryDocsComponent
    ]
})
export class CommonsModule {
}
