import {Injectable, OnDestroy} from '@angular/core';
import {NGXLogger, NgxLoggerLevel} from 'ngx-logger';
import {HttpHeaders} from '@angular/common/http';
import {Subscription} from 'rxjs';
import {hasStringValues, isDefined, isNullOrUndefined} from '../../commons/utils';
import {HttpConstants} from '../../commons/http-constants';
import {Consumer} from '../../commons/functional-types';
import {UserDeviceService} from '../user-device/user-device.service';
import {DeviceService} from '../device/device.service';
import {ReCaptchaService} from '../re-captcha/re-captcha.service';
import {SharedDataService} from '../shared-data/shared-data.service';
import {AppConstants} from '../../commons/app-constants';
import {AuthorizationService} from '../../auth/authorization.service';
import {environment} from '../../../environments/environment';
import {FirebaseService} from '../firebase/firebase.service';
import {INGXLoggerConfig} from 'ngx-logger/lib/config/iconfig';

@Injectable({
    providedIn: 'root'
})
export class LoggerService implements OnDestroy {

    private static readonly LOGGING_RECAPTCHA_ACTION = 'unauthenticatedLogging';

    private reCaptchaSubscription = new Subscription();
    private loggerHeaders = new HttpHeaders();
    private readonly clientSideLogger: NGXLogger;

    constructor(private authorizationService: AuthorizationService,
                private sharedDataService: SharedDataService,
                private userDeviceService: UserDeviceService,
                private deviceService: DeviceService,
                private logger: NGXLogger,
                private reCaptchaService: ReCaptchaService) {
        this.clientSideLogger = this.logger;
    }

    private static getHeaders(accessToken: string, deviceId: string): HttpHeaders {
        let loggerHeaders = new HttpHeaders();

        loggerHeaders = isDefined(accessToken)
            ? loggerHeaders.set(HttpConstants.AUTHORIZATION, HttpConstants.BEARER + accessToken)
            : loggerHeaders.delete(HttpConstants.AUTHORIZATION);

        loggerHeaders = isDefined(deviceId)
            ? loggerHeaders.set(HttpConstants.DEVICE_ID_HEADER_KEY, deviceId)
            : loggerHeaders.delete(HttpConstants.DEVICE_ID_HEADER_KEY);

        return loggerHeaders;
    }

    init(): void {
        this.authorizationService.isAuthenticated().subscribe(() => this.updateConfig());
        this.authorizationService.onAuthenticate().subscribe(() => this.updateConfig());
        this.authorizationService.onTokenUpdate().subscribe(token => this.updateConfig(token.accessToken));
        this.userDeviceService.onUserDeviceIdUpdate().subscribe(userDeviceId => this.updateConfig(null, userDeviceId));
    }

    ngOnDestroy(): void {
        this.reCaptchaSubscription.unsubscribe();
        this.reCaptchaSubscription = new Subscription();
    }

    private updateConfig(token?: string, userDeviceId?: string): void {
        const loggerConfig: INGXLoggerConfig = {
            serverLoggingUrl: `${FirebaseService.getApiBasePath()}/api/global/logs`,
            level: environment.logLevel,
            serverLogLevel: environment.serverLogLevel,
            disableConsoleLogging: false,
        };
        this.logger.updateConfig(loggerConfig);

        if (isNullOrUndefined(token)) {
            token = this.sharedDataService.getSharedData<string>(AppConstants.ACCESS_TOKEN);
        }
        if (isNullOrUndefined(userDeviceId)) {
            userDeviceId = this.deviceService.getDeviceId();
        }

        loggerConfig.customHttpHeaders = LoggerService.getHeaders(token, userDeviceId);
        this.logger.updateConfig(loggerConfig);

        const customLoggerConfig: INGXLoggerConfig = {
            level: this.logger.getConfigSnapshot().level,
            serverLogLevel: NgxLoggerLevel.OFF
        };
        this.clientSideLogger.updateConfig(customLoggerConfig);
    }


    trace(message: any, ...additional: any[]): void {
        this.doLog(logger => logger.trace(message, ...additional), NgxLoggerLevel.TRACE);
    }

    debug(message: any, ...additional: any[]): void {
        this.doLog(logger => logger.debug(message, ...additional), NgxLoggerLevel.DEBUG);
    }

    info(message: any, ...additional: any[]): void {
        this.doLog(logger => logger.info(message, ...additional), NgxLoggerLevel.INFO);
    }

    log(message: any, ...additional: any[]): void {
        this.doLog(logger => logger.log(message, ...additional), NgxLoggerLevel.LOG);
    }

    warn(message: any, ...additional: any[]): void {
        this.doLog(logger => logger.warn(message, ...additional), NgxLoggerLevel.WARN);
    }

    error(message: any, ...additional: any[]): void {
        this.doLog(logger => logger.error(message, ...additional), NgxLoggerLevel.ERROR);
    }

    fatal(message: any, ...additional: any[]): void {
        this.doLog(logger => logger.fatal(message, ...additional), NgxLoggerLevel.FATAL);
    }

    private doLog(logAction: Consumer<NGXLogger>, level: NgxLoggerLevel): void {
        if (this.isEnabledForServerLogging(level)) {
            this.authorizationService.isNotExpiredToken()
                ? logAction(this.logger)
                : this.doLogWithReCaptchaValidation(logAction);
        } else {
            logAction(this.logger);
        }
    }

    private doLogWithReCaptchaValidation(logAction: Consumer<NGXLogger>): void {
        const reCaptchaSubscription = this.reCaptchaService.executeV3Action(LoggerService.LOGGING_RECAPTCHA_ACTION)
            .subscribe(
                token => hasStringValues(token)
                    ? this.doLogWithReCaptchaToken(logAction, token)
                    : logAction(this.clientSideLogger),
                error => {
                    logAction(this.logger);
                    this.logger.error(error);
                });
        this.reCaptchaSubscription.add(reCaptchaSubscription);
    }

    private doLogWithReCaptchaToken(logAction: Consumer<NGXLogger>, token: string): void {
        try {
            this.logger.setCustomHttpHeaders(this.loggerHeaders.set(HttpConstants.RECAPTCHA_TOKEN_HEADER_KEY, token));
            logAction(this.logger);
        } finally {
            this.logger.setCustomHttpHeaders(this.loggerHeaders);
        }
    }

    private isEnabledForServerLogging(level: NgxLoggerLevel): boolean {
        const loggerConfig = this.logger.getConfigSnapshot();
        const serverLogLevel = isDefined(loggerConfig.serverLoggingUrl) && isDefined(loggerConfig.serverLogLevel)
            ? loggerConfig.serverLogLevel
            : NgxLoggerLevel.OFF;
        return serverLogLevel !== NgxLoggerLevel.OFF && level >= serverLogLevel;
    }
}
