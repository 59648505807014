import {Injectable} from '@angular/core';
import {CompanyService} from '../company/company.service';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CompanyInitialsService {

    companyInitialsSubject = new BehaviorSubject<string>('');

    constructor(private readonly companyService: CompanyService) {
        this.initCompanyInitials();
        this.watchCompanyInitialsChange();
    }

    private initCompanyInitials(): void {
        this.companyInitialsSubject.next(this.getInitials(CompanyService.getCurrentCompany().name));
    }

    private watchCompanyInitialsChange(): void {
        this.companyService.onCompanyChange()
            .subscribe(company => this.companyInitialsSubject.next(this.getInitials(company.name)));
    }

    private getInitials(companyName: string): string {
        const name = companyName.split(' ');
        let initials = '';
        name.forEach(value => initials += value.charAt(0));
        return initials;
    }
}
