import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {PrincipalService} from '../../../services/principal/principal.service';
import {isDefined} from '../../utils';
import {AuthorityRoles} from '../../../domain/authority-roles';

@Component({
    selector: 'app-unauthorised-card',
    templateUrl: './unauthorised-card.component.html',
    styleUrls: ['./unauthorised-card.component.scss']
})
export class UnauthorisedCardComponent implements OnInit, OnChanges {

    showMessage = false;

    @Input() authorities: AuthorityRoles[];

    constructor(private principalService: PrincipalService) {
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (isDefined(this.authorities)) {
            this.principalService
                .hasAnyAuthority(this.authorities)
                .subscribe(hasAuthority => this.showMessage = !hasAuthority);
        } else {
            this.showMessage = false;
        }
    }
}
