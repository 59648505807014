import {ElementRef} from '@angular/core';
import {Point} from 'chart.js';
import Color from 'color-converter/dist/color-converter';

export class ChartUtil {

    public static createChartGradient(chart: ElementRef, y1: number): any {
        const style = getComputedStyle(document.body);
        const chartColor = style.getPropertyValue('--chart-primary');
        const context = chart.nativeElement.getContext('2d');
        const gradient = context.createLinearGradient(0, 0, 0, y1);
        gradient.addColorStop(0, chartColor);
        gradient.addColorStop(1, 'rgba(255,255,255,0.2)');
        return gradient;
    }

    public static createDoughnutChartGradient(chart: ElementRef,
                                              color: Color,
                                              quarter: ChartQuarter,
                                              axisCoordinate: number,
                                              darken: number): any {
        const context = chart.nativeElement.getContext('2d');
        let chartGradient: CanvasGradient;
        switch (quarter) {
            case ChartQuarter.TOP_LEFT:
                chartGradient = context.createLinearGradient(0, axisCoordinate, axisCoordinate, 0);
                break;
            case ChartQuarter.TOP_RIGHT:
                chartGradient = context.createLinearGradient(0, 0, axisCoordinate, axisCoordinate);
                break;
            case ChartQuarter.BOTTOM_LEFT:
                chartGradient = context.createLinearGradient(axisCoordinate, axisCoordinate, 0, 0);

                break;
            case ChartQuarter.BOTTOM_RIGHT:
                chartGradient = context.createLinearGradient(axisCoordinate, 0, 0, axisCoordinate);
                break;
            default:
                chartGradient = context.createLinearGradient(0, axisCoordinate, axisCoordinate, 0);

        }
        chartGradient.addColorStop(0, color.toString());
        chartGradient.addColorStop(1, color.darken(darken).toString());
        return chartGradient;
    }

    public static getPointAtDistanceFromAnother(p0: Point, p1: Point, offset: number): Point {
        const d = Math.sqrt(Math.pow(p1.x - p0.x, 2) + Math.pow(p1.y - p0.y, 2));
        const dt = d + offset;
        const t = dt / d;
        const xt = (1 - t) * p0.x + t * p1.x;
        const yt = (1 - t) * p0.y + t * p1.y;
        return {x: xt, y: yt};
    }

    public static getChartQuarter(p0: Point, p1: Point): ChartQuarter {
        if (p0.x >= p1.x && p0.y >= p1.y) {
            return ChartQuarter.TOP_LEFT;
        }
        if (p0.x < p1.x && p0.y >= p1.y) {
            return ChartQuarter.TOP_RIGHT;
        }
        if (p0.x >= p1.x && p0.y < p1.y) {
            return ChartQuarter.BOTTOM_LEFT;
        }
        if (p0.x < p1.x && p0.y < p1.y) {
            return ChartQuarter.BOTTOM_RIGHT;
        }
    }

    public static labelCoordinateCorrection(labelCoordinate: Point, quarter: ChartQuarter, textWidth: number): Point {
        const correctedLabelCoordinate = {x: labelCoordinate.x, y: labelCoordinate.y};
        switch (quarter) {
            case ChartQuarter.TOP_LEFT:
                correctedLabelCoordinate.x = labelCoordinate.x - (textWidth + 25);
                correctedLabelCoordinate.y = labelCoordinate.y - 10;
                break;
            case ChartQuarter.TOP_RIGHT:
                correctedLabelCoordinate.x = labelCoordinate.x - 15;
                correctedLabelCoordinate.y = labelCoordinate.y - 10;
                break;
            case ChartQuarter.BOTTOM_LEFT:
                correctedLabelCoordinate.x = labelCoordinate.x - (textWidth + 25);
                correctedLabelCoordinate.y = labelCoordinate.y - 10;

                break;
            case ChartQuarter.BOTTOM_RIGHT:
                correctedLabelCoordinate.x = labelCoordinate.x - 15;
                correctedLabelCoordinate.y = labelCoordinate.y - 10;

                break;
            default:
        }

        return correctedLabelCoordinate;
    }
}

export enum ChartQuarter {
    TOP_LEFT = 'TOP_LEFT',
    TOP_RIGHT = 'TOP_RIGHT',
    BOTTOM_LEFT = 'BOTTOM_LEFT',
    BOTTOM_RIGHT = 'BOTTOM_RIGHT'
}
