import {Pipe, PipeTransform} from '@angular/core';


@Pipe({
    name: 'capitalizeFirstLetter',
})
export class CapitalizeFirstLetterPipe implements PipeTransform {

    transform(text: string) {
        let next = text.slice(1);
        next = next.toLowerCase();
        return text.charAt(0).toUpperCase() + next;
    }
}
