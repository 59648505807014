import {Position} from './position';
import {CurrencyAmountOverview} from './currency-amount-overview';
import {TimePeriod} from './time-period';

export class PositionOverview {
    symbol: string;
    tradesCount: number;
    tradesCountPercentage: number;
    totalAmount?: CurrencyAmountOverview;
    sideAmount?: CurrencyAmountOverview[];
    positions?: Position[];
    hedgeDatePeriod?: TimePeriod;
    tradingVolume: number;
    tradingVolumePercentage: number;
}
