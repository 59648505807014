import {Injectable} from '@angular/core';
import {PositionOverview} from '../../domain/position-overview';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {FirebaseService} from '../firebase/firebase.service';
import {HttpUtils} from '../../commons/http/http-utils';
import {HttpConstants} from '../../commons/http-constants';
import {Page} from '../../commons/page';
import {PositionOverviewFilter} from '../../domain/filter/position-overview-filter';
import {Pageable} from '../../commons/pageable';
import {InsightsViewType} from '../../domain/insights-view-type';
import {isDefined} from '../../commons/utils';
import {InsightsPeriod} from '../../domain/insights-period';
import {SettlementOverview} from '../../domain/settlement-overview';
import {SettlementOverviewFilter} from '../../domain/filter/settlement-overview-filter';

@Injectable({providedIn: 'root'})
export class InsightsService {
    constructor(private readonly http: HttpClient) {
    }

    getPositionsOverview(filter: PositionOverviewFilter,
                         pageable: Pageable,
                         viewType?: InsightsViewType,
                         insightsPeriod?: InsightsPeriod): Observable<Page<PositionOverview>> {
        const url = `${FirebaseService.getApiBasePath()}/api/positions/overviews`;
        const httpParams = HttpUtils.getDefinedParamsFromObject(filter);
        if (isDefined(viewType)) {
            httpParams.viewType = viewType;
        }
        if (isDefined(insightsPeriod)) {
            httpParams.insightsPeriod = insightsPeriod;
        }
        httpParams.size = HttpConstants.DEFAULT_PAGE_SIZE.toString();
        const options = HttpUtils.getRequestOptionsWithParameters(httpParams, pageable, true);
        return this.http.get<Page<PositionOverview>>(url, options);
    }

    getSettlementOverviews(settlementOverviewFilter: SettlementOverviewFilter,
                           pageable: Pageable,
                           insightsPeriod?: InsightsPeriod): Observable<SettlementOverview> {
        const url = `${FirebaseService.getApiBasePath()}/api/settlement/overviews`;

        return this.http.get<SettlementOverview>(
            url,
            HttpUtils.getRequestOptionsWithParameters(
                {...settlementOverviewFilter, insightsPeriod},
                pageable,
                true
            )
        );
    }
}
