import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {PlatformEnum} from '../../../domain/platform';
import {environment} from '../../../../environments/environment';
import {HttpUtils} from '../http-utils';
import {FirebaseService} from '../../../services/firebase/firebase.service';

@Injectable()
export class AppVersionInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const isResourceRequest = HttpUtils.isLocalResourceRequest(req);
        const isTokenRequest = req.url.indexOf(FirebaseService.getIssuer()) >= 0;
        if (!isResourceRequest && !isTokenRequest && environment.platform !== PlatformEnum.WEB) {
            const updatedReq = req.clone({
                setHeaders: {
                    'Mobile-Application-Version': environment.apiVersion,
                    'Platform': environment.platform
                }
            });
            return next.handle(updatedReq);
        }
        return next.handle(req);
    }
}
