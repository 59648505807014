import {Injectable} from '@angular/core';
import {File} from '@awesome-cordova-plugins/file/ngx'
import {Observable, from} from 'rxjs';

@Injectable({providedIn: 'root'})
export class FileService {

    readonly dataDirectoryPath = this.file.dataDirectory;

    constructor(private readonly file: File) {
    }

    saveFile(fileName: string, file: Blob): Observable<void> {
        return from(this.file.writeFile(this.dataDirectoryPath, fileName, file, {replace: true}));
    }
}