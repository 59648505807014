<div>
    <ion-grid class="ion-no-margin ion-no-padding">
        <ion-row class="padding-small">
            <ion-col class="ion-text-start" size="12">
                <ion-icon class="icon-back" name="chevron-back" (click)="goBack()"/>
            </ion-col>
        </ion-row>
        <ion-row class="padding-small">
            <ion-col size="10" class="title-container">
                <div class="currency-item">{{symbol}}</div>
            </ion-col>
            <ion-col size="2" class="ion-text-end">
                <ion-icon src="assets/icon/icon_filter.svg"
                          class="icon-filter"
                          (click)="openFilters()"/>
            </ion-col>
        </ion-row>
        <ng-container *ngrxLet="pageData$; let pageData; suspenseTpl: loading">
            <ion-row>
                <app-tenor-bar class="tenor-bar"
                               [availableTenors]="availableTenors"
                               [activeTenor]="activeTenor"
                               (changeActiveTenor)="onTenorSelect($event)"/>
            </ion-row>
            <app-settlement-overview-bar-chart *ngIf="!noCurrencyData else emptyChart"
                                               [symbol]="symbol"
                                               [dateOverviews]="pageData[0].dateOverviews"
                                               [activeTenor]="activeTenor"/>
            <ion-row *ngIf="!noCurrencyData">
                <ion-col>
                    <app-settlement-overview-details-list [settlementOverview]="pageData[0]"
                                                          [symbol]="symbol"
                                                          [activeTenor]="activeTenor"
                                                          [settlementOverviewFilter]="settlementOverviewFilter"/>
                </ion-col>
            </ion-row>
        </ng-container>
    </ion-grid>
</div>
<ng-template #loading>
    <ion-spinner name="crescent"/>
</ng-template>
<ng-template #emptyChart>
    <ion-row>
        <ion-col size="12">
            <div class="empty-chart-wrap">
                <ion-icon src="assets/icon/empty_chart.svg" class="empty_chart"/>
            </div>
        </ion-col>
    </ion-row>
    <ion-row>
        <ion-col size="12" class="text-light ion-text-center">
            <div>{{'insights.no-trades-shown' | translate}}</div>
        </ion-col>
    </ion-row>
</ng-template>
