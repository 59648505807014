import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    ViewChild
} from '@angular/core';

@Component({
    selector: 'app-scrollable-container',
    templateUrl: './scrollable-container.component.html',
    styleUrls: ['./scrollable-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScrollableContainerComponent implements AfterViewInit {

    canScrollLeft = false;
    canScrollRight = false;

    @ViewChild('container') container: ElementRef;

    constructor(private readonly changeDetector: ChangeDetectorRef) {
    }

    ngAfterViewInit(): void {
        setTimeout(() => this.onScrollChange(), 100);
    }

    public onScrollChange(): void {
        const {scrollLeft, scrollWidth, clientWidth} = this.container.nativeElement;

        this.canScrollLeft = scrollLeft > 1;
        this.canScrollRight = scrollWidth - (clientWidth + scrollLeft) > 1;
        this.changeDetector.detectChanges();
    }
}
