import {ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {PositionOverview} from '../../../../domain/position-overview';
import {CurrencyAmount} from '../../../../domain/currency-amount';
import {TradingRatePipe} from '../../../../pipes/rate/trading-rate';
import {InsightsViewType} from '../../../../domain/insights-view-type';
import {DecimalAmountPipe} from '../../../../pipes/decimal-input/decimal-input.pipe';

@Component({
    selector: 'app-bar-chart-total-card',
    templateUrl: './bar-chart-total-card.component.html',
    styleUrls: ['./bar-chart-total-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BarChartTotalCardComponent implements OnInit, OnChanges {

    readonly TEN_MILLION = 10000000;
    readonly Math = Math;
    readonly TradingRatePipe = TradingRatePipe;
    readonly DecimalAmountPipe = DecimalAmountPipe;
    tradingCurrency: CurrencyAmount;
    contraCurrency: CurrencyAmount;
    isNetPosition: boolean;

    @Input() positionOverview: PositionOverview;
    @Input() viewType: InsightsViewType;

    constructor() {
    }

    ngOnInit(): void {
        this.isNetPosition = this.viewType === InsightsViewType.NET_AMOUNTS;
        [this.tradingCurrency, this.contraCurrency] = this.positionOverview.totalAmount.currencyAmounts;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.isNetPosition = this.viewType === InsightsViewType.NET_AMOUNTS;
        [this.tradingCurrency, this.contraCurrency] = this.positionOverview.totalAmount.currencyAmounts;
    }
}
