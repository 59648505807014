import {Pipe, PipeTransform} from '@angular/core';
import {Side} from '../../domain/side';

@Pipe({
    name: 'reversedSide',
})
export class ReversedSidePipe implements PipeTransform {

    transform(value: Side): Side {
        if (value === Side.BUY) {
            return Side.SELL;
        } else {
            return Side.BUY;
        }
    }
}
