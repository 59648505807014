<section>
    <ng-container *ngIf="afterDraw$ | async">
        <app-doughnut-chart-label *ngIf="isNullOrUndefined(selectedSegmentIndex) && isDefined(labelCoordinates[0])"
                                  class="position-absolute"
                                  [label]="positionsOverview[0].symbol"
                                  [labelColor]="colors[0]"
                                  [percentage]="volume
                                             ? positionsOverview[0]?.tradingVolumePercentage
                                             : positionsOverview[0]?.tradesCountPercentage"
                                  [style]="'left:' + labelCoordinates[0].x + 'px; top:' + labelCoordinates[0].y + 'px;'"
                                  (labelClick)="onLabelClicked($event)">
        </app-doughnut-chart-label>
        <app-doughnut-chart-label *ngIf="isNullOrUndefined(selectedSegmentIndex) && isDefined(labelCoordinates[1])"
                                  class="position-absolute"
                                  [label]="positionsOverview[1].symbol"
                                  [labelColor]="colors[1]"
                                  [percentage]="volume
                                             ? positionsOverview[1]?.tradingVolumePercentage
                                             : positionsOverview[1]?.tradesCountPercentage"
                                  [style]="'left:' + labelCoordinates[1].x + 'px; top:' + labelCoordinates[1].y + 'px;'"
                                  (labelClick)="onLabelClicked($event)">
        </app-doughnut-chart-label>
        <app-doughnut-chart-label *ngIf="isNullOrUndefined(selectedSegmentIndex) && isDefined(labelCoordinates[2])"
                                  class="position-absolute"
                                  [label]="positionsOverview[2].symbol"
                                  [labelColor]="colors[2]"
                                  [percentage]="volume
                                             ? positionsOverview[2]?.tradingVolumePercentage
                                             : positionsOverview[2]?.tradesCountPercentage"
                                  [style]="'left:' + labelCoordinates[2].x + 'px; top:' + labelCoordinates[2].y + 'px;'"
                                  (labelClick)="onLabelClicked($event)">
        </app-doughnut-chart-label>
        <app-doughnut-chart-label *ngIf="isNullOrUndefined(selectedSegmentIndex) && isDefined(labelCoordinates[3])"
                                  class="position-absolute"
                                  [label]="positionsOverview[3].symbol"
                                  [labelColor]="colors[3]"
                                  [percentage]="volume
                                             ? positionsOverview[3]?.tradingVolumePercentage
                                             : positionsOverview[3]?.tradesCountPercentage"
                                  [style]="'left:' + labelCoordinates[3].x + 'px; top:' + labelCoordinates[3].y + 'px;'"
                                  (labelClick)="onLabelClicked($event)">
        </app-doughnut-chart-label>
        <app-doughnut-chart-label *ngIf="isNullOrUndefined(selectedSegmentIndex) && isDefined(labelCoordinates[4])"
                                  class="position-absolute"
                                  [label]="positionsOverview[4].symbol"
                                  [labelColor]="colors[4]"
                                  [percentage]="volume
                                             ? positionsOverview[4]?.tradingVolumePercentage
                                             : positionsOverview[4]?.tradesCountPercentage"
                                  [style]="'left:' + labelCoordinates[4].x + 'px; top:' + labelCoordinates[4].y + 'px;'"
                                  (labelClick)="onLabelClicked($event)">
        </app-doughnut-chart-label>
    </ng-container>
    <ng-container *ngIf="baseCcy$ | async as baseCcy">
        <div class="body-text-container position-absolute">
        <div class="body-text">
            <div *ngIf="isDefined(selectedSegmentIndex)" class="symbol">
                {{positionsOverview[Math.min(selectedSegmentIndex, 4)].symbol}}
            </div>
            <div *ngIf="!isDefined(selectedSegmentIndex) && volume" class="symbol">
                {{'insights.total' | translate}}
            </div>
            <div *ngIf="isDefined(selectedSegmentIndex); else noSelectedSegment">
                <div *ngIf="volume" class="total-value">
                    <span class="main">
                        {{positionsOverview[Math.min(selectedSegmentIndex, 4)].tradingVolume | thousandSuffixes}}
                    </span>
                    <span>{{baseCcy}}</span>
                </div>
                <div *ngIf="!volume" class="total-value">
                    <span class="main">
                        {{positionsOverview[Math.min(selectedSegmentIndex, 4)].tradesCount}}
                    </span>
                    <span>{{'insights.labels.trades' | translate }}</span>
                </div>
                <div class="percentage">
                    <span *ngIf="volume">
                        {{positionsOverview[Math.min(selectedSegmentIndex, 4)].tradingVolumePercentage | percentage }}
                    </span>
                    <span *ngIf="!volume">
                        {{positionsOverview[Math.min(selectedSegmentIndex, 4)].tradesCountPercentage | percentage }}
                    </span>
                </div>
                </div>
            </div>
        </div>
        <ng-template #noSelectedSegment>
            <div *ngIf="volume" class="total-value">
                <span class="main">{{totalVolume | thousandSuffixes}}</span>
                <span>{{baseCcy}}</span>
            </div>
            <div *ngIf="!volume" class="total-value nr-of-trades">
                <span class="main">{{totalNumberOfTrades | number}}</span>
                <span>{{'insights.labels.trades' | translate }}</span>
            </div>
        </ng-template>
    </ng-container>
    <canvas #doughnutChartCanvas class="doughnut-chart"></canvas>
</section>
