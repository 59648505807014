<app-header *ngIf="backButton"
            [pageTitle]="'components.back-button' | translate"
            [mainPage]="false"
            [companySelectorDisabled]="false"
            [noInternetPage]="noInternetPage"
            (goBack)="goToPreviousPage()">
</app-header>
<ion-content class="ion-padding">
    <app-page-title [pageTitle]="title | translate: appName"></app-page-title>
    <div class="body ion-margin-top">
        <p>{{body | translate}}</p>
        <p *ngIf="storeLink"
           class="link"
           (click)="openUrl()">
            {{storeLink}}
        </p>
    </div>
    <ion-button *ngIf="termsAndConditionsModal"
                class="main-btn ion-padding-vertical"
                (click)="unregister()">
        {{'read-mode.unregister' | translate}}
    </ion-button>
</ion-content>
