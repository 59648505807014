import {Pipe} from '@angular/core';
import {BaseRatePipe} from './base-rate';
import {isNullOrUndefined} from '../../commons/utils';
import {DecimalAmountPipe} from '../decimal-input/decimal-input.pipe';

@Pipe({
    name: 'detailsRate'
})
export class DetailsRatePipe extends BaseRatePipe {
    private static readonly TWO_RATE_FORMAT = '1.2-2';
    private static readonly FOUR_RATE_FORMAT = '1.4-4';

    constructor(decimalAmountPipe: DecimalAmountPipe) {
        super(decimalAmountPipe);
    }

    transform(value: number): string {
        if (isNullOrUndefined(value)) {
            return '';
        }
        return super.transform(
            value,
            value > 99 ? DetailsRatePipe.TWO_RATE_FORMAT : DetailsRatePipe.FOUR_RATE_FORMAT
        );
    }
}
