import {Injectable} from '@angular/core';
import {HedgingProfileService} from '../hedging-profile/hedging-profile.service';
import {CompanyService} from '../company/company.service';
import {CurrenciesService} from '../currencies/currencies.service';
import {forkJoin, Observable} from 'rxjs';
import {PrincipalService} from '../principal/principal.service';
import {ConfigurationService} from '../configuration/configuration.service';
import {UserUtils} from '../../utils/user-utils';

@Injectable({
    providedIn: 'root'
})
export class CacheService {

    constructor(private principal: PrincipalService,
                private currenciesProvider: CurrenciesService,
                private hedgingProfileProvider: HedgingProfileService,
                private companyProvider: CompanyService,
                private configurationService: ConfigurationService) {
    }

    cacheData(): Observable<void> {
        return new Observable(observer => {
            this.principal.identity().subscribe(user => {
                if (user) {
                    const defaultTenant = UserUtils.getDefaultTenant(user);
                    this.companyProvider.getRootCompany(defaultTenant).subscribe(company => {
                            if (!company.termsOfUseRequested) {
                                forkJoin([
                                    this.companyProvider.getCompanies(),
                                    this.currenciesProvider.getTradingCurrencies(),
                                    this.currenciesProvider.getContraCurrencies(),
                                    this.hedgingProfileProvider.getHedgingProfile(),
                                    this.configurationService.getForwardPointsDecimals()
                                ]).subscribe(() => {
                                    observer.next();
                                    observer.complete();
                                });
                            } else {
                                observer.next();
                                observer.complete();
                            }
                        },
                        () => {
                            observer.next();
                            observer.complete();
                        });
                } else {
                    observer.next();
                    observer.complete();
                }
            });
        });
    }

    clearCache(): void {
        this.currenciesProvider.clearCache();
        this.hedgingProfileProvider.clearCache();
        this.companyProvider.clearCache();
    }

    clearCurrenciesCache(): void {
        this.currenciesProvider.clearCache();
    }
}
