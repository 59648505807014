export class AppConstants {
    public static readonly ACCESS_TOKEN = 'accessToken';
    public static readonly SECURED_TOKEN = 'SECURED_TOKEN';
    public static readonly REGISTERED_BIOMETRIC = 'REGISTERED_BIOMETRIC';
    public static readonly TOKEN_RESPONSE = 'token_response';
    public static readonly DELETE = 'delete';
    public static readonly EMPTY_STRING = '';
    public static readonly USER_PIN_CODE = 'userPinCode';
    public static readonly ACTIVE_ENVIRONMENT = 'active_environment';
    public static readonly DUE_DATE_2 = 'dueDate2';
    public static readonly CORRECTED_DUE_DATE_2 = 'correctedDueDate2';
    public static readonly TENOR_2 = 'tenor2';
    public static readonly NEXT_ROUTE = 'nextRoute';
    public static readonly CACHED_COMPANY = 'cachedCompany';
    public static readonly CACHED_BANK_ENTITY = 'cachedBankEntity';
    public static readonly CACHED_USER_ID = 'cachedUserId';
    public static readonly CACHED_USER_LANG = 'cachedUserLang';
    public static readonly CACHED_APPLICATION_SETTINGS = 'cachedApplicationSettings';
    public static readonly UNAUTHENTICATED_MARKET_RATES = 'market-rates/';
    public static readonly CUSTOM_CREDENTIALS = 'custom-credentials';
    public static readonly CSAT = 'CSAT';
    public static readonly CES = 'CES';
    public static readonly PLATFORM = 'platform';
    public static readonly PROFILE_PICTURE_SIZE = {width: 96, height: 96};
    public static readonly TERMS_AND_CONDITIONS_NOT_ACCEPTED = 'TERMS_AND_CONDITIONS_NOT_ACCEPTED';
    public static readonly GLOBALIZATION = 'globalization';
    public static readonly BACKSPACE = 'Backspace';
    public static readonly UNIDENTIFIED = 'Unidentified';
    public static readonly API_BASE_PATH = 'apiBasePath';
    public static readonly WEB_SOCKET_PATH = 'webSocketPath';
    public static readonly IAM_ISSUER_URL = 'iamIssuerURL';
    public static readonly APP_LINK = 'appLink';
    public static readonly EXCLUDE_GLOBAL_TENANT = 'excludeGlobalTenant';
    public static readonly AUTH_FLOWS = 'authFlows';
    public static readonly DEVICE_ID = 'deviceId';
    public static readonly DEFAULT_SECRET = '00000';
    public static readonly DISMISS_BUTTON = '-108';
    public static readonly BIOMETRIC_LOCKED_OUT = '-111';
    public static readonly FROM_PUSH_NOTIFICATION = 'from-push-notification';
    public static readonly DEVICE_FLOW_CONFIG = 'deviceFlowConfig';
    public static readonly DEVICE_CODE = 'deviceCode';
    public static readonly BACK_BTN_ROUTING_PATH = 'backRoutingPath';
    public static readonly INVALID_USER_INFO_RESPONSE = 'invalid_user_info_response';
    public static readonly HEDGED_ORDER_ID = 'hedgedOrderId';
    public static readonly CREATED_DATE = 'createdDate';
    public static readonly CNH = 'CNH';
    public static readonly CNY = 'CNY';
    public static readonly BOV = 'BOV';
    public static readonly BOB = 'BOB';
}
