import {BehaviorSubject, forkJoin, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {throwError} from 'rxjs/internal/observable/throwError';
import {catchError, filter, finalize, switchMap, take} from 'rxjs/operators';
import {NavController} from '@ionic/angular';
import {ToastService} from '../../../services/toast/toast.service';
import {TranslateService} from '@ngx-translate/core';
import {isDefined} from '../../utils';
import {ConfigurationService} from '../../../services/configuration/configuration.service';
import {AuthorizationService} from '../../../auth/authorization.service';
import {TokenResponse} from '@openid/appauth';
import {HttpConstants} from '../../http-constants';
import {AppConstants} from '../../app-constants';
import {AppPaths} from '../../app-paths';
import {SharedDataService} from '../../../services/shared-data/shared-data.service';

@Injectable()
export class AuthExpiredInterceptor implements HttpInterceptor {

    private isRefreshingToken = false;
    private tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(private authorizationService: AuthorizationService,
                private configurationService: ConfigurationService,
                private sharedDataService: SharedDataService,
                private navCtrl: NavController,
                private toast: ToastService,
                private translate: TranslateService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(catchError(error => {
                if (error instanceof HttpErrorResponse) {
                    const isTokenRequest = error.url.includes(AppPaths.TOKEN_ENDPOINT);
                    const isCustomCredentialsRequest = error.url.includes(AppConstants.CUSTOM_CREDENTIALS);
                    if (error.status === 401 && isCustomCredentialsRequest) {
                        return throwError(error);
                    }
                    if (error.status === 0 || (error.status === 401 && !isTokenRequest)) {
                        if (isDefined(error.error) && error.error.message === 'Your account is still blocked') {
                            forkJoin([
                                this.configurationService.getDefaultBankContactSettings(),
                                this.configurationService.getApplicationSettings()
                            ]).subscribe(([bankContactSettings, applicationSettings]) => {
                                this.toast.errorWithCancelButton(
                                    this.translate.instant('error-message.blocked-account', {
                                        supportNumber: bankContactSettings.phoneNumber,
                                        appName: applicationSettings.applicationName
                                    })
                                );
                                this.authorizationService.logout();
                            });
                        } else {
                            return this.handle401Error(req, next);
                        }
                    }
                }
                return throwError(error);
            }
        ));
    }

    handle401Error(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;

            this.tokenSubject.next(null);

            const pin = this.sharedDataService.getSharedData<string>(AppConstants.USER_PIN_CODE);
            return this.authorizationService.refreshToken(pin)
                .pipe(
                    switchMap((newToken: TokenResponse) => {
                        if (isDefined(newToken)) {
                            this.tokenSubject.next(newToken.accessToken);
                            return next.handle(this.addToken(req, newToken.accessToken));
                        } else {
                            this.authorizationService.logout();
                            return next.handle(req);
                        }
                    }),
                    catchError(() => {
                        this.authorizationService.logout();
                        return next.handle(req);
                    }),
                    finalize(() => this.isRefreshingToken = false)
                );
        } else {
            return this.tokenSubject
                .pipe(
                    filter(token => token != null),
                    take(1),
                    switchMap(token => next.handle(this.addToken(req, token)))
                );
        }
    }

    private addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
        if (req.url.includes(AppPaths.TOKEN_ENDPOINT)
            || req.url.includes(AppConstants.UNAUTHENTICATED_MARKET_RATES)) {
            return req;
        } else {
            return req.clone({
                setHeaders: {
                    Authorization: HttpConstants.BEARER + token
                }
            });
        }
    }
}
