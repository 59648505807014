import {Pipe, PipeTransform} from '@angular/core';
import {GlossaryService} from '../../../../services/glossary/glossary.service';

export const GLOSSARY_ITEM_CSS_CLASS = 'glossary';

@Pipe({
    name: 'highlightGlossary'
})
export class HighlightGlossaryPipe implements PipeTransform {

    constructor(private glossaryService: GlossaryService) {
    }

    transform(value: string): string {
        const chapterTitles = this.glossaryService.getGlossaryChapterTitles();
        const pattern = new RegExp(chapterTitles.reverse().join('|')
            .replace('(', '\\(')
            .replace(')', '\\)'), 'gi');
        return value
            .replace(pattern, match => `<a class=${GLOSSARY_ITEM_CSS_CLASS}>${match}</a>`);
    }
}
