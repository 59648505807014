import {Directive, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {ConfigurationService} from '../services/configuration/configuration.service';
import {noop} from 'rxjs';

@Directive({
    selector: '[appMidRateVisible]'
})
export class MidRateVisibleDirective implements OnInit {

    constructor(private readonly viewContainerRef: ViewContainerRef,
                private readonly templateRef: TemplateRef<any>,
                private readonly configurationService: ConfigurationService) {
    }

    ngOnInit(): void {
        this.configurationService.isMidRateVisible().subscribe(
            isMidRateVisible => {
                this.viewContainerRef.clear();
                isMidRateVisible
                    ? this.viewContainerRef.createEmbeddedView(this.templateRef, null, 0)
                    : noop();
            },
            () => this.viewContainerRef.clear()
        );
    }
}
