<div>
    <ion-grid class="ion-no-margin">
        <ion-row>
            <ion-col class="ion-text-start" size="12">
                <ion-icon class="icon-back" name="chevron-back" (click)="emitGoBackEvent()"/>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col size="10" class="title-container">
                <ng-container *ngIf="positionOverview$ | async as positionOverview">
                    <div class="currency-item">{{positionOverview?.symbol}}</div>
                    <div class="trades-item">
                        <span class="trades-number">{{positionOverview?.tradesCount}}</span>
                        <span>{{'insights.labels.trades' | translate}}</span>
                    </div>
                </ng-container>
            </ion-col>
            <ion-col size="2" class="ion-text-end">
                <ion-icon src="assets/icon/icon_filter.svg"
                          class="icon-filter"
                          (click)="openFilters()"/>
            </ion-col>
        </ion-row>
        <ion-row *ngIf="filterPeriods$ | async as filterPeriods">
            <ng-container *ngIf="isDefined(filterPeriods.settlementPeriod) && positionOverview?.tradesCount > 0">
                <app-tenor-bar class="tenor-bar"
                               [availableTenors]="availableTenors"
                               [activeTenor]="activeTenor"
                               (changeActiveTenor)="onTenorSelect($event)"/>
            </ng-container>
        </ion-row>
        <ion-row class="subtitle">
            <span *ngIf="netAmounts" class="subtitle">{{'insights.subtitle-net-amounts' | translate}}</span>
            <span *ngIf="totalAmounts" class="subtitle">{{'insights.subtitle-total-amounts' | translate}}</span>
        </ion-row>
        <div *ngIf="isChartEmpty" class="empty-chart-wrap">
            <ion-icon src="assets/icon/empty_chart.svg" class="empty_chart"/>
        </div>
        <ion-row>
            <div *ngIf="isChartEmpty" class="ion-text-center" style="margin: 50px auto">
                <ion-label class="no-trades">{{'insights.no-trades-shown' | translate }}</ion-label>
            </div>
            <ion-col size="12">
                <section class="chart-wrapper">
                    <div #barChart></div>
                </section>
            </ion-col>
        </ion-row>
    </ion-grid>
    <ng-container *ngIf="!isChartEmpty">
        <ng-container *ngIf="filterPeriods$ | async as filterPeriods">
            <app-insights-dates-label [backgroundGrey]="true"
                                      [positionOverviewFilter]="positionOverviewFilter"
                                      [filterPeriods]="filterPeriods"/>
        </ng-container>
        <ion-list *ngIf="isDefined(tradingCcy) && isDefined(contraCcy)" class="legend-list">
            <app-bar-chart-total-card [positionOverview]="positionOverview"
                                      [viewType]="viewType"/>
            <app-bar-chart-side-card [tradingCurrency]="tradingCurrencyBuy"
                                     [contraCurrency]="contraCurrencySell"
                                     [averageRate]="tradingCurrencyRate"
                                     [viewType]="viewType"/>
            <app-bar-chart-side-card [tradingCurrency]="tradingCurrencySell"
                                     [contraCurrency]="contraCurrencyBuy"
                                     [averageRate]="contraCurrencyRate"
                                     [viewType]="viewType"/>
        </ion-list>
    </ng-container>
</div>
