import {Injectable} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {ModalOptions} from '@ionic/core';
import {isDefined} from '../../commons/utils';
import {noop} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ModalService {

    private isModalVisible = false;
    private modalObject: HTMLIonModalElement;

    constructor(private modalCtrl: ModalController) {
    }

    async showModal(options: ModalOptions): Promise<void> {
        if (!this.isModalVisible) {
            this.isModalVisible = true;
            this.modalObject = await this.modalCtrl.create(options);
            this.modalObject.onDidDismiss().then(() => this.isModalVisible = false);
            await this.modalObject.present();
        }
    }

    closeModal(): void {
        if (this.isModalVisible) {
            if (this.modalObject) {
                this.modalObject.dismiss(true);
                this.modalObject = null;
            }
        }
    }

    dismissModal(): void {
        this.modalCtrl.getTop().then(elem => {
            isDefined(elem) ? this.modalCtrl.dismiss().then(() => this.dismissModal()) : noop();
        });
    }
}
