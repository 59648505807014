<ion-row>
    <p class="description">
        {{'configure-pin-code.description' | translate}}
    </p>
</ion-row>
<div class="pin-form-title">
    <span>{{'configure-pin-code.type-pin' | translate}}</span>
</div>
<div [class.inactive-form]="pin.length>4">
    <div class="pin-form">
        <ion-icon [name]="pin.length>0 ? 'radio-button-on' : 'radio-button-off'"></ion-icon>
        <ion-icon [name]="pin.length>1 ? 'radio-button-on' : 'radio-button-off'"></ion-icon>
        <ion-icon [name]="pin.length>2 ? 'radio-button-on' : 'radio-button-off'"></ion-icon>
        <ion-icon [name]="pin.length>3 ? 'radio-button-on' : 'radio-button-off'"></ion-icon>
        <ion-icon [name]="pin.length>4 ? 'radio-button-on' : 'radio-button-off'"></ion-icon>
    </div>
</div>
<div [class.inactive-form]="pin.length<5">
    <div class="pin-form-title">
        <span>{{'configure-pin-code.confirm-pin' | translate}}</span>
    </div>
    <div class="pin-form">
        <ion-icon [name]="confirmPin.length>0 ? 'radio-button-on' : 'radio-button-off'"></ion-icon>
        <ion-icon [name]="confirmPin.length>1 ? 'radio-button-on' : 'radio-button-off'"></ion-icon>
        <ion-icon [name]="confirmPin.length>2 ? 'radio-button-on' : 'radio-button-off'"></ion-icon>
        <ion-icon [name]="confirmPin.length>3 ? 'radio-button-on' : 'radio-button-off'"></ion-icon>
        <ion-icon [name]="confirmPin.length>4 ? 'radio-button-on' : 'radio-button-off'"></ion-icon>
    </div>
</div>
<app-keyboard (keyEmitter)="handleInput($event)"></app-keyboard>
