import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import * as Highcharts from 'highcharts';
import {Chart, HTMLDOMElement} from 'highcharts';
import HC_more from 'highcharts/highcharts-more';
import {hasValues, isDefined} from 'src/app/commons/utils';
import {CurrencyOverview} from '../../../../domain/currency-overview';
import {ChartHelper} from '../../../../utils/chart-helper';

HC_more(Highcharts);


@Component({
    selector: 'app-bubble-chart',
    templateUrl: './bubble-chart.component.html',
    styleUrls: ['./bubble-chart.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BubbleChartComponent implements OnInit, AfterViewInit, OnChanges {

    public readonly isDefined = isDefined;

    private chart: Chart;
    private positiveCurrencies: CurrencyOverview[];
    private negativeCurrencies: CurrencyOverview[];

    @Input() currencyOverviews: CurrencyOverview[];
    @Input() selectedCcy: string;
    @Input() baseCurrency: string;
    @Output() selectedCcyChange = new EventEmitter<string>();
    @ViewChild('chart', {static: true}) private readonly chartEl: ElementRef;

    constructor(private readonly chartHelper: ChartHelper) {
    }

    ngOnInit(): void {
        this.getSeriesData();
    }

    private getSeriesData(): void {
        this.positiveCurrencies = this.getChartSeriesData(true);
        this.negativeCurrencies = this.getChartSeriesData(false);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (isDefined(changes.selectedCcy?.currentValue)) {
            const positiveCurrency = this.currencyOverviews
                .find(settlement => settlement.currency === this.selectedCcy)
                .totalNetAmount > 0;

            if (positiveCurrency) {
                const currencyIndex = this.positiveCurrencies
                    .map(settlement => settlement.currency).indexOf(this.selectedCcy);
                if (isDefined(currencyIndex)) {
                    const point = this.chart.series[0].points[currencyIndex];
                    point.select(true);
                    this.chart.tooltip.refresh(point);
                }
            } else {
                const currencyIndex = this.negativeCurrencies
                    .map(settlement => settlement.currency).indexOf(this.selectedCcy);
                if (isDefined(currencyIndex)) {
                    const point = this.chart.series[1].points[currencyIndex];
                    point.select(true);
                    this.chart.tooltip.refresh(point);
                }
            }
        } else {
            if (hasValues(this.positiveCurrencies)) {
                const seriesPositive = this.chart.series[0];
                seriesPositive.points.forEach(point => point.select(false));
            }
            if (hasValues(this.negativeCurrencies)) {
                const negativeSeries = this.chart.series[1];
                negativeSeries.points.forEach(point => point.select(false));
            }
        }

        if (isDefined(changes.baseCurrency?.currentValue) || isDefined(changes.currencyOverviews?.currentValue)) {
            this.getSeriesData();
            this.createChart();
        }
    }

    private createChart(): void {
        const element: HTMLDOMElement = this.chartEl.nativeElement;
        this.chart = Highcharts.chart(
            element,
            ChartHelper.getBubbleChartOptions(
                this.positiveCurrencies,
                this.negativeCurrencies,
                this.selectBubble.bind(this),
                this.unselectBubble.bind(this),
                ChartHelper
                    .getBubbleChartTooltip('bubble-tooltip', this.chartHelper.getSettlementOverviewTooltipFormatter(
                        this.currencyOverviews,
                        this.baseCurrency
                    ))
            )
        );
    }

    ngAfterViewInit(): void {
        this.createChart();
    }

    selectBubble(): void {
        this.selectedCcy = this.chart.getSelectedPoints()[0].name;
        this.selectedCcyChange.emit(this.selectedCcy);
    }

    unselectBubble(): void {
        this.selectedCcy = this.chart.getSelectedPoints()[0]?.name || null;
        this.selectedCcyChange.emit(this.selectedCcy);

        if (this.selectedCcy === null) {
            this.chart.tooltip.hide();
        }
    }

    private getChartSeriesData(positive: boolean): CurrencyOverview[] {
        return this.currencyOverviews.filter(position => position.totalNetAmount > 0 === positive);
    }
}
