import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-doughnut-chart-label',
    templateUrl: './doughnut-chart-label.component.html',
    styleUrls: ['./doughnut-chart-label.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DoughnutChartLabelComponent implements OnInit {

    @Input() label: string;
    @Input() labelColor: string;
    @Input() percentage: number;
    @Output() labelClick = new EventEmitter<string>();

    constructor() {
    }

    ngOnInit(): void {
    }

    onLabelClicked(): void {
        this.labelClick.emit(this.label);
    }
}
