<div class="card ion-no-padding ion-no-margin">
    <ion-row class="ion-no-padding ion-no-margin">
        <ion-col size="1" class="ion-no-padding ion-align-items-end legend">
            <div *ngIf="!isNetPosition"
                 class="legend-color"
                 [class]="tradingCcyBuySide ? 'trading-ccy-buy-color' : 'trading-ccy-sell-color'">
            </div>
        </ion-col>
        <ion-col size="3" class="trading-currency ion-no-padding">
            <ion-row class="trading-ccy-side">
                {{tradingCurrency?.currency}} {{'insights.side.' + tradingCurrency?.side | translate}}
            </ion-row>
            <ion-row class="trading-ccy-value">{{tradingCurrency?.amount | decimalAmount:DecimalAmountPipe.AMOUNT_FORMAT_0_DIGITS}}</ion-row>
        </ion-col>
        <ion-col size="1" class="ion-no-padding ion-text-end">
            <div *ngIf="!isNetPosition"
                 class="legend-color"
                 [class]="contraCcyBuySide ? 'contra-ccy-sell-color' : 'contra-ccy-buy-color'">
            </div>
        </ion-col>
        <ion-col size="3" class="contra-currency ion-no-padding">
            <ion-row class="contra-ccy-side">
                {{contraCurrency?.currency}} {{'insights.side.' + contraCurrency?.side | translate}}
            </ion-row>
            <ion-row class="contra-ccy-value">{{contraCurrency?.amount | decimalAmount:DecimalAmountPipe.AMOUNT_FORMAT_0_DIGITS}}</ion-row>
        </ion-col>
        <ion-col size="4" class="rate vertical-line">
            <ion-row class="rate-label">{{'insights.labels.average-rate' | translate}}</ion-row>
            <ion-row class="rate-value">{{averageRate | tradingRate: TradingRatePipe.FOUR_RATE_FORMAT}}</ion-row>
        </ion-col>
    </ion-row>
</div>
