import {Pipe, PipeTransform} from '@angular/core';
import {Dictionary} from '../../commons/dictionary';

@Pipe({
    name: 'keys'
})
export class KeysPipe implements PipeTransform {

    transform<T>(map: Dictionary<T>): string[] {
        return Object.keys(map);
    }
}
