import {Component, NgZone} from '@angular/core';
import {Platform} from '@ionic/angular';
import {StatusBar} from '@awesome-cordova-plugins/status-bar/ngx';
import {LoadingSpinnerService} from './services/loading-service/loading-spinner.service';
import {OnPlatformEvents} from './utils/on-platform-events';
import {MobileAccessibility} from '@awesome-cordova-plugins/mobile-accessibility/ngx';
import {AppLanguageService} from './services/app-language/app-language.service';
import {OneSignalService} from './services/one-signal/one-signal.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html'
})
export class AppComponent extends OnPlatformEvents {

    private readonly whiteHexString = '#FFFFFF';

    constructor(ngZone: NgZone,
                private platform: Platform,
                private statusBar: StatusBar,
                private loadingService: LoadingSpinnerService,
                private mobileAccessibility: MobileAccessibility,
                private appLanguage: AppLanguageService,
                private oneSignal: OneSignalService) {
        super(platform, ngZone);
        this.initializeApp();
    }

    ionPause(): void {
        this.loadingService.stopScheduler();
    }

    ionResume(): void {
        this.loadingService.startScheduler();
    }

    initializeApp(): void {
        this.platform.ready().then(() => {
            this.statusBar.backgroundColorByHexString(this.whiteHexString);
            this.statusBar.styleDefault();
            this.mobileAccessibility.usePreferredTextZoom(false);
            this.appLanguage.setTranslationLanguage().subscribe();
            this.loadingService.startScheduler();
            this.oneSignal.init();
        });
    }
}
