import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import * as moment from 'moment';
import {Moment} from 'moment';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {hasValues, isDefined} from '../../../../commons/utils';
import {CompanyService} from '../../../../services/company/company.service';
import {AppLanguage} from '../../../../domain/app-language';
import {AppLanguageService} from '../../../../services/app-language/app-language.service';
import {InvalidBusinessDayService} from '../../../../services/invalid-business-day/invalid-business-day.service';

const ET_DATE_FORMATS = {
    parse: {
        dateInput: 'LL'
    },
    display: {
        dateInput: 'ddd, D MMM YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY'
    }
};

@Component({
    selector: 'app-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss'],
    providers: [
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: ET_DATE_FORMATS},
    ]
})
export class DatePickerComponent implements OnInit {

    @Input() tradingCurrency: string;
    @Input() contraCurrency: string;
    @Input() dueDate: Moment;
    @Input() invalidDate: Date;
    @Input() minDate: Moment;
    @Input() maxDate: Moment;

    minDueDate: Moment;
    maxDueDate: Moment;
    disabledSetDate = true;

    private maxTenorInMonths: number;
    private invalidBusinessDays: string[];
    private currentLanguage: string;

    constructor(private readonly modalController: ModalController,
                private readonly appLanguageService: AppLanguageService,
                private readonly dateAdapter: DateAdapter<Moment>,
                private readonly invalidBusinessDayService: InvalidBusinessDayService) {
    }

    ngOnInit(): void {
        this.currentLanguage = this.appLanguageService.getTranslationLanguage();
        this.dateAdapter.setLocale(this.currentLanguage);
        this.maxTenorInMonths = CompanyService.getCurrentCompany().maxTenorInMonths;
        if (isDefined(this.minDate)) {
            this.minDueDate = this.minDate;
        }
        if (isDefined(this.maxDate)) {
            this.maxDueDate = this.maxDate;
        }
        this.computeAndSetMaxDueDate();
        if (isDefined(this.tradingCurrency) && isDefined(this.contraCurrency)) {
            this.getInvalidBusinessDays();
        }

    }

    filterInvalidDays = (day: Moment): boolean => {
        if (!this.isWorkingDay(day) || day.isAfter(this.maxDueDate)) {
            return false;
        }
        return !(isDefined(this.invalidDate) && day.isSame(moment(this.invalidDate), 'day'));
    };

    changeHedgeDate(event: Moment): void {
        this.dueDate = event;
        this.dueDate.utc(true);
        this.disabledSetDate = false;
    }

    private isWorkingDay(date: moment.Moment): boolean {
        switch (this.currentLanguage) {
            case AppLanguage.NL:
                return date.weekday() !== 5 && date.weekday() !== 6;
            case AppLanguage.FI:
                return date.weekday() !== 5 && date.weekday() !== 6;
            case AppLanguage.EN:
                return date.weekday() !== 6 && date.weekday() !== 0;
            case AppLanguage.DE:
                return date.weekday() !== 5 && date.weekday() !== 6;
            default:
                return date.weekday() !== 6 && date.weekday() !== 0;
        }
    }

    private getInvalidBusinessDays(): void {
        if (isDefined(this.tradingCurrency) && isDefined(this.contraCurrency)) {
            this.invalidBusinessDayService
                .getInvalidBusinessDays([this.tradingCurrency, this.contraCurrency])
                .subscribe(invalidBusinessDays => this.loadDateFilter(invalidBusinessDays));
        }
    }

    private loadDateFilter(invalidBusinessDays: string[]): void {
        this.invalidBusinessDays = invalidBusinessDays;
        this.filterInvalidDays = (day: Moment): boolean => {
            const formattedDay = day.toISOString(true).split('+')[0] + 'Z';
            if (!this.isWorkingDay(day) || day.isAfter(this.maxDueDate)) {
                return false;
            }
            if (isDefined(this.invalidDate) && day.isSame(moment(this.invalidDate), 'day')) {
                return false;
            }
            return hasValues(this.invalidBusinessDays)
                ? this.invalidBusinessDays.every(invalidDay => invalidDay !== formattedDay)
                : true;
        };
    }

    private computeAndSetMaxDueDate(): void {
        const currentDay = moment().utcOffset(0, true).startOf('day');
        if (!isDefined(this.maxDate)) {
            this.maxDueDate = currentDay.clone().add(this.maxTenorInMonths, 'month');
        }
    }

    dismissModal(): void {
        this.modalController.dismiss({
            'dueDate': isDefined(this.dueDate) ? this.dueDate : null,
        }).then();
    }

    closeModal(): void {
        this.modalController.dismiss().then();
    }
}
