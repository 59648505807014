import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
    selector: 'app-expandable-checkbox-filter',
    templateUrl: './expandable-checkbox-filter.component.html',
    styleUrls: ['./expandable-checkbox-filter.component.css'],
})
export class ExpandableCheckboxFilterComponent implements OnInit, OnChanges {

    isExpanded = false;
    isFilterActive: boolean;
    activeFilters: number;

    @Input() filterNameKey: string;
    @Input() filterItems: string[];
    @Input() selectedFilterItems: string[];
    @Input() translateString: string;
    @Input() disabled = false;
    @Output() changeFilters = new EventEmitter<string[]>();

    constructor() {
    }

    ngOnInit(): void {
        this.isFilterActive = this.selectedFilterItems?.length > 0;
        this.activeFilters = this.selectedFilterItems?.length;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.isFilterActive = this.selectedFilterItems?.length > 0;
        this.activeFilters = this.selectedFilterItems?.length;
    }

    onFilterSelect(checked: boolean, filterItem: string): void {
        if (!this.disabled) {
            if (checked) {
                this.selectedFilterItems?.push(filterItem);
            } else {
                const indexOfFilterItem = this.selectedFilterItems.indexOf(filterItem);
                this.selectedFilterItems?.splice(indexOfFilterItem, 1);
            }
            this.isFilterActive = this.selectedFilterItems?.length > 0;
            this.activeFilters = this.selectedFilterItems?.length;
            this.changeFilters.emit(this.selectedFilterItems);
        }
    }

    clearAll(event: MouseEvent): void {
        event.stopPropagation();
        this.isFilterActive = false;
        this.activeFilters = 0;
        this.selectedFilterItems = [];
        this.changeFilters.emit(this.selectedFilterItems);
    }

    toggleExpand(): void {
        this.isExpanded = !this.isExpanded;
    }

}
