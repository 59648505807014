import {Component, OnInit} from '@angular/core';
import {ApplicationSettings} from '../../../domain/contact-settings';
import {AppConstants} from '../../app-constants';
import {LocalStorageService} from '../../../services/local-storage/local-storage.service';

@Component({
    selector: 'app-qr-code-description',
    templateUrl: './qr-code-description.component.html',
    styleUrls: ['./qr-code-description.component.scss'],
})
export class QrCodeDescriptionComponent implements OnInit {

    applicationSettings: ApplicationSettings;

    constructor(private readonly localStorageService: LocalStorageService) {
    }

    ngOnInit(): void {
        this.applicationSettings = this.localStorageService
            .getItem<ApplicationSettings>(AppConstants.CACHED_APPLICATION_SETTINGS);
    }

}
