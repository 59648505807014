import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {CompanyService} from '../company/company.service';
import {Observable} from 'rxjs';
import {HedgedOrder} from '../../domain/hedged-order';
import {HttpUtils} from '../../commons/http/http-utils';
import {Page} from '../../commons/page';
import {hasValues, isDefined} from '../../commons/utils';
import {FirebaseService} from '../firebase/firebase.service';
import {HedgedOrderFilter} from '../../domain/filter/hedged-order-filter';
import {Pageable} from '../../commons/pageable';
import {DateTimeUtilService} from '../date-time-util/date-time-util.service';
import {HttpConstants} from '../../commons/http-constants';
import {map} from 'rxjs/operators';
import {RollableHedgedOrder} from '../../domain/rollable-hedged-order';
import {AverageRate} from '../../domain/average-rate';
import {User} from '../../domain/user';
import {Sort, SortDirection} from '../../commons/sort';

@Injectable({
    providedIn: 'root'
})
export class HedgedOrderService {

    constructor(public http: HttpClient) {
    }

    getHedgedOrders(hedgedOrderFilter: HedgedOrderFilter, pageable: Pageable): Observable<Page<HedgedOrder>> {
        const companyCodes = [];
        const isRootCompany = CompanyService.getCachedRootCompany().code === CompanyService.getCurrentCompany().code;
        if (isRootCompany) {
            CompanyService.getCachedCompanies().forEach(entry => companyCodes.push(entry.code));
        } else {
            companyCodes.push(CompanyService.getCurrentCompany().code);
        }
        const url = `${FirebaseService
            .getApiBasePath()}/api/global/hedges`;

        const params = HttpUtils.getDefinedParamsFromObject(hedgedOrderFilter);

        params.transaction = 'true';
        params.includeFullyRolled = 'true';

        if (isDefined(hedgedOrderFilter.hedgeDateFrom)) {
            params.hedgeDateFrom = DateTimeUtilService.formatToIsoDate(hedgedOrderFilter.hedgeDateFrom);
        }
        if (isDefined(hedgedOrderFilter.hedgeDateTo)) {
            params.hedgeDateTo = DateTimeUtilService.formatToIsoDate(hedgedOrderFilter.hedgeDateTo);
        }
        if (isDefined(hedgedOrderFilter.executionDateFrom)) {
            params.executionDateFrom = DateTimeUtilService.formatToIsoDate(hedgedOrderFilter.executionDateFrom);
        }
        if (isDefined(hedgedOrderFilter.executionDateTo)) {
            params.executionDateTo = DateTimeUtilService.formatToIsoDate(hedgedOrderFilter.executionDateTo);
        }

        return this.http.get<Page<HedgedOrder>>(url, HttpUtils.getRequestOptionsWithParameters(params, pageable, true));
    }

    getLatestHedgedOrderByField(field: string, hedgedOrderFilter?: HedgedOrderFilter): Observable<HedgedOrder> {
        const pageable: Pageable = {
            page: 0,
            size: 10,
            sort: Sort.by(field, SortDirection.DESC)
        };
        const filter = isDefined(hedgedOrderFilter) ? hedgedOrderFilter : new HedgedOrderFilter();
        return this.getHedgedOrders(filter, pageable)
            .pipe(
                map(page => page.content[0])
            );
    }

    getFirstHedgedOrderByField(field: string, hedgedOrderFilter?: HedgedOrderFilter): Observable<HedgedOrder> {
        const pageable: Pageable = {
            page: 0,
            size: 10,
            sort: Sort.by(field, SortDirection.ASC)
        };
        const filter = isDefined(hedgedOrderFilter) ? hedgedOrderFilter : new HedgedOrderFilter();
        return this.getHedgedOrders(filter, pageable)
            .pipe(
                map(page => page.content[0])
            );
    }

    getRollableHedgedOrders(filter: HedgedOrderFilter,
                            pageable: Pageable): Observable<Page<RollableHedgedOrder>> {
        const url = `${FirebaseService.getApiBasePath()}/api/global/hedges/rollable`;
        const options = HttpUtils.getRequestOptionsWithParameters(
            {initialBatchId: 'undefined'},
            pageable
        );
        return this.http.post<Page<RollableHedgedOrder>>(url, filter, options);
    }

    getBatchHedges(initialBatchId: string, legRefIds: string[], sort: Sort): Observable<HedgedOrder[]> {
        const url = `${FirebaseService.getApiBasePath()}/api/hedges/${initialBatchId}`;
        const options = HttpUtils.getRequestOptionsWithParameters(
            {legRefIds: legRefIds},
            Pageable.withDefaultSize(0, sort),
            true
        );
        return this.http.get<HedgedOrder[]>(url, options);
    }

    getSettledLegs(treeId: string): Observable<Page<RollableHedgedOrder>> {
        const httpParams = new HttpParams()
            .set('size', HttpConstants.DEFAULT_PAGE_SIZE.toString());
        const url = `${FirebaseService.getApiBasePath()}/api/global/hedges/trees/${treeId}/settled-legs`;
        return this.http.get<Page<RollableHedgedOrder>>(
            url,
            HttpUtils.getRequestOptionsWithParameters(httpParams, null, true)
        );
    }

    getInitialHedgedOrder(legRefId: string): Observable<HedgedOrder> {
        const hedgedOrderFilter = new HedgedOrderFilter();
        hedgedOrderFilter.legRefIds = [legRefId];
        const params = HttpUtils.getRequestOptionsWithParameters(hedgedOrderFilter, null, true);
        const url = `${FirebaseService.getApiBasePath()}/api/global/hedges`;
        return this.http.get<Page<HedgedOrder>>(url, params)
            .pipe(map(page => hasValues(page.content) ? page.content[0] : null));
    }

    getUnsettledLegs(parentLegRefId: string): Observable<Page<RollableHedgedOrder>> {
        const httpParams = new HttpParams()
            .set('parentLegRefId', parentLegRefId)
            .set('size', HttpConstants.DEFAULT_PAGE_SIZE.toString());
        const url = `${FirebaseService.getApiBasePath()}/api/global/hedges/trees/unsettled-legs`;
        return this.http.get<Page<RollableHedgedOrder>>(url, HttpUtils.getRequestOptionsWithParameters(httpParams));
    }

    getFamilyAverageRate(treeId: string): Observable<AverageRate> {
        const url = `${FirebaseService.getApiBasePath()}/api/global/hedges/trees/${treeId}`;
        return this.http.get<AverageRate>(url, HttpUtils.getRequestOptions(true));
    }

    getHedgers(companyCodes: string[] = []): Observable<User[]> {
        const url = `${FirebaseService.getApiBasePath()}/api/global/hedges/hedgers`;
        const options = HttpUtils.getRequestOptionsWithParameters({companyCodes}, null, true);
        return this.http.get<User[]>(url, options);
    }

    getUsedCurrencies(companyCodes: string[] = []): Observable<string[]> {
        const url = `${FirebaseService.getApiBasePath()}/api/global/hedges/currencies`;
        const options = HttpUtils.getRequestOptionsWithParameters({companyCodes}, null, true);
        return this.http.get<string[]>(url, options);
    }

    getUsedSymbols(companyCodes: string[] = []): Observable<string[]> {
        const url = `${FirebaseService.getApiBasePath()}/api/global/hedges/symbols`;
        const options = HttpUtils.getRequestOptionsWithParameters({companyCodes}, null, true);
        return this.http.get<string[]>(url, options);
    }

    getDataSources(companyCodes: string[] = []): Observable<string[]> {
        const url = `${FirebaseService.getApiBasePath()}/api/global/hedges/data-sources`;
        const options = HttpUtils.getRequestOptionsWithParameters({companyCodes}, null, true);
        return this.http.get<string[]>(url, options);
    }

    getPlatforms(companyCodes: string[] = []): Observable<string[]> {
        const url = `${FirebaseService.getApiBasePath()}/api/global/hedges/platforms`;
        const options = HttpUtils.getRequestOptionsWithParameters({companyCodes}, null, true);
        return this.http.get<string[]>(url, options);
    }
}
