import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {InsightsPeriod} from '../../../../domain/insights-period';

@Component({
    selector: 'app-tenor-selector',
    templateUrl: './tenor-selector.component.html',
    styleUrls: ['./tenor-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TenorSelectorComponent implements OnInit {

    @Input() tenor: InsightsPeriod;
    @Input() activeTenor: InsightsPeriod;
    @Output() selectTenor = new EventEmitter<InsightsPeriod>();

    constructor() {
    }

    ngOnInit(): void {
    }

    onTenorSelect(): void {
        this.selectTenor.emit(this.tenor);
    }
}
