import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {ActiveElement, ArcElement, Chart, ChartEvent, DoughnutController} from 'chart.js';
import Color from 'color-converter/dist/color-converter';
import {ChartUtil} from '../../../../utils/chart-util';
import {isDefined, isNullOrUndefined} from '../../../../commons/utils';
import {PositionOverview} from '../../../../domain/position-overview';
import {HedgingProfileService} from '../../../../services/hedging-profile/hedging-profile.service';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
    selector: 'app-currency-doughnut-chart',
    templateUrl: './currency-doughnut-chart.component.html',
    styleUrls: ['./currency-doughnut-chart.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CurrencyDoughnutChartComponent implements OnInit, OnChanges {

    private static readonly PADDING: number = 20;
    private readonly OVERLAP_PERCENTAGE = 6.5;

    readonly isNullOrUndefined = isNullOrUndefined;
    readonly isDefined = isDefined;
    readonly Math = Math;

    private isFirstLoad = true;
    private chartColors: any[];
    private x0: number;
    private y0: number;
    private dataLabels: string[];
    private smallIndex: number;

    doughnutChart: Chart<'doughnut'>;
    labelCoordinates = [];

    baseCcy$: Observable<string>;
    afterDraw$: Subject<any>;

    @Input() selectedSegmentIndex: number;
    @Input() positionsOverview: PositionOverview[];
    @Input() colors: string[];
    @Input() volume: boolean;
    @Input() totalNumberOfTrades: number;
    @Input() totalVolume: number;
    @Input() parsingKey: string;
    @Output() clickSegment = new EventEmitter<number>();
    @ViewChild('doughnutChartCanvas', {read: ElementRef, static: true}) doughnutChartCanvas: ElementRef;

    constructor(private readonly hedgingProfileService: HedgingProfileService) {
    }

    ngOnInit(): void {
        this.baseCcy$ = this.hedgingProfileService.getHedgingProfile().pipe(
            map(hedgingProfile => hedgingProfile.baseCurrency)
        );
        this.smallIndex = this.volume
            ? this.positionsOverview.findIndex((positionOverview) =>
                positionOverview.tradingVolumePercentage < this.OVERLAP_PERCENTAGE)
            : this.positionsOverview.findIndex((positionOverview) =>
                positionOverview.tradesCountPercentage < this.OVERLAP_PERCENTAGE);
        this.chartColors = this.colors;
        Chart.register(DoughnutController, ArcElement);
        this.dataLabels = this.positionsOverview.map((positionOverview: PositionOverview) => positionOverview.symbol);
        this.createChart();
        this.afterDraw$ = new Subject<any>();
    }

    private createChart(): void {
        const afterDraw = (chart: Chart<'doughnut'>): void => {
            const {ctx, chartArea: {width}} = chart;
            this.x0 = width / 2 + CurrencyDoughnutChartComponent.PADDING;
            this.y0 = width / 2 - 2 * CurrencyDoughnutChartComponent.PADDING;

            this.chartColors = [];
            const labelCoordinates = [];

            chart.getDatasetMeta(0).data.forEach((datapoint, index) => {
                const {x, y} = datapoint.tooltipPosition(false);
                const labelCoordinate = ChartUtil.getPointAtDistanceFromAnother({x: this.x0, y: this.y0}, {x, y}, 0);
                const text = chart.data.labels[index] as string;
                const textWithPercentage = text + ' ' + '34%';
                ctx.font = '10px var(--font-regular)';
                const textWidth = ctx.measureText(textWithPercentage).width;
                const chartQuarter = ChartUtil
                    .getChartQuarter({x: this.x0, y: this.y0}, {x: labelCoordinate.x, y: labelCoordinate.y});
                const correctedLabelCoordinate = ChartUtil
                    .labelCoordinateCorrection(labelCoordinate, chartQuarter, textWidth);
                const sliceColor = Color.fromHex(this.colors[index]);
                this.chartColors.push(ChartUtil.createDoughnutChartGradient(
                    this.doughnutChartCanvas,
                    sliceColor,
                    chartQuarter,
                    200,
                    15));

                labelCoordinates.push(correctedLabelCoordinate);
            });

            this.labelCoordinates = labelCoordinates;
            this.updateLabelCoordinates();
            if (this.isFirstLoad && isDefined(this.doughnutChart)) {
                this.doughnutChart.data.datasets[0].backgroundColor = this.chartColors;
                this.isFirstLoad = !this.isFirstLoad;
            }
        };

        const onClick = (event: ChartEvent, elements: ActiveElement[]): void => {
            const segment = elements[0];
            if (isDefined(segment)) {
                this.onSegmentClicked(segment.index);
            }
        };

        onClick.bind(this);
        afterDraw.bind(this);

        const doughnutLabelsLine = {
            id: 'doughnutLabelsLine',
            afterDraw,
        };

        this.doughnutChart = new Chart(this.doughnutChartCanvas.nativeElement, {
            type: 'doughnut',
            data: {
                labels: this.dataLabels,
                datasets: [
                    {
                        data: this.positionsOverview as any,
                        backgroundColor: this.chartColors,
                        borderWidth: 0
                    }
                ]
            },
            options: {
                cutout: '70%',
                rotation: -100,
                layout: {
                    padding: CurrencyDoughnutChartComponent.PADDING
                },
                parsing: {
                    key: this.parsingKey
                },
                aspectRatio: 1.5,
                responsive: true,
                onClick: onClick,
                plugins: {
                    tooltip: {
                        enabled: false
                    },
                    legend: {
                        display: false
                    }
                },
                animation: {
                    onProgress: () => {
                        this.doughnutChart.update('none');
                    }
                }
            },
            plugins: [doughnutLabelsLine]
        });
    }

    onLabelClicked(event: string): void {
        const index = this.doughnutChart.data.labels.findIndex(label => label === event);
        this.selectedSegmentIndex === index ? this.selectedSegmentIndex = null : this.selectedSegmentIndex = index;
        this.clickSegment.emit(this.selectedSegmentIndex);
        this.updateChartColors(index);
    }

    private onSegmentClicked(segmentIndex: number): void {
        this.selectedSegmentIndex === segmentIndex
            ? this.selectedSegmentIndex = null
            : this.selectedSegmentIndex = segmentIndex;
        this.clickSegment.emit(this.selectedSegmentIndex);
        this.updateChartColors(segmentIndex);
    }

    private updateChartColors(index: number): void {
        const colors = [];
        const correctedIndex = Math.min(index, 4);
        this.colors.forEach((color, i) => {
            const point = {x: this.labelCoordinates[i]?.x, y: this.labelCoordinates[i]?.y};
            const chartQuarter = ChartUtil.getChartQuarter({x: this.x0, y: this.y0}, point);
            if (color !== this.colors[correctedIndex] && isDefined(this.selectedSegmentIndex)) {
                colors.push(ChartUtil.createDoughnutChartGradient(
                    this.doughnutChartCanvas,
                    Color.fromHex(this.colors[i]).desaturate(100),
                    chartQuarter,
                    200,
                    35));
            } else {
                colors.push(ChartUtil.createDoughnutChartGradient(this.doughnutChartCanvas,
                    Color.fromHex(color),
                    chartQuarter,
                    200,
                    15));
            }
        });
        this.doughnutChart.data.datasets[0].backgroundColor = colors;
        this.doughnutChart.update('none');
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (isDefined(this.doughnutChart)) {
            if (isDefined(changes.selectedSegmentIndex)) {
                this.updateChartColors(changes.selectedSegmentIndex?.currentValue);
            }
        }
    }

    private updateLabelCoordinates(): void {
        if (this.smallIndex > 0) {
            this.labelCoordinates.forEach((labelCoordinate, index) => {
                if (index > this.smallIndex) {
                    labelCoordinate.y = this.labelCoordinates[index - 1].y - 22;
                }
            });
        }
        this.afterDraw$.next(this.labelCoordinates);
    }
}
