import {ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {SettlementOverview} from '../../../../domain/settlement-overview';
import {InsightsPeriod} from '../../../../domain/insights-period';
import {SettlementOverviewFilter} from '../../../../domain/filter/settlement-overview-filter';

@Component({
    selector: 'app-settlement-overview-details-list',
    templateUrl: './settlement-overview-details-list.component.html',
    styleUrls: ['./settlement-overview-details-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettlementOverviewDetailsListComponent implements OnChanges {

    total: number;

    @Input() activeTenor: InsightsPeriod;
    @Input() settlementOverview: SettlementOverview;
    @Input() symbol: string;
    @Input() settlementOverviewFilter: SettlementOverviewFilter;

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.settlementOverview.currentValue !== changes.settlementOverview.previousValue) {
            this.total = this.settlementOverview.currencyOverviews
                .find(currencyOverview => currencyOverview.currency === this.symbol).totalNetAmount;
        }
    }

}
