import {Component, EventEmitter, Output} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {NavController} from '@ionic/angular';
import {ToastService} from '../../../../services/toast/toast.service';
import {TranslateService} from '@ngx-translate/core';
import {AppConstants} from '../../../../commons/app-constants';
import {SecuredToken} from '../../../../domain/secured-token';
import {EtEncryptService} from '../../../../services/et-encrypt/et-encrypt.service';
import {LocalStorageService} from '../../../../services/local-storage/local-storage.service';
import {JwtHelperService} from '@auth0/angular-jwt';
import {PinCodeService} from '../../../../services/pin-code/pin-code.service';

@Component({
    selector: 'app-previous-pin-check',
    templateUrl: './previous-pin-check.component.html',
    styleUrls: ['./previous-pin-check.component.scss']
})
export class PreviousPinCheckComponent {

    private matchedCurrentPin = new Subject<string>();
    currentUserPin = '';
    private jwtHelper: JwtHelperService;

    @Output() matchedCurrentPinChange = new EventEmitter<string>;

    constructor(private navCtrl: NavController,
                private toast: ToastService,
                private translate: TranslateService,
                private etEncryptService: EtEncryptService,
                private localStorageService: LocalStorageService,
                private pinCodeService: PinCodeService) {
        this.jwtHelper = new JwtHelperService();
    }

    handleInput(prevPinDigit: string): void {
        if (prevPinDigit === AppConstants.DELETE) {
            this.deleteLastDigit();
        } else {
            this.currentUserPin += prevPinDigit;
        }
        if (this.currentUserPin.length === 5) {
            const decryptedToken = this.etEncryptService
                .decryptTokenSignature(this.localStorageService.getItem<SecuredToken>(AppConstants.SECURED_TOKEN),
                    this.currentUserPin);
            const userSub = this.jwtHelper.decodeToken(decryptedToken.refreshToken).sub;
            this.pinCodeService.validPin(userSub, this.currentUserPin).subscribe(() => {
                this.matchedCurrentPin.next(this.currentUserPin);
                this.matchedCurrentPinChange.emit(this.currentUserPin);
            }, () => {
                this.toast.error(this.translate.instant('previous-pin-code.match-error'));
                this.currentUserPin = AppConstants.EMPTY_STRING;
            });
        }
    }

    goBack(): void {
        this.navCtrl.pop().then();
    }

    deleteLastDigit(): void {
        this.currentUserPin = this.currentUserPin.substr(0, this.currentUserPin.length - 1);
    }

    onMatchedPin(): Observable<string> {
        return this.matchedCurrentPin.asObservable();
    }
}
