import {Sort} from './sort';

export interface Pageable {
    page: number;
    size: number;
    sort: Sort;
}

export namespace Pageable {
    export const DEFAULT_PAGE_SIZE = 20;

    export function withMaxSize(page: number = 0, sort: Sort = Sort.empty()): Pageable {
        return {
            page: page,
            size: 2000,
            sort: sort
        };
    }

    export function withDefaultSize(page: number = 0, sort: Sort = Sort.empty()): Pageable {
        return {
            page: page,
            size: Pageable.DEFAULT_PAGE_SIZE,
            sort: sort
        };
    }
}
