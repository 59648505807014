import {EventMessage, EventMessageType} from '../../domain/event-message';
import {HttpUtils} from '../../commons/http/http-utils';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {MessageStatus} from '../../domain/message-status';
import {FirebaseService} from '../firebase/firebase.service';
import {Page} from '../../commons/page';
import {Pageable} from '../../commons/pageable';

@Injectable({
    providedIn: 'root'
})
export class EventMessageService {

    constructor(private readonly httpClient: HttpClient) {
    }

    updateEventMessageStatus<T>(id: String, messageStatus: MessageStatus): Observable<EventMessage<T>> {
        const url = `${FirebaseService.getApiBasePath()}/api/global/event-messages/${id}`
            + `?newMessageStatus=${messageStatus}`;
        return this.httpClient.put<EventMessage<T>>(url, {}, HttpUtils.getRequestOptions());
    }

    find(eventTypes: EventMessageType[], pageable: Pageable): Observable<Page<EventMessage<Object>>> {
        const url = `${FirebaseService.getApiBasePath()}/api/global/event-messages/`;
        let pagingParams = new HttpParams()
            .set('eventMessageTypes', eventTypes.toString() || '')
            .set('page', pageable.page.toString())
            .set('size', pageable.size.toString());
        pageable.sort.format().forEach(it => pagingParams = pagingParams.append('sort', it));
        return this.httpClient.get<Page<EventMessage<Object>>>(
            url,
            HttpUtils.getRequestOptionsWithParameters(pagingParams)
        );
    }

    count(messageStatuses: MessageStatus[]): Observable<number> {
        const url = `${FirebaseService.getApiBasePath()}/api/global/event-messages/count`;
        const params = new HttpParams()
            .set('messageStatuses', messageStatuses.toString() || '');
        return this.httpClient.get<number>(url, HttpUtils.getRequestOptionsWithParameters(params));
    }

    markAllAsRead(): Observable<EventMessage<Object>[]> {
        const url = `${FirebaseService.getApiBasePath()}/api/global/event-messages/mark-all-as-read`;
        return this.httpClient.put<EventMessage<Object>[]>(url, {}, HttpUtils.getRequestOptions());
    }
}
