import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {Dictionary} from '../../dictionary';
import {ClientContextService} from '../../../services/client-context/client-context.service';
import {AuthorizationService} from '../../../auth/authorization.service';
import {IAuthConfig} from 'ionic-appauth/lib/';

@Injectable()
export class ClientContextInterceptor implements HttpInterceptor {

    private static readonly CLIENT_CONTEXT_ID_KEY = 'X-Client-Context-Id';
    private readonly excludedPaths: string[];
    private readonly authConfig: IAuthConfig;

    constructor(private readonly clientContextService: ClientContextService,
                private readonly authorizationService: AuthorizationService) {
        this.authConfig = this.authorizationService.getAuthConfig();
        this.excludedPaths = [this.authorizationService.getIssuer(),
            this.authConfig.redirect_url,
            this.authConfig.end_session_redirect_url];
    }

    private isExcludedPath(url: string): boolean {
        return this.excludedPaths.some(path => url.indexOf(path) >= 0);
    }

    private getClientContextIdHeader(): Dictionary<string | string[]> {
        return {[ClientContextInterceptor.CLIENT_CONTEXT_ID_KEY]: this.clientContextService.getClientContextId()};
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.isExcludedPath(request.url)) {
            return next.handle(request);
        }

        const updatedRequest = request.clone({setHeaders: this.getClientContextIdHeader()});
        return next.handle(updatedRequest);
    }
}
