import {ChangeDetectionStrategy, Component, Input, OnChanges} from '@angular/core';
import {EventMessage, EventMessageType} from '../../../domain/event-message';
import {DateTimeUtilService} from '../../../services/date-time-util/date-time-util.service';
import {MessageStatus} from '../../../domain/message-status';
import {DecimalAmountPipe} from '../../../pipes/decimal-input/decimal-input.pipe';
import {flatMap, isDefined} from '../../utils';
import {TradePlanRateNotification} from 'src/app/domain/trade-plan-rate-notification';

@Component({
    selector: 'app-notification-message',
    templateUrl: './notification-message.component.html',
    styleUrls: ['./notification-message.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationMessageComponent implements OnChanges {

    readonly MessageStatus = MessageStatus;
    readonly EventMessageType = EventMessageType;
    readonly DecimalAmountPipe = DecimalAmountPipe;
    readonly isDefined = isDefined;
    readonly TRADE_PLAN_RATE_NOTIFICATION_TRANSLATE_PREFIX = 'notifications-page.notification.trade-plan-rate-notification.';

    elapsedTimeLabel: string;
    projectEventsCount: number;
    tradePlanRateNotification: TradePlanRateNotification;

    @Input() event: EventMessage<object>;

    constructor(private readonly dateTimeUtilService: DateTimeUtilService) {
    }

    ngOnChanges(): void {
        this.computeElapsedTimeLabel();
        if (isDefined(this.event) && this.event.eventMessageType === EventMessageType.TRADE_PLAN_RATE_NOTIFICATION) {
            this.tradePlanRateNotification = this.event.data as TradePlanRateNotification;
            this.projectEventsCount = Object.values(this.tradePlanRateNotification.projectToEventIds)
                .reduce(flatMap, [])
                .length;
        }
    }

    private computeElapsedTimeLabel(): void {
        this.elapsedTimeLabel = this.dateTimeUtilService.getTimeSinceLabel(this.event.createdDate);
    }
}
