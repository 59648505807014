import {Injectable} from '@angular/core';
import {TokenResponse} from '@openid/appauth';
import {AppConstants} from '../../commons/app-constants';
import jsSHA from 'jssha';
import * as CryptoJS from 'crypto-js';
import base64url from 'base64-url';
import {SharedDataService} from '../shared-data/shared-data.service';
import {SecuredToken} from '../../domain/secured-token';
import {isNullOrUndefined} from '../../commons/utils';
import {throwError} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class EtEncryptService {

    constructor(private sharedDataService: SharedDataService) {
    }

    encryptTokenSignature(token: TokenResponse, pinCode?: string): SecuredToken {
        const tokenObject = {...token};
        const signatureLength = tokenObject.refreshToken.split('.')[2].length;
        const signature = CryptoJS.enc.Base64.parse(base64url.unescape(tokenObject.refreshToken.split('.')[2]));
        tokenObject.refreshToken = tokenObject.refreshToken.slice(0, tokenObject.refreshToken.length - signatureLength);
        const conversionEncryptOutput = CryptoJS.AES.encrypt(signature, this.computePinCodeKey(pinCode),
            {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.NoPadding});
        tokenObject.refreshToken = tokenObject.refreshToken + conversionEncryptOutput;
        const securedToken = {...tokenObject};
        delete securedToken.accessToken;
        return securedToken as SecuredToken;
    }

    decryptTokenSignature(token: SecuredToken, pinCode?: string): SecuredToken {
        if (isNullOrUndefined(token)) {
            throwError('Missing secured token object from local storage');
        }
        const tokenObject = {...token};
        const signatureLength = tokenObject.refreshToken.split('.')[2].length;
        const encryptedSignature = tokenObject.refreshToken.split('.')[2];
        tokenObject.refreshToken = tokenObject.refreshToken.slice(0, tokenObject.refreshToken.length - signatureLength);
        const conversionDecryptOutput = CryptoJS.AES.decrypt(encryptedSignature, this.computePinCodeKey(pinCode),
            {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.NoPadding});
        tokenObject.refreshToken = tokenObject.refreshToken
            + base64url.escape(conversionDecryptOutput.toString(CryptoJS.enc.Base64));
        return tokenObject as SecuredToken;
    }

    computePinCodeKey(pinCode?: string): string {
        if (isNullOrUndefined(pinCode)) {
            pinCode = this.sharedDataService.getSharedData<string>(AppConstants.USER_PIN_CODE);
        }
        const shaObj = new jsSHA('SHA-256', 'TEXT');
        shaObj.update(pinCode);
        return shaObj.getHash('HEX');
    }
}
