import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {DateOverview} from '../../../../domain/date-overview';
import {isDefined} from '../../../../commons/utils';
import {
    PositionChartPeriodLabelPipe
} from '../../../../pipes/position-chart-period-label/position-chart-period-label.pipe';
import {InsightsPeriod} from '../../../../domain/insights-period';
import {ChartHelper} from '../../../../utils/chart-helper';
import * as Highcharts from 'highcharts';
import {Chart, Options} from 'highcharts';

@Component({
    selector: 'app-settlement-overview-bar-chart',
    templateUrl: './settlement-overview-bar-chart.component.html',
    styleUrls: ['./settlement-overview-bar-chart.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettlementOverviewBarChartComponent implements OnChanges {

    private readonly color = getComputedStyle(document.body).getPropertyValue('--chart-primary');

    private barChart: Chart;
    private ccyBuyAmounts: number[];
    private ccySellAmounts: number[];
    private chartLabels: string[];

    public isChartEmpty = true;

    @Input() symbol: string;
    @Input() dateOverviews: DateOverview[];
    @Input() activeTenor: InsightsPeriod;
    @ViewChild('barChart', {static: true}) barChartCanvas: ElementRef;

    constructor(private readonly positionChartPeriodLabelPipe: PositionChartPeriodLabelPipe,
                private readonly chartHelper: ChartHelper) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (isDefined(changes.dateOverviews?.currentValue)) {
            this.createChart();
        }
    }

    createChart(): void {
        this.resetChartData();
        this.getChartData();

        const options: Options = this.chartHelper.getSettlementBarChartOptions(
            this.ccyBuyAmounts,
            this.ccySellAmounts,
            this.symbol,
            this.chartLabels,
            this.color,
            this.chartHelper.getBarChartNumberFormatter()
        );
        this.barChart = Highcharts.chart(this.barChartCanvas.nativeElement, options);
    }

    resetChartData(): void {
        this.ccyBuyAmounts = [];
        this.ccySellAmounts = [];
        this.chartLabels = [];

        if (isDefined(this.barChart)) {
            this.barChart.destroy();
        }
    }

    getChartData(): void {
        this.dateOverviews.forEach(dateOverview => {
            const foundPosition = dateOverview.dateOverviewPositions
                .find(position => position.currency === this.symbol);

            if (isDefined(foundPosition)) {
                this.ccyBuyAmounts.push(foundPosition.buyAmount > 0 ? foundPosition.buyAmount : null);
                this.ccySellAmounts.push(foundPosition.sellAmount > 0 ? -foundPosition.sellAmount : null);
            } else {
                this.ccyBuyAmounts.push(null);
                this.ccySellAmounts.push(null);
            }

            const label = this.positionChartPeriodLabelPipe
                .transform(dateOverview.hedgeDatePeriod.startDate, this.activeTenor);
            const translatedLabel = this.chartHelper.getTranslationsForChartLabels(label, this.activeTenor);
            this.chartLabels.push(translatedLabel);
        });
    }
}
