import {Injectable} from '@angular/core';
import {LocalStorageService} from '../local-storage/local-storage.service';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {HttpUtils} from '../../commons/http/http-utils';
import {environment} from '../../../environments/environment';
import {isDefined} from '../../commons/utils';
import {FirebaseService} from '../firebase/firebase.service';

@Injectable({
    providedIn: 'root'
})
export class AppLanguageService {

    constructor(private localStorage: LocalStorageService,
                private translate: TranslateService,
                public http: HttpClient) {
    }

    getAppLanguages(): Observable<string[]> {
        const url = `${FirebaseService.getApiBasePath()}/api/config/languages`;
        return this.http.get<string[]>(url, HttpUtils.getRequestOptions());
    }

    setTranslationLanguage(langKey?: string): Observable<any> {
        const currentUserLanguageKey = isDefined(langKey) ? langKey : environment.defaultAppLanguage;
        this.translate.setDefaultLang(currentUserLanguageKey);
        return this.translate.use(currentUserLanguageKey);
    }

    getTranslationLanguage(): string {
        return isDefined(this.translate.defaultLang)
            ? this.translate.defaultLang
            : environment.defaultAppLanguage;
    }
}
