import {AfterContentChecked, ChangeDetectionStrategy, Component, OnInit, ViewChild} from '@angular/core';
import {HttpConstants} from '../../../../commons/http-constants';
import {PositionOverviewFilter} from '../../../../domain/filter/position-overview-filter';
import {HedgedOrderFilter} from '../../../../domain/filter/hedged-order-filter';
import {PositionOverview} from '../../../../domain/position-overview';
import {TimePeriod} from '../../../../domain/time-period';
import {BehaviorSubject, forkJoin, Observable} from 'rxjs';
import {
    FilterPeriods
} from '../../insights-details-modal/position-overview-details-modal/position-overview-details-modal.component';
import {FieldNames} from '../../../../commons/field-names';
import {SwiperOptions} from 'swiper';
import {IonContent, ModalController} from '@ionic/angular';
import {SwiperComponent} from 'swiper/angular';
import {InsightsService} from '../../../../services/insights/insights.service';
import {HedgedOrderService} from '../../../../services/hedged-order/hedged-order.service';
import {LocalStorageService} from '../../../../services/local-storage/local-storage.service';
import {HedgedOrder} from '../../../../domain/hedged-order';
import {Pageable} from '../../../../commons/pageable';
import {Sort, SortDirection} from '../../../../commons/sort';
import {filter, map, switchMap, tap} from 'rxjs/operators';
import {Page} from '../../../../commons/page';
import {PersistedFilter} from '../../../../domain/persisted-filter';
import {DateTimeUtilService} from '../../../../services/date-time-util/date-time-util.service';
import * as moment from 'moment';
import {CompanyService} from '../../../../services/company/company.service';
import {isDefined, sum} from '../../../../commons/utils';

@Component({
  selector: 'app-position-overview',
  templateUrl: './position-overview.component.html',
  styleUrls: ['./position-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PositionOverviewComponent implements OnInit, AfterContentChecked {

  public static readonly FILTER_KEY = 'positionOverviewFilter';

  readonly colors: string[] = [
    getComputedStyle(document.body).getPropertyValue('--chart-primary'),
    getComputedStyle(document.body).getPropertyValue('--chart-fill-1'),
    getComputedStyle(document.body).getPropertyValue('--chart-fill-2'),
    getComputedStyle(document.body).getPropertyValue('--chart-fill-3'),
    getComputedStyle(document.body).getPropertyValue('--chart-fill-4'),
  ];
  readonly isDefined = isDefined;

  private readonly pageSize = HttpConstants.DEFAULT_PAGE_SIZE;

  private scrolledItemHeight = 119;
  private page = 0;

  positionOverviewFilter: PositionOverviewFilter;
  hedgedOrdersFilter: HedgedOrderFilter;
  nrOfTradesPositionsOverview: PositionOverview[];
  totalVolumesPositionsOverview: PositionOverview[];

  selectedSegmentIndex: number;

  isVolumeChartLoading = true;
  isTotalChartLoading = true;
  showNoTrades = false;

  settlementPeriod: TimePeriod;
  tradesPeriod: TimePeriod;

  filterPeriods$: Observable<FilterPeriods>;
  getPositionsOverview$: BehaviorSubject<PositionOverviewFilter>;
  positionsOverviewTotalVolume$: Observable<PositionOverview[]>;
  positionsOverviewNrOfTrades$: Observable<PositionOverview[]>;

  isTradingVolumeSelected = true;
  totalNumberOfTrades: number;
  totalVolume: number;
  tradingVolumeParsingKey = FieldNames.TRADING_VOLUME_PERCENTAGE;
  numberOfTradesParsingKey = FieldNames.TRADES_COUNT_PERCENTAGE;

  swiperConfigs: SwiperOptions = {
    pagination: true
  };

  @ViewChild(IonContent, {read: IonContent, static: false}) scrollPosition: IonContent;
  @ViewChild('swiper') swiper: SwiperComponent;

  constructor(private readonly insightsService: InsightsService,
              private readonly hedgedOrderService: HedgedOrderService,
              private readonly localStorageService: LocalStorageService,
              private readonly modalController: ModalController,
              private readonly hedgedOrdersService: HedgedOrderService) {
  }

  public static setPeriods(latestExpiringHedgedOrder: HedgedOrder,
                           latestTradedHedgedOrder: HedgedOrder,
                           firstTradedHedgedOrder: HedgedOrder,
                           firstExpiringHedgedOrder: HedgedOrder): FilterPeriods {

    const settlementPeriod = {
      startDate: new Date(firstExpiringHedgedOrder.hedgeDate),
      endDate: new Date(latestExpiringHedgedOrder.hedgeDate)
    };
    const tradesPeriod = {
      startDate: new Date(firstTradedHedgedOrder.executionDate),
      endDate: new Date(latestTradedHedgedOrder.executionDate)
    };

    return {
      settlementPeriod: settlementPeriod,
      tradesPeriod: tradesPeriod
    };
  }

  ngOnInit(): void {
    const pageable: Pageable = {
      page: 0,
      size: 10,
      sort: Sort.empty()
    };

    this.hedgedOrdersFilter = new HedgedOrderFilter();
    this.filterPeriods$ = this.hedgedOrdersService.getHedgedOrders(this.hedgedOrdersFilter, pageable)
        .pipe(
            tap(hedgedOrdersPage => this.handleNoTrades(hedgedOrdersPage)),
            tap(() => this.handleSavedFilter()),
            filter(hedgedOrdersPage => hedgedOrdersPage.totalElements !== 0),
            switchMap(() => forkJoin([
              this.hedgedOrderService.getLatestHedgedOrderByField('hedgeDate'),
              this.hedgedOrderService.getLatestHedgedOrderByField('executionDate'),
              this.hedgedOrderService.getFirstHedgedOrderByField('executionDate'),
              this.hedgedOrderService.getFirstHedgedOrderByField('hedgeDate')
            ])),
            map(([
                   latestExpiringHedgedOrder,
                   latestTradedHedgedOrder,
                   firstTradedHedgedOrder,
                   firstExpiringHedgedOrder]) =>
                PositionOverviewComponent.setPeriods(
                    latestExpiringHedgedOrder,
                    latestTradedHedgedOrder,
                    firstTradedHedgedOrder,
                    firstExpiringHedgedOrder
                ))
        );

    this.handleSavedFilter();
    this.getPositionsOverview();
  }

  ngAfterContentChecked(): void {
    if (this.swiper) {
      this.swiper.updateSwiper({});
    }
  }

  detectSwiperChanges(): void {
    this.isTradingVolumeSelected = !this.isTradingVolumeSelected;
    if (isDefined(this.selectedSegmentIndex)) {
      if (this.isTradingVolumeSelected) {
        this.selectedSegmentIndex = this.totalVolumesPositionsOverview
            .findIndex(position =>
                this.nrOfTradesPositionsOverview[this.selectedSegmentIndex].symbol === position.symbol);
      } else {
        this.selectedSegmentIndex = this.nrOfTradesPositionsOverview
            .findIndex(position =>
                this.totalVolumesPositionsOverview[this.selectedSegmentIndex].symbol === position.symbol);
      }
    }
  }

  private handleNoTrades(hedgedOrdersPage: Page<HedgedOrder>): void {
    if (hedgedOrdersPage.totalElements === 0) {
      this.showNoTrades = true;
      this.isVolumeChartLoading = false;
    }
  }

  private handleSavedFilter(): void {
    const savedFilter: PersistedFilter<PositionOverviewFilter> = this.localStorageService
        .getItem(PositionOverviewComponent.FILTER_KEY);
    const currentDate = new Date();
    const filterSaveDate = new Date(savedFilter?.timeStamp);
    if (isDefined(savedFilter) && currentDate.getDate() === filterSaveDate.getDate()) {
      this.positionOverviewFilter = savedFilter.filter;
    } else {
      this.localStorageService.removeItem(PositionOverviewComponent.FILTER_KEY).then();
      this.positionOverviewFilter = {
        hedgeDateFrom: DateTimeUtilService.formatToIsoDate(moment().toString())
      };
    }
  }

  private getPositionsOverview(): void {
    this.positionOverviewFilter.companyCodes = [CompanyService.getCurrentCompany().code];
    this.getPositionsOverview$ = new BehaviorSubject<PositionOverviewFilter>(this.positionOverviewFilter);

    const pageableTotalVolume: Pageable = {
      page: this.page,
      size: this.pageSize,
      sort: Sort.by(FieldNames.TRADING_VOLUME_PERCENTAGE, SortDirection.ASC)
    };
    this.positionsOverviewTotalVolume$ = this.getPositionsOverview$.pipe(
        switchMap(positionOverviewFilter =>
            this.insightsService.getPositionsOverview(positionOverviewFilter, pageableTotalVolume)
                .pipe(
                    map(page => page.content),
                    tap(positionsOverview => this.totalVolumesPositionsOverview = positionsOverview),
                    map(positionsOverview => positionsOverview.length > 5
                        ? this.groupPositionsOverview(positionsOverview)
                        : positionsOverview
                    ),
                    tap((positionsOverviewTotalVolume) => {
                      if (!positionsOverviewTotalVolume.length) {
                        this.showNoTrades = true;
                      }
                      this.isVolumeChartLoading = false;
                      this.calculateTotalVolume();
                    })
                )
        )
    );

    const pageableNrOfTrades: Pageable = {
      page: this.page,
      size: this.pageSize,
      sort: Sort.by(FieldNames.TRADES_COUNT_PERCENTAGE, SortDirection.ASC)
    };
    this.positionsOverviewNrOfTrades$ = this.getPositionsOverview$.pipe(
        switchMap(positionOverviewFilter =>
            this.insightsService.getPositionsOverview(positionOverviewFilter, pageableNrOfTrades)
                .pipe(
                    map(page => page.content),
                    tap(positionsOverview => this.nrOfTradesPositionsOverview = positionsOverview),
                    map(positionsOverview => positionsOverview.length > 5
                        ? this.groupPositionsOverview(positionsOverview)
                        : positionsOverview
                    ),
                    tap((positionsOverviewNrOfTrades) => {
                      if (!positionsOverviewNrOfTrades.length) {
                        this.showNoTrades = true;
                      }
                      this.isTotalChartLoading = false;
                      this.calculateTotalNumberOfTrades();
                    })
                )
        )
    );
  }

  private groupPositionsOverview(positionsOverview: PositionOverview[]): PositionOverview[] {
    const positionOverviewGroup: PositionOverview = {
      symbol: 'Other',
      tradesCount: 0,
      tradesCountPercentage: 0,
      tradingVolume: 0,
      tradingVolumePercentage: 0
    };
    const positionsOverviewGroup: PositionOverview[] = [];
    positionsOverview.forEach((positionOverview, index) => {
      if (index < 4) {
        positionsOverviewGroup.push(positionOverview);
      } else {
        positionOverviewGroup.tradesCount += positionOverview.tradesCount;
        positionOverviewGroup.tradesCountPercentage += positionOverview.tradesCountPercentage;
        positionOverviewGroup.tradingVolume += positionOverview.tradingVolume;
        positionOverviewGroup.tradingVolumePercentage += positionOverview.tradingVolumePercentage;
      }
    });
    positionsOverviewGroup.push(positionOverviewGroup);
    return positionsOverviewGroup;
  }

  onSegmentClicked(segmentIndex: number): void {
    this.selectedSegmentIndex = segmentIndex;
    this.scrollPosition.scrollToPoint(0, this.selectedSegmentIndex * this.scrolledItemHeight, 1000).then();
  }

  onCardClicked(event: number): void {
    this.selectedSegmentIndex === event
        ? this.selectedSegmentIndex = null
        : this.selectedSegmentIndex = event;
  }

  filterChanged(positionOverviewFilter: PositionOverviewFilter): void {
    this.positionOverviewFilter = positionOverviewFilter;
    this.selectedSegmentIndex = null;
    this.isVolumeChartLoading = true;
    this.isTotalChartLoading = true;
    this.showNoTrades = false;
    this.getPositionsOverview$.next(this.positionOverviewFilter);
  }

  calculateTotalNumberOfTrades(): void {
    if (this.nrOfTradesPositionsOverview.length !== 0) {
      this.totalNumberOfTrades = this.nrOfTradesPositionsOverview
          .map(overview => overview.tradesCount)
          .reduce(sum);
    }
  }

  calculateTotalVolume(): void {
    if (this.totalVolumesPositionsOverview.length !== 0) {
      this.totalVolume = this.totalVolumesPositionsOverview
          .map(overview => overview.tradingVolume)
          .reduce(sum);
    }
  }
}
