import {Pipe} from '@angular/core';
import {isDefined, isNullOrUndefined} from '../../commons/utils';
import {BaseRatePipe} from './base-rate';
import {DecimalAmountPipe} from '../decimal-input/decimal-input.pipe';

@Pipe({
    name: 'tradingRate'
})
export class TradingRatePipe extends BaseRatePipe {
    private static readonly SEVEN_RATE_FORMAT = '1.7-7';
    public static readonly FOUR_RATE_FORMAT = '1.4-4';

    constructor(decimalAmountPipe: DecimalAmountPipe) {
        super(decimalAmountPipe);
    }

    transform(value: number, decimalNumber?: string): string {
        if (isNullOrUndefined(value)) {
            return '';
        }

        if (isDefined(decimalNumber) && decimalNumber === TradingRatePipe.FOUR_RATE_FORMAT) {
            return super.transform(value, TradingRatePipe.FOUR_RATE_FORMAT);
        }

        return super.transform(
            value,
            value > 99 ? TradingRatePipe.FOUR_RATE_FORMAT : TradingRatePipe.SEVEN_RATE_FORMAT
        );
    }
}
