import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {PopoverController} from '@ionic/angular';

@Component({
    selector: 'app-language-list',
    templateUrl: './language-list.component.html',
    styleUrls: ['./language-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguageListComponent {

    @Input() currentLanguage: string;
    @Input() availableLanguages: string[];
    @Output() changeCurrentLanguage = new EventEmitter<string>();

    constructor(private readonly popoverController: PopoverController) {
    }

    changeLanguage(language: string): void {
        this.changeCurrentLanguage.emit(language);
        this.popoverController.dismiss().then();
    }
}
