import {ChangeDetectionStrategy, Component, Input, OnChanges, OnInit} from '@angular/core';
import {CurrencyAmount} from '../../../../domain/currency-amount';
import {Side} from '../../../../domain/side';
import {TradingRatePipe} from '../../../../pipes/rate/trading-rate';
import {InsightsViewType} from '../../../../domain/insights-view-type';
import {DecimalAmountPipe} from '../../../../pipes/decimal-input/decimal-input.pipe';

@Component({
    selector: 'app-bar-chart-side-card',
    templateUrl: './bar-chart-side-card.component.html',
    styleUrls: ['./bar-chart-side-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BarChartSideCardComponent implements OnInit, OnChanges {

    readonly TradingRatePipe = TradingRatePipe;
    readonly DecimalAmountPipe = DecimalAmountPipe;
    tradingCcyBuySide = false;
    contraCcyBuySide = false;
    isNetPosition: boolean;

    @Input() tradingCurrency: CurrencyAmount;
    @Input() contraCurrency: CurrencyAmount;
    @Input() averageRate: number;
    @Input() viewType: InsightsViewType;

    constructor() {
    }

    ngOnInit(): void {
        this.isNetPosition = this.viewType === InsightsViewType.NET_AMOUNTS;
        this.tradingCurrency.side === Side.BUY ? this.tradingCcyBuySide = true : this.tradingCcyBuySide = false;
        this.contraCurrency.side === Side.BUY ? this.contraCcyBuySide = true : this.contraCcyBuySide = false;
    }

    ngOnChanges(): void {
        this.isNetPosition = this.viewType === InsightsViewType.NET_AMOUNTS;
    }
}
