import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {hasValues, isDefined} from 'src/app/commons/utils';
import {SettlementOverviewFilter} from 'src/app/domain/filter/settlement-overview-filter';
import {AmountPerDay, SettlementCard} from 'src/app/domain/settlement-card';
import {FieldNames} from '../../../../commons/field-names';
import {HttpConstants} from '../../../../commons/http-constants';
import {BehaviorSubject, Observable, zip} from 'rxjs';
import {SettlementOverview} from '../../../../domain/settlement-overview';
import {HedgingProfileService} from '../../../../services/hedging-profile/hedging-profile.service';
import {DatePipe} from '@angular/common';
import {InsightsService} from '../../../../services/insights/insights.service';
import {DateTimeUtilService} from '../../../../services/date-time-util/date-time-util.service';
import {map, switchMap, tap} from 'rxjs/operators';
import {CompanyService} from 'src/app/services/company/company.service';
import {Company} from '../../../../domain/company';
import {Pageable} from '../../../../commons/pageable';
import {Sort, SortDirection} from '../../../../commons/sort';
import {DateOverview} from '../../../../domain/date-overview';
import {ModalOptions} from '@ionic/core';
import {ModalController} from '@ionic/angular';
import {
    SettlementOverviewDetailsModalComponent
} from '../../insights-details-modal/settlement-overview-details-modal/settlement-overview-details-modal.component';
import {DecimalAmountPipe} from '../../../../pipes/decimal-input/decimal-input.pipe';

@Component({
    selector: 'app-settlement-overview',
    templateUrl: './settlement-overview.component.html',
    styleUrls: ['./settlement-overview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettlementOverviewComponent implements OnInit {
    public readonly hasValues = hasValues;

    selectedSymbol: string = null;
    canScrollLeft = true;
    canScrollRight = true;
    settlementCards: SettlementCard[];
    settlementOverviewFilter = new SettlementOverviewFilter();
    datesLabel: string;

    private isDetailsModalVisible = false;
    private sort = FieldNames.HEDGE_DATE_FROM;
    private page = 0;
    private modalObject: HTMLIonModalElement;
    private readonly pageSize = HttpConstants.DEFAULT_PAGE_SIZE;
    private readonly NO_OF_DAYS = 3;

    $baseCurrency: Observable<string>;
    $initData: BehaviorSubject<void>;
    $companyChange: Observable<Company>;
    $settlementOverview: Observable<SettlementOverview>;
    $widgetData: Observable<[SettlementOverview, string]>;

    constructor(private readonly hedgingProfileService: HedgingProfileService,
                private readonly insightsService: InsightsService,
                private readonly companyService: CompanyService,
                private readonly etDate: DatePipe,
                private readonly etAmount: DecimalAmountPipe,
                private readonly modalController: ModalController) {
    }

    ngOnInit(): void {
        this.settlementOverviewFilter.hedgeDateFrom = DateTimeUtilService.formatToIsoDate(new Date());
        this.settlementOverviewFilter.noOfDays = this.NO_OF_DAYS;

        this.$initData = new BehaviorSubject<void>(undefined);
        this.$baseCurrency = this.$initData.pipe(
            switchMap(() => this.hedgingProfileService.getHedgingProfile()),
            map(hedgingProfile => hedgingProfile.baseCurrency)
        );
        this.$companyChange = this.companyService.onCompanyChange().pipe(
            tap(() => this.$initData.next())
        );

        this.getSettlementOverview();
    }

    public onCcyChange(currency: string): void {
        if (this.selectedSymbol !== currency) {
            this.selectedSymbol = currency;
            this.toggleSelectedCard(currency);
            this.scrollToSymbol(this.selectedSymbol);
            this.refreshScrollableState();
        }
    }

    toggleSelectedCard(symbol: string): void {
        this.selectedSymbol = symbol;
        this.scrollToSymbol(this.selectedSymbol);
        this.refreshScrollableState();
    }

    private scrollToSymbol(symbol: string): void {
        setTimeout(() => {
            const selectedCell = document.getElementById(symbol);
            if (selectedCell) {
                selectedCell.scrollIntoView({behavior: 'smooth', block: 'center'});
            }
        });
    }

    get selectedSymbolIndex(): number {
        return this.settlementCards
            .map(settlementOverview => settlementOverview.currency)
            .indexOf(this.selectedSymbol);
    }

    private refreshScrollableState(): void {
        if (isDefined(this.selectedSymbol)) {
            const selectedSymbolIndex = this.selectedSymbolIndex;
            this.canScrollLeft = selectedSymbolIndex > 0;
            this.canScrollRight = selectedSymbolIndex < this.settlementCards.length - 1;
        } else {
            this.canScrollLeft = hasValues(this.settlementCards);
            this.canScrollRight = hasValues(this.settlementCards);
        }
    }

    private computeSettlementCards(settlementOverview: SettlementOverview): void {
        this.settlementCards = settlementOverview.currencyOverviews.map(
            currencyOverview => {
                const settlementCard = new SettlementCard();
                settlementCard.currency = currencyOverview.currency;
                settlementCard.amounts = [];
                return settlementCard;
            }
        );

        settlementOverview.dateOverviews.forEach(
            dateOverview => {
                this.settlementCards.forEach(settlementCard => {
                    const position = dateOverview.dateOverviewPositions
                        .find(pos => pos.currency === settlementCard.currency);
                    const amountPerDay = new AmountPerDay();
                    amountPerDay.date = dateOverview.hedgeDatePeriod.startDate.toString();

                    if (isDefined(position)) {
                        amountPerDay.amount = this.etAmount
                            .transform(position.totalNetAmount, DecimalAmountPipe.AMOUNT_FORMAT_0_DIGITS);
                    } else {
                        amountPerDay.amount = '-';
                    }

                    settlementCard.amounts.push(amountPerDay);
                });
            }
        );
    }

    private getSettlementOverview(): void {
        const pageable: Pageable = {
            page: this.page,
            size: this.pageSize,
            sort: Sort.by(this.sort, SortDirection.DESC)
        };

        this.$settlementOverview = this.$initData.pipe(
            switchMap(() => this.insightsService.getSettlementOverviews(
                    this.settlementOverviewFilter,
                    pageable
                )
            ),
            tap(settlementOverview => this.computeSettlementCards(settlementOverview)),
            tap(settlementOverview => this.computeDatesLabel(settlementOverview.dateOverviews))
        );

        this.$widgetData = zip(this.$settlementOverview, this.$baseCurrency);
    }

    private computeDatesLabel(dateOverviews: DateOverview[]): void {
        this.datesLabel = `${this.etDate.transform(dateOverviews[0]?.hedgeDatePeriod.startDate)}
    - ${this.etDate.transform(dateOverviews[dateOverviews.length - 1]?.hedgeDatePeriod.startDate)}`;
    }

    async goToDetails(currency: string): Promise<void> {
        const settlementOverviewDetailsOptions: ModalOptions = {
            component: SettlementOverviewDetailsModalComponent,
            componentProps: {
                symbol: currency,
            }
        };
        if (!this.isDetailsModalVisible) {
            this.isDetailsModalVisible = true;
            this.modalObject = await this.modalController.create(settlementOverviewDetailsOptions);
            await this.modalObject.present();
            this.modalObject.onDidDismiss().then(() => this.isDetailsModalVisible = false);
        }
    }
}
