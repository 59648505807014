import {Pipe, PipeTransform} from '@angular/core';
import {ExpandableHedgedOrder} from '../../domain/expandable-hedged-order';

@Pipe({
    name: 'expandableHedgedOrder',
    pure: true
})
export class ExpandableHedgedOrderCastPipe implements PipeTransform {
    transform(value: any, args?: any): ExpandableHedgedOrder {
        return value;
    }
}
