export class HttpConstants {
    public static readonly BEARER = 'Bearer ';
    public static readonly CONTENT_TYPE_HEADER = 'Content-Type';
    public static readonly ACCESS_CONTROL_ALLOW_ORIGIN_HEADER = 'Access-Control-Allow-Origin';
    public static readonly ACCEPT = 'Accept';
    public static readonly APPLICATION_JSON = 'application/json';
    public static readonly FORM_URLENCODED = 'application/x-www-form-urlencoded';
    public static readonly AUTHORIZATION = 'Authorization';
    public static readonly AUTHORIZATION_BASIC = 'Basic ';
    public static readonly DEVICE_ID_HEADER_KEY = 'X-Device-Id';
    public static readonly VERSION = 'Version';
    public static readonly RECAPTCHA_TOKEN_HEADER_KEY = 'X-Recaptcha-Token';
    public static readonly TENANT_HEADER = 'X-Tenant';
    public static readonly INCLUDE_SUBTENANTS_HEADER = 'X-Include-Subtenants';

    public static readonly PAGE_PARAM = 'page';
    public static readonly PAGE_SIZE_PARAM = 'size';
    public static readonly PAGE_SORT_PARAM = 'sort';
    public static readonly DEFAULT_PAGE_SIZE = 1000;
}
