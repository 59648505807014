import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DateFormats} from 'src/app/domain/date-params';
import {SettlementCard} from '../../../../domain/settlement-card';

@Component({
  selector: 'app-bubble-chart-card',
  templateUrl: './bubble-chart-card.component.html',
  styleUrls: ['./bubble-chart-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BubbleChartCardComponent implements OnInit {

  public readonly DateFormats = DateFormats;

  @Input() settlementCard: SettlementCard;
  @Input() selected: boolean;
  @Output() selectSymbol = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  public onSymbolSelect(): void {
    this.selectSymbol.emit(this.settlementCard.currency);
  }
}
