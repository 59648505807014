import {Pipe, PipeTransform} from '@angular/core';
import {DecimalPipe} from '@angular/common';
import {ConfigurationService} from '../../services/configuration/configuration.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {isDefined} from '../../commons/utils';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
    name: 'forwardPoints'
})
export class ForwardPointsPipe implements PipeTransform {

    constructor(private decimalPipe: DecimalPipe,
                private configurationService: ConfigurationService,
                private translateService: TranslateService) {
    }

    transform(amount: number): Observable<string> {
        return this.configurationService.getForwardPointsDecimals().pipe(
            map(value => {
                return this.setAmountDigits(amount, isDefined(value) ? value : 2);
            })
        );
    }

    private setAmountDigits(amount: number, decimals: number): string {
        const digits = `1.${decimals}-${decimals}`;
        return this.decimalPipe.transform(amount, digits, this.translateService.getDefaultLang());
    }
}
