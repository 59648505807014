import {ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {PositionOverview} from '../../../../domain/position-overview';
import {InsightsService} from '../../../../services/insights/insights.service';
import {CurrencyAmountOverview} from '../../../../domain/currency-amount-overview';
import {CurrencyAmount} from '../../../../domain/currency-amount';
import {TranslateService} from '@ngx-translate/core';
import {
    PositionChartPeriodLabelPipe
} from '../../../../pipes/position-chart-period-label/position-chart-period-label.pipe';
import {ModalOptions} from '@ionic/core';
import {InsightsFilterComponent} from '../../components/insights-filter/insights-filter.component';
import {copy, isDefined} from '../../../../commons/utils';
import {ModalController} from '@ionic/angular';
import {PositionOverviewFilter} from '../../../../domain/filter/position-overview-filter';
import {Pageable} from '../../../../commons/pageable';
import {Sort, SortDirection} from '../../../../commons/sort';
import {HttpConstants} from '../../../../commons/http-constants';
import {InsightsViewType} from '../../../../domain/insights-view-type';
import {map, switchMap} from 'rxjs/operators';
import {InsightsPeriod} from '../../../../domain/insights-period';
import {HedgedOrderService} from '../../../../services/hedged-order/hedged-order.service';
import {HedgedOrderFilter} from '../../../../domain/filter/hedged-order-filter';
import {TimePeriod} from '../../../../domain/time-period';
import {Side} from '../../../../domain/side';
import {CompanyService} from '../../../../services/company/company.service';
import {BehaviorSubject, forkJoin, Observable} from 'rxjs';
import {FieldNames} from '../../../../commons/field-names';
import {DateFormatPipe} from '../../../../pipes/date-format/date-format.pipe';
import * as Highcharts from 'highcharts';
import {Chart, Options} from 'highcharts';
import {ChartHelper} from '../../../../utils/chart-helper';

@Component({
    selector: 'app-position-overview-details-modal',
    templateUrl: './position-overview-details-modal.component.html',
    styleUrls: ['./position-overview-details-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PositionOverviewDetailsModalComponent implements OnInit {

    private readonly pageSize = HttpConstants.DEFAULT_PAGE_SIZE;
    private readonly sort = FieldNames.TRADING_VOLUME_PERCENTAGE;
    private readonly HEDGE_DATE = 'hedgeDate';
    private readonly EXECUTION_DATE = 'executionDate';

    private isModalVisible = false;
    private modalObject: HTMLIonModalElement;
    private page = 0;
    private barChart: Chart;
    private chartLabels: string[] = [];

    viewType = InsightsViewType.NET_AMOUNTS;

    @Input() symbol: string;
    @Input() positionOverviewFilter: PositionOverviewFilter;
    @ViewChild('barChart', {static: true}) barChartCanvas: ElementRef;

    filterPeriods$: Observable<FilterPeriods>;
    positionOverview$: Observable<PositionOverview>;
    getPositionsOverview$: BehaviorSubject<InsightsPeriod>;

    positionOverview: PositionOverview;
    tradingCurrency: CurrencyAmountOverview;
    tradingCurrencyRate: number;
    contraCurrency: CurrencyAmountOverview;
    contraCurrencyRate: number;

    tradingCurrencyBuy: CurrencyAmount;
    tradingCurrencySell: CurrencyAmount;
    contraCurrencyBuy: CurrencyAmount;
    contraCurrencySell: CurrencyAmount;

    startDate: Date;
    endDate: Date;

    netAmounts = false;
    totalAmounts = false;

    tradingCcyBuyAmounts: number[] = [];
    tradingCcySellAmounts: number[] = [];
    contraCcyBuyAmounts: number[] = [];
    contraCcySellAmounts: number[] = [];

    tradingCcy: string;
    contraCcy: string;

    availableTenors: InsightsPeriod[] = [];
    activeTenor: InsightsPeriod;
    isChartEmpty = false;

    colors: string[] = [
        getComputedStyle(document.body).getPropertyValue('--chart-trading-currency-buy'),
        getComputedStyle(document.body).getPropertyValue('--chart-trading-currency-sell'),
        getComputedStyle(document.body).getPropertyValue('--chart-contra-currency-buy'),
        getComputedStyle(document.body).getPropertyValue('--chart-contra-currency-sell')
    ];
    readonly isDefined = isDefined;

    constructor(private readonly insightsService: InsightsService,
                private readonly hedgedOrderService: HedgedOrderService,
                private readonly positionChartPeriodLabelPipe: PositionChartPeriodLabelPipe,
                private readonly modalController: ModalController,
                private readonly chartHelper: ChartHelper) {
    }

    ngOnInit(): void {
        this.initFirstAndLastSettlementDate();
    }

    private initFirstAndLastSettlementDate(): void {
        const hedgedOrderFilter = new HedgedOrderFilter();
        hedgedOrderFilter.symbols = [this.symbol];

        this.filterPeriods$ = forkJoin([
            this.hedgedOrderService.getFirstHedgedOrderByField(this.HEDGE_DATE, hedgedOrderFilter),
            this.hedgedOrderService.getLatestHedgedOrderByField(this.HEDGE_DATE, hedgedOrderFilter),
            this.hedgedOrderService.getFirstHedgedOrderByField(this.EXECUTION_DATE, hedgedOrderFilter),
            this.hedgedOrderService.getLatestHedgedOrderByField(this.EXECUTION_DATE, hedgedOrderFilter)
        ]).pipe(
            map(
                ([
                     firstExpiringHedgedOrder,
                     lastExpiringHedgedOrder,
                     firstTradedHedgedOrder,
                     lastTradedHedgedOrder]) => {
                    return {
                        settlementPeriod: {
                            startDate: new Date(firstExpiringHedgedOrder.hedgeDate),
                            endDate: new Date(lastExpiringHedgedOrder.hedgeDate)
                        },
                        tradesPeriod: {
                            startDate: new Date(firstTradedHedgedOrder.executionDate),
                            endDate: new Date(lastTradedHedgedOrder.executionDate)
                        }
                    };
                })
        );

        this.getPositionsOverview();
    }

    calculateChartData(positionOverview: PositionOverview): void {
        [this.tradingCurrency, this.contraCurrency] = positionOverview.sideAmount;
        [this.tradingCurrencyBuy, this.contraCurrencySell] = this.tradingCurrency.currencyAmounts;
        [this.contraCurrencyBuy, this.tradingCurrencySell] = this.contraCurrency.currencyAmounts;
        this.contraCurrencySell.amount = -this.contraCurrencySell.amount;
        this.tradingCurrencySell.amount = -this.tradingCurrencySell.amount;
        this.tradingCurrencyRate = this.tradingCurrency.averageRate;
        this.contraCurrencyRate = this.contraCurrency.averageRate;
        this.getTotalPeriodForDetails(positionOverview);
        [this.tradingCcy, this.contraCcy] = positionOverview.symbol.split('/');
        this.resetChartDataAndLabels();
        this.getCcyAmountsData(this.viewType, positionOverview);
        if (this.viewType === InsightsViewType.NET_AMOUNTS) {
            this.netAmounts = true;
            this.colors[1] = getComputedStyle(document.body).getPropertyValue('--chart-trading-currency-buy');
            this.colors[3] = getComputedStyle(document.body).getPropertyValue('--chart-contra-currency-buy');
        } else {
            this.totalAmounts = true;
        }
        if (isDefined(this.barChart)) {
            this.barChart.destroy();
        }
        this.createBarChart();
    }

    resetChartDataAndLabels(): void {
        this.chartLabels = [];
        this.tradingCcyBuyAmounts = [];
        this.tradingCcySellAmounts = [];
        this.contraCcySellAmounts = [];
        this.contraCcyBuyAmounts = [];
        this.netAmounts = false;
        this.totalAmounts = false;
        this.colors = [
            getComputedStyle(document.body).getPropertyValue('--chart-trading-currency-buy'),
            getComputedStyle(document.body).getPropertyValue('--chart-trading-currency-sell'),
            getComputedStyle(document.body).getPropertyValue('--chart-contra-currency-buy'),
            getComputedStyle(document.body).getPropertyValue('--chart-contra-currency-sell')
        ];
    }

    emitGoBackEvent(): void {
        this.modalController.dismiss().then();
    }

    getTotalPeriodForDetails(positionOverview: PositionOverview): void {
        this.startDate = positionOverview.positions[0].timePeriod.startDate;
        this.endDate = positionOverview.positions[positionOverview.positions.length - 1].timePeriod.endDate;
    }

    getCcyAmountsData(viewType: InsightsViewType, positionOverview: PositionOverview): void {
        positionOverview.positions.forEach(position => {
            const label = this.positionChartPeriodLabelPipe.transform(position.timePeriod.startDate, this.activeTenor);
            const translatedLabel = this.chartHelper.getTranslationsForChartLabels(label, this.activeTenor);
            this.chartLabels.push(translatedLabel);
            position.amounts.forEach(amount => {
                amount.currencyAmounts.forEach((ccyAmount, index) =>
                    viewType === InsightsViewType.NET_AMOUNTS
                        ? this.fillNetPositionChartData(ccyAmount, index)
                        : this.fillTotalAmountsChartData(ccyAmount));
            });
        });
    }

    private fillTotalAmountsChartData(ccyAmount: CurrencyAmount): void {
        if (ccyAmount.currency === this.tradingCcy && ccyAmount.side === Side.BUY) {
            ccyAmount.amount === 0
                ? this.tradingCcyBuyAmounts.push(null)
                : this.tradingCcyBuyAmounts.push(ccyAmount.amount);
        }
        if (ccyAmount.currency === this.tradingCcy && ccyAmount.side === Side.SELL) {
            ccyAmount.amount === 0
                ? this.tradingCcySellAmounts.push(null)
                : this.tradingCcySellAmounts.push(-ccyAmount.amount);
        }
        if (ccyAmount.currency === this.contraCcy && ccyAmount.side === Side.BUY) {
            ccyAmount.amount === 0
                ? this.contraCcyBuyAmounts.push(null)
                : this.contraCcyBuyAmounts.push(ccyAmount.amount);
        }
        if (ccyAmount.currency === this.contraCcy && ccyAmount.side === Side.SELL) {
            ccyAmount.amount === 0
                ? this.contraCcySellAmounts.push(null)
                : this.contraCcySellAmounts.push(-ccyAmount.amount);
        }
    }

    private fillNetPositionChartData(ccyAmount: CurrencyAmount, index: number): void {
        if (ccyAmount.amount === 0) {
            if (index === 0) {
                this.tradingCcyBuyAmounts.push(null);
                this.tradingCcySellAmounts.push(null);
            } else {
                this.contraCcySellAmounts.push(null);
                this.contraCcyBuyAmounts.push(null);
            }
        }
        if (ccyAmount.currency === this.tradingCcy && ccyAmount.amount > 0) {
            this.tradingCcyBuyAmounts.push(ccyAmount.amount);
            this.tradingCcySellAmounts.push(null);
        }
        if (ccyAmount.currency === this.tradingCcy && ccyAmount.amount < 0) {
            this.tradingCcySellAmounts.push(ccyAmount.amount);
            this.tradingCcyBuyAmounts.push(null);
        }
        if (ccyAmount.currency === this.contraCcy && ccyAmount.amount > 0) {
            this.contraCcyBuyAmounts.push(ccyAmount.amount);
            this.contraCcySellAmounts.push(null);
        }
        if (ccyAmount.currency === this.contraCcy && ccyAmount.amount < 0) {
            this.contraCcySellAmounts.push(ccyAmount.amount);
            this.contraCcyBuyAmounts.push(null);
        }
    }

    createBarChart(): void {
        const classNames = this.viewType === InsightsViewType.NET_AMOUNTS
            ? ['contra-currency-buy', 'contra-currency-buy', 'trading-currency-buy', 'trading-currency-buy']
            : ['contra-currency-buy', 'contra-currency-sell', 'trading-currency-buy', 'trading-currency-sell'];

        const options: Options = this.chartHelper.getPositionBarChartOptions(
            this.tradingCcyBuyAmounts,
            this.tradingCcySellAmounts,
            this.contraCcyBuyAmounts,
            this.contraCcySellAmounts,
            this.tradingCcy,
            this.contraCcy,
            this.chartLabels,
            classNames,
            this.colors,
            this.chartHelper.getBarChartNumberFormatter()
        );
        this.barChart = Highcharts.chart(this.barChartCanvas.nativeElement, options);
    }

    async openFilters(): Promise<void> {
        const filtersModalOptions: ModalOptions = {
            component: InsightsFilterComponent,
            componentProps: {
                positionOverviewFilter: copy(this.positionOverviewFilter),
                viewType: this.viewType,
                showViewType: true
            }
        };

        if (!this.isModalVisible) {
            this.isModalVisible = true;
            this.modalObject = await this.modalController.create(filtersModalOptions);
            this.modalObject.onDidDismiss().then(modalData => {
                if (isDefined(modalData.data)) {
                    this.positionOverviewFilter = modalData.data.positionOverviewFilter;
                    this.viewType = modalData.data.viewType;
                    this.getPositionsOverview$.next(null);
                }
                this.isModalVisible = false;
            });
            await this.modalObject.present();
        }
    }

    private getPositionsOverview(): void {
        this.getPositionsOverview$ = new BehaviorSubject<InsightsPeriod>(this.activeTenor);
        this.isChartEmpty = false;
        const pageable: Pageable = {
            page: this.page,
            size: this.pageSize,
            sort: Sort.by(this.sort, SortDirection.DESC)
        };
        this.positionOverviewFilter.companyCodes = [CompanyService.getCurrentCompany().code];
        this.positionOverview$ = this.getPositionsOverview$.pipe(
            switchMap(activeTenor => this.insightsService
                .getPositionsOverview(this.positionOverviewFilter, pageable, this.viewType, activeTenor)
                .pipe(
                    map(page => page.content),
                    map((positionOverviews: PositionOverview[]) => positionOverviews
                        .find(positionOverview => positionOverview.symbol === this.symbol)
                    ),
                    map(positionOverview => this.processPositionOverview(positionOverview))
                )
            )
        );
    }

    private setAvailableTenors(positionOverview: PositionOverview): void {
        const startDate = new Date(isDefined(this.positionOverviewFilter.hedgeDateFrom)
            ? this.positionOverviewFilter.hedgeDateFrom
            : positionOverview.hedgeDatePeriod.startDate);

        const endDate = new Date(isDefined(this.positionOverviewFilter.hedgeDateTo)
            ? this.positionOverviewFilter.hedgeDateTo
            : positionOverview.hedgeDatePeriod.endDate);

        const timePeriod: TimePeriod = {
            startDate,
            endDate
        };
        this.availableTenors = this.timePeriodToTenors(timePeriod, positionOverview);
    }

    private timePeriodToTenors(timePeriod: TimePeriod, positionOverview: PositionOverview): InsightsPeriod[] {
        const differenceInTime = timePeriod.endDate.getTime() - timePeriod.startDate.getTime();
        const differenceInDays = differenceInTime / (1000 * 3600 * 24) + 1;

        if (differenceInDays < 8) {
            this.activeTenor = InsightsPeriod.DAY;
            return [InsightsPeriod.DAY];
        } else if (differenceInDays > 7 && differenceInDays < 32) {
            this.activeTenor = InsightsPeriod.WEEK;
            return [InsightsPeriod.WEEK];
        } else if (differenceInDays > 31 && differenceInDays < 93) {
            const tenors = [InsightsPeriod.WEEK, InsightsPeriod.MONTH];
            positionOverview.positions[0].insightsPeriod === InsightsPeriod.WEEK
                ? this.activeTenor = InsightsPeriod.WEEK
                : this.activeTenor = InsightsPeriod.MONTH;
            return tenors;
        } else if (differenceInDays > 92 && differenceInDays < 366) {
            const tenors = [InsightsPeriod.MONTH, InsightsPeriod.QUARTER];
            positionOverview.positions[0].insightsPeriod === InsightsPeriod.MONTH
                ? this.activeTenor = InsightsPeriod.MONTH
                : this.activeTenor = InsightsPeriod.QUARTER;
            return tenors;
        } else {
            const tenors = [InsightsPeriod.QUARTER, InsightsPeriod.YEAR];
            positionOverview.positions[0].insightsPeriod === InsightsPeriod.QUARTER
                ? this.activeTenor = InsightsPeriod.QUARTER
                : this.activeTenor = InsightsPeriod.YEAR;
            return tenors;
        }
    }

    private crateZeroAmountChartData(): void {
        this.resetChartDataAndLabels();
        if (isDefined(this.barChart)) {
            this.barChart.destroy();
        }
    }

    onTenorSelect(tenor: InsightsPeriod): void {
        this.activeTenor = tenor;
        this.getPositionsOverview$.next(this.activeTenor);
    }

    processPositionOverview(positionOverview: PositionOverview): PositionOverview {
        if (isDefined(positionOverview)) {
            this.isChartEmpty = false;
            this.setAvailableTenors(positionOverview);
            this.calculateChartData(positionOverview);
        } else {
            this.crateZeroAmountChartData();
            this.isChartEmpty = true;
            positionOverview = new PositionOverview();
            positionOverview.symbol = this.positionOverview.symbol;
            positionOverview.tradesCount = 0;
        }
        this.positionOverview = positionOverview;
        return positionOverview;
    }
}

export class FilterPeriods {
    settlementPeriod: TimePeriod;
    tradesPeriod: TimePeriod;
}
