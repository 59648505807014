import {Injectable} from '@angular/core';
import {Currency} from '../../domain/currency';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {HttpUtils} from '../../commons/http/http-utils';
import {tap} from 'rxjs/operators';
import {CompanyService} from '../company/company.service';
import {isDefined} from '../../commons/utils';
import {FirebaseService} from '../firebase/firebase.service';
import {Page} from '../../commons/page';
import {Pageable} from '../../commons/pageable';

@Injectable({
    providedIn: 'root'
})
export class CurrenciesService {

    private tradingCurrenciesMap = new Map<string, Currency[]>();
    private contraCurrenciesMap = new Map<string, Currency[]>();

    constructor(public http: HttpClient) {
    }

    public getTradingCurrencies(): Observable<Currency[]> {
        const currentCompany = CompanyService.getCurrentCompany().code;
        if (this.tradingCurrenciesMap.has(currentCompany)) {
            return of(this.tradingCurrenciesMap.get(currentCompany));
        } else {
            const url = `${FirebaseService.getApiBasePath()}/api/global/manual-trades/trading-currencies`;
            return this.http.get <Currency[]>(url, HttpUtils.getRequestOptions())
                .pipe(tap(currencies => this.tradingCurrenciesMap.set(currentCompany, currencies)));
        }
    }

    public getContraCurrencies(): Observable<Currency[]> {
        const currentCompany = CompanyService.getCurrentCompany().code;
        if (this.contraCurrenciesMap.has(currentCompany)) {
            return of(this.contraCurrenciesMap.get(currentCompany));
        } else {
            const url = `${FirebaseService.getApiBasePath()}/api/global/manual-trades/contra-currencies`;
            return this.http.get <Currency[]>(url, HttpUtils.getRequestOptions())
                .pipe(tap(currencies => this.contraCurrenciesMap.set(currentCompany, currencies)));
        }
    }

    public getCurrencies(pageable?: Pageable): Observable<Page<Currency>> {
        const url = `${FirebaseService.getApiBasePath()}/api/global/currencies`;
        const options = isDefined(pageable)
            ? HttpUtils.getRequestOptionsWithPaging(pageable)
            : HttpUtils.getRequestOptions();

        return this.http.get<Page<Currency>>(url, options);
    }

    public clearCache(): void {
        this.tradingCurrenciesMap = new Map<string, Currency[]>();
        this.contraCurrenciesMap = new Map<string, Currency[]>();
    }

    getAvailableManualTradeCurrencies(includeBaseCurrency: boolean): Observable<Currency[]> {
        const url = `${FirebaseService.getApiBasePath()}/api/global/currencies/manual-trade/available`;
        const params = {
            tenant: CompanyService.getCurrentCompany().code,
            includeBaseCurrency: includeBaseCurrency
        };

        return this.http.get<Currency[]>(url, HttpUtils.getRequestOptionsWithParameters(params));
    }
}
