import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {HttpUtils} from '../http-utils';
import {FirebaseService} from '../../../services/firebase/firebase.service';

@Injectable()
export class GlobalTenantInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const isResourceRequest = HttpUtils.isLocalResourceRequest(req);
        const isTokenRequest = req.url.indexOf(FirebaseService.getIssuer()) >= 0;
        const isExcludedGlobalTenant = FirebaseService.isExcludedGlobalTenant();
        const globalTenantAPI = `/global/`;

        if (!isResourceRequest && !isTokenRequest && isExcludedGlobalTenant) {
            let updatedReq = req;
            if (req.url.includes(globalTenantAPI)) {
                updatedReq = updatedReq.clone({
                    url: req.url.replace(globalTenantAPI, '/')
                });
            }
            return next.handle(updatedReq);
        }
        return next.handle(req);
    }

}
