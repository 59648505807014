import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UserFeedback} from '../../domain/user-feedback';
import {HttpUtils} from '../../commons/http/http-utils';
import {FirebaseService} from '../firebase/firebase.service';

@Injectable({
    providedIn: 'root'
})
export class UserFeedbackService {

    private readonly url = `${FirebaseService.getApiBasePath()}/api/global/user-feedbacks`;

    constructor(private readonly httpClient: HttpClient) {
    }

    insert(userFeedback: UserFeedback): Observable<UserFeedback> {
        return this.httpClient.post<UserFeedback>(this.url, userFeedback, HttpUtils.getDefaultRequestOptions());
    }

    update(userFeedback: UserFeedback): Observable<UserFeedback> {
        return this.httpClient.put<UserFeedback>(this.url, userFeedback, HttpUtils.getDefaultRequestOptions());
    }
}
