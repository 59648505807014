<ion-row class="qr-login-icon">
  <ion-col class="ion-text-center">
    <ion-img src="assets/icon/qr-web-login.svg" class="qr-icon"/>
  </ion-col>
</ion-row>
<ion-row>
  <ion-col>
    <p class="web-hint ion-text-center">
      {{'extra-page.qr-code.web-login-hint' | translate: {appName: applicationSettings.applicationName} }}
    </p>
  </ion-col>
</ion-row>
<ion-row class="pin-hint ion-text-center">
  <ion-col>
    <p>{{'extra-page.qr-code.pin-hint' | translate}}</p>
  </ion-col>
</ion-row>
