<ion-row class="ion-justify-content-center">
    <ion-col *ngIf="regulatoryDocs?.kidUrl"
             size="3"
             class="center-items"
             (click)="openLink(regulatoryDocs.kidUrl)">
        <ion-icon class="icon"
                  src="assets/icon/icon-kid.svg"/>
        {{ 'manual-trade.kid' | translate }}
    </ion-col>
    <ion-col *ngIf="regulatoryDocs?.kotraUrl"
             size="3"
             class="center-items"
             (click)="openLink(regulatoryDocs.kotraUrl)">
        <ion-icon class="icon"
                  src="assets/icon/icon-kotra.svg"/>
        {{ 'manual-trade.kotra' | translate }}
    </ion-col>
</ion-row>
