import {PlatformEnum} from '../platform';

export abstract class BaseReportFilter {
    initialBatchId?: string;
    requestId?: string;
    legRefId?: string;
    legRefIds?: string[];
    hedgeDateFrom?: string;
    hedgeDateTo?: string;
    successfullyHedged?: boolean;
    hedgingPlatform?: PlatformEnum;
    sides?: string[];
    contraCurrencies?: string[];
    tradingCurrencies?: string[];
    currencies?: string[];
    companyCodes?: string[];
}
