import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {LoadingSpinnerService} from '../../../services/loading-service/loading-spinner.service';
import {catchError, finalize, tap} from 'rxjs/operators';
import * as uuid from 'uuid';
import {forwardRef, Inject, Injectable} from '@angular/core';

@Injectable()
export class RequestLoadingSpinnerInterceptor implements HttpInterceptor {

    constructor(@Inject(forwardRef(() => LoadingSpinnerService))
                private loadingService: LoadingSpinnerService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const requestId = uuid.v4();
        this.loadingService.show(requestId);
        return next.handle(req).pipe(
            tap(() => this.loadingService.hide(requestId)),
            catchError(response => {
                this.loadingService.hide(requestId);
                return throwError(response);
            }),
            finalize(() => this.loadingService.hide(requestId))
        );
    }
}
