export class FieldNames {
    public static readonly ABOVE_RATE_THRESHOLD = 'aboveRateThreshold';
    public static readonly AMOUNT = 'amount';
    public static readonly BELOW_RATE_THRESHOLD = 'belowRateThreshold';
    public static readonly CAMPAIGN_NAME = 'campaignName';
    public static readonly CONTRA_CURRENCY = 'contraCurrency';
    public static readonly CREATED_DATE = 'createdDate';
    public static readonly DATA_PLATFORM = 'dataPlatform';
    public static readonly DUE_DATE = 'dueDate';
    public static readonly FAR_LEG = 'farLeg';
    public static readonly FEEDBACK = 'feedback';
    public static readonly FORECAST = 'forecast';
    public static readonly HEDGE_DATE_FROM = 'hedgeDateFrom';
    public static readonly HEDGE_DATE_TO = 'hedgeDateTo';
    public static readonly INITIAL_BATCH_ID = 'initialBatchId';
    public static readonly PRODUCT = 'product';
    public static readonly LEG_SOURCE = 'legSource';
    public static readonly MANUAL_POSITION = 'manualPosition';
    public static readonly MANUAL_TRADE = 'manualTrade';
    public static readonly METRIC = 'metric';
    public static readonly NEAR_LEG = 'nearLeg';
    public static readonly NOTIFY_ABOVE = 'notifyAbove';
    public static readonly NOTIFY_BELOW = 'notifyBelow';
    public static readonly OPPOSITE_TRADING_ACTION = 'oppositeTradingAction';
    public static readonly PAYMENT_METHOD = 'paymentMethod';
    public static readonly REFERENCE_ID = 'referenceId';
    public static readonly REMARKS = 'remarks';
    public static readonly ROLLOVER = 'rollover';
    public static readonly TENOR = 'tenor';
    public static readonly TRADE_PLANNING = 'tradePlanning';
    public static readonly TRADING_ACTION = 'tradingAction';
    public static readonly TRADING_CURRENCY = 'tradingCurrency';
    public static readonly UNEVEN_SWAP = 'unevenSwap';
    public static readonly USER_ID = 'userId';
    public static readonly USE_SETTLEMENT_INSTRUCTIONS = 'useSettlementInstructions';
    public static readonly TRADING_CURRENCY_ACCOUNT = 'tradingCurrencyAccount';
    public static readonly CONTRA_CURRENCY_ACCOUNT = 'contraCurrencyAccount';
    public static readonly TRADES_COUNT_PERCENTAGE = 'tradesCountPercentage';
    public static readonly TRADING_VOLUME_PERCENTAGE = 'tradingVolumePercentage';
    public static readonly ENABLED = 'enabled';
    public static readonly ACTIVE = 'active';
    public static readonly TYPE = 'type';
    public static readonly CHANNELS = 'channels';
    public static readonly READ_ONLY = 'readOnly';
    public static readonly EMAIL = 'email';
    public static readonly EMAIL_CONSENT = 'emailConsent';
    public static readonly WINDOW_START_DATE = 'windowStartDate';
    public static readonly WINDOW_TENOR = 'windowTenor';
    public static readonly USE_WINDOW_FORWARD = 'useWindowForward';
}
