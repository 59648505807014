import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Tenors} from '../../domain/tenors';

@Injectable({
    providedIn: 'root'
})
export class ActiveTenorService {
    private tenorChange = new BehaviorSubject(Tenors.ONE_DAY);

    constructor() {
    }

    onTenorChange(): Observable<Tenors> {
        return this.tenorChange.asObservable();
    }

    changeMessage(message: Tenors): void {
        this.tenorChange.next(message);
    }
}
