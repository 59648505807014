export enum Product {
    SPOT = 'SPOT',
    FORWARD = 'FORWARD',
    SWAP = 'SWAP',
    SWAP_AFTER = 'SWAP_AFTER', SWAP_BEFORE = 'SWAP_BEFORE',
    HRR_AFTER = 'HRR_AFTER', HRR_BEFORE = 'HRR_BEFORE',
    SWAP_EXT = 'SWAP_EXT', SWAP_ET = 'SWAP_ET',
    HR_EXT = 'HR_EXT', HR_ET = 'HR_ET',
    MR_EXT = 'MR_EXT', MR_ET = 'MR_ET',
    DRAWDOWN = 'DRAWDOWN',
    WINDOW_FORWARD = 'WINDOW_FORWARD',
}

export const getExtensions = (): Product[] => {
    return [Product.SWAP_EXT, Product.MR_EXT, Product.HR_EXT];
};

export const getEarlyTakeUps = (): Product[] => {
    return [Product.SWAP_ET, Product.MR_ET, Product.HR_ET];
};

const BasicProducts = [Product.SPOT, Product.SWAP, Product.FORWARD];
export const EarlyTakeUps = getEarlyTakeUps();
export const Extensions = getExtensions();

const matchingProducts: { [key: string]: Product[] } = {};
matchingProducts[Product.SWAP_ET] = [Product.SWAP_EXT];
matchingProducts[Product.SWAP_EXT] = [Product.SWAP_ET];
matchingProducts[Product.HR_ET] = [Product.HR_EXT, Product.MR_EXT];
matchingProducts[Product.HR_EXT] = [Product.HR_ET, Product.MR_ET];
matchingProducts[Product.MR_ET] = [Product.HR_EXT, Product.MR_EXT];
matchingProducts[Product.MR_EXT] = [Product.HR_ET, Product.MR_ET];

export const isBasicProduct = (product: Product): boolean => {
    return BasicProducts.includes(product);
};

export const isRolloverProduct = (product: Product): boolean => {
    return !isBasicProduct(product);
};

export function getRollableProducts(): Product[] {
    return [
        Product.SPOT,
        Product.SWAP,
        Product.FORWARD,
        Product.SWAP_EXT,
        Product.SWAP_ET,
        Product.HR_EXT,
        Product.HR_ET,
        Product.MR_EXT,
        Product.MR_ET,
        Product.WINDOW_FORWARD
    ];
}

export const isValidProductSelection = (products: Product[]): boolean => {
    const rolloverProducts = products.filter(isRolloverProduct);
    if (rolloverProducts.length === 2) {
        const first = rolloverProducts[0];
        const second = rolloverProducts[1];
        return (EarlyTakeUps.includes(first) && Extensions.includes(second))
            || (EarlyTakeUps.includes(second) && Extensions.includes(first));
    }
    return rolloverProducts.length === 1;
};

