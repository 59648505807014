import {BaseReportFilter} from './base-report-filter';
import {HedgingFlow} from '../hedging-flow';
import {PlatformEnum} from '../platform';
import {Side} from '../side';
import {Product} from '../product';
import {TradeVersion} from '../trade-version';

export class HedgedOrderFilter extends BaseReportFilter {
    enabled = true;
    companyCodes?: string[];
    initialBatchId?: string;
    executionDateFrom?: string;
    executionDateTo?: string;
    hedgeDateFrom?: string;
    hedgeDateTo?: string;
    hedgingPlatform?: PlatformEnum;
    dataSource?: string;
    hedgerId?: string;
    currencies?: string[];
    symbols?: string[];
    sides?: Side[];
    hedgingFlows?: HedgingFlow[];
    originalHedgingFlows?: HedgingFlow[];
    products?: Product[];
    keyword?: string;
    successfullyHedged?: boolean;
    legRefIds?: string[];
    version = TradeVersion.ORIGINAL;
}
