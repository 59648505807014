export const environment = {
    production: false,
    enableSSLPinning: false,
    idleTimeInSec: 90,
    appName: 'Easy FX Acc',
    platform: "ios",
    apiVersion: '2.15.0',
    defaultAppLanguage: 'nl',
    iamClientID: 'trup-mobile',
    logLevel: 0,
    serverLogLevel: 2,
    androidStoreLink: 'https://play.google.com/apps/internaltest/4701673088319771442',
    iosStoreLink: 'https://testflight.apple.com',
    iosBundleId: 'com.easytradefx.rabo.acc',
    androidBundleId: 'com.easytradefx.rabo.acc',
    oneSignal: {
        apiKeyIos: '',
        apiKeyAndroid: ''
    },
    firebase: {
        apiKey: 'AIzaSyA4nuRu1AbzxL7_AwAjFzsrE7hEm__xT4Y',
        authDomain: 'rabo-easyfx-acc.firebaseapp.com',
        databaseURL: 'https://rabo-easyfx-acc.firebaseio.com',
        projectId: 'rabo-easyfx-acc',
        storageBucket: 'gs://rabo-easyfx-acc.appspot.com',
        messagingSenderId: '149102136903'
    },
    base: {
        apiBasePath: 'https://login-acc.easytrade-fx.com',
        webSocketPath: 'wss://login-acc.easytrade-fx.com/websocket',
        iamIssuerURL: 'https://login-acc.easytrade-fx.com/auth/realms/trup1',
        appLink: 'nl.rabo.acc',
        excludeGlobalTenant: 'true',
        authFlows: 'CREDENTIALS, QR_LOGIN, LOGIN_WITH_CODE'
    },
    alt: {
        apiBasePath: 'https://login-acc.easytrade-fx.com',
        webSocketPath: 'wss://login-acc.easytrade-fx.com/websocket',
        iamIssuerURL: 'https://login-acc.easytrade-fx.com/auth/realms/trup1',
        appLink: 'nl.rabo.acc',
        excludeGlobalTenant: 'true',
        authFlows: 'CREDENTIALS, QR_LOGIN, LOGIN_WITH_CODE'
    }
};
