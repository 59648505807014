<ion-item>
    <ion-grid>
        <ion-row (click)="toggleExpand()">
            <ion-col size="6" class="ion-text-start"
                     [class.bold]="isFilterActive">
                {{filterNameKey | translate}}
            </ion-col>
            <ion-col size="5" class="ion-text-end">
                <span *ngIf="clearable && (dateFrom || dateTo)"
                      class="font-color-light"
                      (click)="clearFilter($event)">
                    {{'reports.clear' | translate}}
                </span>
            </ion-col>
            <ion-col size="1" class="ion-text-end">
                <ion-icon src="assets/icon/icon-expand-arrow.svg" [class.vertical-flip]="isExpanded"></ion-icon>
            </ion-col>
        </ion-row>
        <div *ngIf="isExpanded">
            <ion-row>
                <ion-col size="6">
                    <app-date-input [placeholder]="'rollover.from'"
                                    [minDate]="minDateFrom"
                                    [maxDate]="maxDateFrom"
                                    [date]="dateFrom"
                                    (changeDate)="onDateFromChanged($event)">
                    </app-date-input>
                </ion-col>
                <ion-col size="6">
                    <app-date-input [placeholder]="'rollover.to'"
                                    [minDate]="minDateTo"
                                    [maxDate]="maxDateTo"
                                    [date]="dateTo"
                                    (changeDate)="onDateToChanged($event)">
                    </app-date-input>
                </ion-col>
            </ion-row>
        </div>
    </ion-grid>
</ion-item>
