import {Pipe, PipeTransform} from '@angular/core';
import {Tenors} from '../../domain/tenors';
import {DateFormatPipe} from '../date-format/date-format.pipe';
import {DateFormats, Timezones} from '../../domain/date-params';

@Pipe({
    name: 'chartDate',
})
export class ChartDataPipe implements PipeTransform {

    constructor(private dateFormat: DateFormatPipe) {
    }

    transform(date: Date | string,
              activeTenor: Tenors,
              format: DateFormats = DateFormats.DAY_OF_WEEK_MONTH_YEAR): string {
        if (activeTenor === Tenors.ONE_DAY) {
            return this.dateFormat.transform(date, DateFormats.HOUR_MINUTE);
        }

        if (activeTenor === Tenors.FIVE_YEARS || activeTenor === Tenors.THREE_YEARS) {
            return this.dateFormat.transform(date, DateFormats.DAY_FULL_MONTH_YEAR, Timezones.UTC);
        }

        return this.dateFormat.transform(date, format, Timezones.UTC);
    }
}
