import {Observable, throwError} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {EtHttpService} from '../../../services/et-http/et-http.service';
import {environment} from '../../../../environments/environment';
import {HttpUtils} from '../http-utils';

@Injectable()
export class SslPinningRequestInterceptor implements HttpInterceptor {

    constructor(private etHttp: EtHttpService) {
    }

    private static createSslNotConfiguredError(request: HttpRequest<any>): HttpErrorResponse {
        return new HttpErrorResponse({
            error: 'Error processing request. Http client not configured.',
            status: 500,
            statusText: 'Error processing request. Http client not configured.',
            url: request.url
        });
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const isResourceRequest = HttpUtils.isLocalResourceRequest(req);
        if (environment.enableSSLPinning && !isResourceRequest) {
            if (this.etHttp.isSslPinnedModeEnabled()) {
                return this.etHttp.request(req);
            } else {
                return throwError(SslPinningRequestInterceptor.createSslNotConfiguredError(req));
            }
        } else {
            return next.handle(req);
        }
    }
}
