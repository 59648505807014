import {Injectable} from '@angular/core';
import {SocialSharing} from '@awesome-cordova-plugins/social-sharing/ngx';

@Injectable({providedIn: 'root'})
export class SocialShareService {

    isSharing = false;

    constructor(private readonly socialSharing: SocialSharing) {
    }

    shareScreenshot(filePath: string, fileName: string): void {
        this.isSharing = true;
        this.socialSharing.share(undefined, undefined, `${filePath}${fileName}`).then(() => this.isSharing = false);
    }
}
