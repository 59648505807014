export enum Tenors {
    ONE_DAY = 'ONE_DAY',
    ONE_WEEK = 'ONE_WEEK',
    ONE_MONTH = 'ONE_MONTH',
    THREE_MONTHS = 'THREE_MONTHS',
    SIX_MONTHS = 'SIX_MONTHS',
    ONE_YEAR = 'ONE_YEAR',
    THREE_YEARS = 'THREE_YEARS',
    FIVE_YEARS = 'FIVE_YEARS'
}
