import {HttpHeaders, HttpParams, HttpRequest} from '@angular/common/http';
import {HttpConstants} from '../http-constants';
import {Dictionary} from '../dictionary';
import {isDefined} from '../utils';
import {Pageable} from '../pageable';
import {CompanyService} from '../../services/company/company.service';

export class HttpUtils {

    public static getRequestOptions(includeSubtenants: boolean = false, tenant?: string): HttpOptions {
        let headers = new HttpHeaders()
            .set(HttpConstants.CONTENT_TYPE_HEADER, HttpConstants.APPLICATION_JSON)
            .set(HttpConstants.ACCEPT, HttpConstants.APPLICATION_JSON);
        const requestTenant = this.computeRequestTenant(tenant);
        if (isDefined(requestTenant)) {
            headers = headers.set(HttpConstants.TENANT_HEADER, requestTenant);
            headers = headers.set(HttpConstants.INCLUDE_SUBTENANTS_HEADER, `${includeSubtenants}`);
        }
        return {headers: headers};
    }

    private static computeRequestTenant(tenant?: string): string | null {
        const currentTenant = isDefined(CompanyService.getCurrentCompany())
            ? CompanyService.getCurrentCompany().code
            : null;
        if (isDefined(tenant)) {
            return tenant;
        } else if (isDefined(currentTenant)) {
            return currentTenant;
        }
        return null;
    }

    public static getDefaultRequestOptions(): HttpOptions {
        const headers = new HttpHeaders()
            .set(HttpConstants.CONTENT_TYPE_HEADER, HttpConstants.APPLICATION_JSON)
            .set(HttpConstants.ACCEPT, HttpConstants.APPLICATION_JSON);
        return {headers: headers};
    }

    public static getDefaultRequestOptionsWithUrlEncoded(): HttpOptions {
        const headers = new HttpHeaders()
            .set(HttpConstants.CONTENT_TYPE_HEADER, HttpConstants.FORM_URLENCODED)
            .set(HttpConstants.ACCEPT, HttpConstants.APPLICATION_JSON);
        return {headers: headers};
    }

    public static getLoginRequestOptions(clientId: string, secret: string): HttpOptions {
        const headers = new HttpHeaders()
            .set(HttpConstants.CONTENT_TYPE_HEADER, HttpConstants.FORM_URLENCODED)
            .set(HttpConstants.ACCEPT, HttpConstants.APPLICATION_JSON)
            .set(HttpConstants.AUTHORIZATION, HttpConstants.AUTHORIZATION_BASIC + btoa(clientId + ':' + secret));
        return {headers: headers};
    }

    public static getRequestOptionsWithPaging(pageable: Pageable): HttpOptions {
        let params = new HttpParams()
            .set(HttpConstants.PAGE_PARAM, pageable.page.toString())
            .set(HttpConstants.PAGE_SIZE_PARAM, pageable.size.toString());
        pageable.sort.format().forEach(it => params = params.append(HttpConstants.PAGE_SORT_PARAM, it));

        const options = this.getRequestOptions();
        options.params = params;

        return options;
    }

    public static getRequestOptionsWithParameters(params: Dictionary<any> | HttpParams,
                                                  pageParams?: Pageable,
                                                  includeSubtenants?: boolean,
                                                  tenant?: string): HttpOptions {
        const options = this.getRequestOptions(includeSubtenants, tenant);
        if (params instanceof HttpParams) {
            options.params = params;
        } else {
            options.params = new HttpParams({fromObject: this.getDefinedValues(params)});
        }
        if (isDefined(pageParams)) {
            options.params = options.params
                .set(HttpConstants.PAGE_PARAM, pageParams.page.toString())
                .set(HttpConstants.PAGE_SIZE_PARAM, pageParams.size.toString());
            pageParams.sort.format().forEach(it => {
                options.params = (options.params as HttpParams).append(HttpConstants.PAGE_SORT_PARAM, it);
            });
        }
        return options;
    }

    public static getRequestOptionsWithHeaders(headers: Dictionary<string>): HttpOptions {
        const options = this.getRequestOptions();
        let existingHeaders = options.headers as HttpHeaders;
        if (headers) {
            Object.keys(headers).forEach(key => existingHeaders = existingHeaders.set(key, headers[key]));
            options.headers = existingHeaders;
        }
        return options;
    }

    public static isLocalResourceRequest(request: HttpRequest<any>): boolean {
        return request.url.startsWith('./') && request.url.endsWith('.json');
    }

    public static getDefinedParamsFromObject(object: any): Dictionary<string> {
        const dictionary = {};
        if (isDefined(object)) {
            Object.keys(object)
                .filter(key => isDefined(object[key]))
                .forEach(key => dictionary[key] = object[key]);
        }
        return dictionary;
    }
    private static getDefinedValues(params: Dictionary<any>): Dictionary<any> {
        if (isDefined(params)) {
            const definedValues: Dictionary<any> = {};
            Object
                .keys(params)
                .filter(key => isDefined(params[key]))
                .forEach(key => definedValues[key] = params[key]);
            return definedValues;
        } else {
            return params;
        }
    }
}

export class HttpOptions {
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
        [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
}
