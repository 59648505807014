import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SharedDataService {

    private readonly sharedData = new Map<string, any>();

    constructor() {
    }

    getSharedData<T>(key: string): T {
        return this.sharedData.get(key) as T;
    }

    setSharedData<T>(key: string, value: T): void {
        this.sharedData.delete(key);
        this.sharedData.set(key, value);
    }

    delete<T>(key: string): void {
        this.sharedData.delete(key);
    }

    clear(): void {
        this.sharedData.clear();
    }
}
