import {Injectable} from '@angular/core';
import {ToastController, ToastOptions} from '@ionic/angular';
import {isDefined, isNullOrUndefined} from '../../commons/utils';
import {noop} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ToastService {

    constructor(private toastController: ToastController) {
    }

    success(opts: string | object, isToastUnique: boolean = false, duration: number = 3500): Promise<HTMLIonToastElement> {
        const defaultSuccessOpts = {
            cssClass: 'success-toast',
            duration: duration,
            dismissOnPageChange: false,
            position: 'top'
        };
        return this.showToast(opts, defaultSuccessOpts, isToastUnique);
    }

    warn(opts: string | object, isToastUnique: boolean): Promise<HTMLIonToastElement> {
        const defaultWarnOpts = {
            id: 'warn-toast',
            cssClass: 'warn-toast',
            duration: 3500,
            dismissOnPageChange: true,
            position: 'top'
        };
        return this.showToast(opts, defaultWarnOpts, isToastUnique);
    }

    proceed(opts: string | object): Promise<HTMLIonToastElement> {
        const defaultProceedOpts = {
            cssClass: 'proceed-toast',
            buttons: [
                {
                    text: 'Proceed',
                    role: 'cancel'
                }
            ],
            dismissOnPageChange: true,
            position: 'top'
        };
        return this.showToast(opts, defaultProceedOpts);
    }

    error(opts: string | object): Promise<HTMLIonToastElement> {
        const defaultErrorOpts = {
            cssClass: 'error-toast',
            duration: 3500,
            dismissOnPageChange: true,
            position: 'top'
        };
        return this.showToast(opts, defaultErrorOpts);
    }

    errorWithCancelButton(opts: string | object): Promise<HTMLIonToastElement> {
        const defaultErrorOpts = {
            cssClass: 'error-toast',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel'
                }
            ],
            dismissOnPageChange: true,
            position: 'top'
        };
        return this.showToast(opts, defaultErrorOpts);
    }

    errorPinCode(opts: string | object): Promise<HTMLIonToastElement> {
        const defaultErrorOpts = {
            cssClass: 'error-toast-pin-code',
            duration: 3500,
            dismissOnPageChange: true,
            position: 'middle'
        };
        return this.showToast(opts, defaultErrorOpts);
    }

    maintenanceBannerWithCancelButton(opts: string | object): Promise<HTMLIonToastElement> {
        const defaultErrorOpts = {
            icon: '/assets/icon/warning-outline.svg',
            cssClass: 'warning-toast-maintenance-window',
            buttons: [
                {
                    icon: '/assets/icon/close-circle-outline.svg',
                    role: 'cancel',
                    cssClass: 'warning'
                }
            ],
            dismissOnPageChange: false,
            position: 'top'
        };
        return this.showToast(opts, defaultErrorOpts);
    }

    private showToast(opts: string | object, defaultOpts, isToastUnique = false): Promise<HTMLIonToastElement> {
        const toastOpts = typeof opts === 'string' ?
            Object.assign({message: opts}, defaultOpts) : Object.assign(opts, defaultOpts);

        if (isToastUnique) {
            this.toastController.getTop().then(elem => {
                if (isNullOrUndefined(elem) || elem.id !== defaultOpts.id) {
                    this.createToast(toastOpts);
                }
            });
        } else {
            return this.createToast(toastOpts);
        }
    }

    private createToast(toastOpts): Promise<HTMLIonToastElement> {
        return this.toastController.create(toastOpts).then(toast => {
            toast.present();
            return toast;
        });
    }

    dismissToast(): void {
        this.toastController.getTop().then(elem => {
            isDefined(elem) ? this.toastController.dismiss().then(() => this.dismissToast()) : noop();
        });
    }
}
